import isEmpty from 'lodash/isEmpty';

import { JobLogType } from 'app/pages/JobLogsPage/types';

import { getYearFromDate } from 'utils/services/common.service';

// Constants
import { JobStageEnum } from 'utils/constants/enum';
import {
  defaultClassDetails,
  displayDetailsByEnum,
  JobListClassType,
} from 'app/components/JobLogList/constants';

export const getSbaForeclosure = (sba: boolean, foreClosure: boolean) => {
  const foreClosurePlaceholder = 'PtlForeClose';
  const sbaPlaceholder = 'SBA';
  if (sba && foreClosure) {
    return ` - ${sbaPlaceholder}, ${foreClosurePlaceholder}`;
  } else if (sba) {
    return ` - ${sbaPlaceholder}`;
  } else if (foreClosure) {
    return ` -  ${foreClosurePlaceholder}`;
  } else return '';
};

export const getModifiedJobName = (jobLogDetails: JobLogType) => {
  return `${getYearFromDate(jobLogDetails?.createdAt)}-${
    jobLogDetails?.client?.acronym
  }.${jobLogDetails?.scope?.acronym}.${jobLogDetails?.jobId}${getSbaForeclosure(
    jobLogDetails?.sba,
    jobLogDetails?.foreClosure,
  )}`;
};

export const getLongJobId = (jobLogDetails: JobLogType) => {
  return `${getYearFromDate(jobLogDetails?.createdAt)}-${
    jobLogDetails?.client?.acronym
  }.${jobLogDetails?.scope?.acronym}.${jobLogDetails?.jobId}`;
};

export const getPortfolioId = (
  portfolioId: number | null,
  childPortfolioId?: number | null,
) => `P${portfolioId} (${childPortfolioId ?? 'P'})`;

export const getClassNameByState = (
  jobStage?: JobStageEnum,
): JobListClassType => {
  const details = jobStage && displayDetailsByEnum[jobStage];
  return !isEmpty(details) ? details : defaultClassDetails;
};
