/**
 * JobLogTemplateSection
 */

import { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Form, FormField, ButtonTooltip } from 'app/components';

import { FIELD_LABELS, PLACEHOLDERS } from 'utils/constants';
import { BUTTON_VARIANT_THEMES } from 'styles/assets';

interface JobTemplateApi {
  jobTemplate: string;
}

export const JobLogTemplateSection = ({
  jobTemplateOption,
  onJobTemplateSubmit,
  jobTemplate,
  isHistoryPage,
  isPortfolioJobCompleted,
}) => {
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      jobTemplate: '',
    },
  });

  useEffect(() => {
    if (!isEmpty(jobTemplate)) {
      form.reset(
        { jobTemplate: jobTemplate },
        {
          keepDirty: false,
        },
      );
    }
  }, [jobTemplate]);

  const onFormSubmit = useCallback(({ jobTemplate }: JobTemplateApi) => {
    onJobTemplateSubmit(jobTemplate);
  }, []);

  const isFormDirty = form.formState.isDirty;

  const onCancel = useCallback(() => {
    if (!isEmpty(jobTemplate)) {
      form.reset(
        { jobTemplate: jobTemplate },
        {
          keepDirty: false,
        },
      );
    } else {
      form.reset();
    }
  }, [jobTemplate]);

  return (
    <div className="bg-white mt-12 p-24 radius-8">
      <Form formName="jobTemplateForm" onSubmit={onFormSubmit} form={form}>
        {/* FORM CANCEL & SAVE */}
        <div className="d-flex align-center justify-between mb-16">
          <h5 className="f-14 l-h-22 f-w-700 text-black-8 mb-16">
            Job Template
          </h5>

          {isFormDirty && (
            <div className="d-flex">
              <ButtonTooltip.Cancel buttonProps={{ onClick: onCancel }} />
              <ButtonTooltip.Save />
            </div>
          )}
          {!isFormDirty && !isEmpty(jobTemplate) && (
            <ButtonTooltip.Reload buttonProps={{ type: 'submit' }} />
          )}
        </div>
        <FormField.Select
          className="mw-315"
          name="jobTemplate"
          aria-label="categoryIdSelect"
          placeholder={PLACEHOLDERS.selectTemplate}
          defaultValue=""
          options={jobTemplateOption}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'flex-1 mb-16 mr-8',
          }}
          labelProps={{
            className: 'form-label pb-6',
            displayName: FIELD_LABELS.jobTemplate,
          }}
          disabled={
            (!jobTemplateOption?.length ?? true) ||
            isHistoryPage ||
            isPortfolioJobCompleted
          }
        />
      </Form>
    </div>
  );
};

export default memo(JobLogTemplateSection);
