/**
 * Constants
 */

export { default as ROUTES, defaultPage, defaultPageSize } from './routes';
export { default as HELMET_MESSAGES } from './helmetMessages';
export { default as ERROR_MESSAGES } from './errorMessage';
export { default as SUCCESS_MESSAGES } from './successMessages';
export { default as FIELD_LABELS } from './fieldLabels';
export { default as PLACEHOLDERS } from './placeholders';

export { UserTypeEnum, ActionEnum } from './enum';

export {
  STORAGE_KEYS,
  PATTERNS,
  DATE_FORMAT,
  booleanDropdownList,
  tableEmptyMessages,
  defaultPhoneCode,
  IMAGE_URLS,
  currencyDetails,
  booleanValue,
  booleanApiValue,
} from './common';

export { default as TABLE_DATA_TYPES } from './table';

export {
  BUTTON_COLORS,
  BUTTON_VARIANT_THEMES,
  BUTTON_TYPES,
  BUTTON_SIZE,
  BUTTON_TARGETS,
  COMMON_CLASSNAMES,
} from 'styles/assets';

export const siteStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'VI',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const MAX_UPLOAD_SIZE = 94371840;
