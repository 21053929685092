import axios, { RawAxiosRequestHeaders } from 'axios';
import { getAuth, onAuthStateChanged, getIdToken } from 'firebase/auth';

import config from 'config';

import initializeFirebase from 'utils/services/firebase.service';
import { interceptor } from 'utils/api/api-interceptors';

const apiUrl = config.apiUrl;

const apiKeySecret = config.apiKeySecret;

export const getUserToken = async () => {
  return new Promise(resolve => {
    onAuthStateChanged(
      getAuth(initializeFirebase.getInstance()),
      async user => {
        if (user) {
          const token = await getIdToken(user);
          resolve(token);
        } else {
          resolve(null);
        }
      },
    );
  });
};

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': window.location.origin,
    'Access-Control-Allow-Credentiazls': true,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    apikey: apiKeySecret,
  },
});

const axiosFdInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': window.location.origin,
    'Access-Control-Allow-Credentiazls': true,
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    apikey: apiKeySecret,
  },
});

const axiosBlobInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  responseType: 'blob',
  headers: {
    'Access-Control-Allow-Origin': window.location.origin,
    'Access-Control-Allow-Credentiazls': true,
    'Content-Type': 'application/json',
    apikey: apiKeySecret,
  },
});

// axiosInstance.interceptors.response initialization
interceptor(axiosInstance);
interceptor(axiosFdInstance);
interceptor(axiosBlobInstance);

axiosInstance.interceptors.request.use(async config => {
  const idToken = await getUserToken();
  if (config.headers && idToken) {
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }
  return config;
});

axiosFdInstance.interceptors.request.use(async config => {
  const idToken = await getUserToken();
  if (config.headers && idToken) {
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }
  return config;
});

axiosBlobInstance.interceptors.request.use(async config => {
  const idToken = await getUserToken();
  if (config.headers && idToken) {
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }
  return config;
});

export default axiosInstance;

export const getCall = async (
  url: string,
  headers: RawAxiosRequestHeaders = {},
) => axiosInstance.get(url, { headers });

export const postCall = async (
  url: string,
  payload: object,
  headers: RawAxiosRequestHeaders = {},
) => axiosInstance.post(url, payload, { headers });

export const putCall = async (
  url: string,
  payload?: object,
  headers: RawAxiosRequestHeaders = {},
) => axiosInstance.put(url, payload, { headers });

export const postFormDataCall = async (
  url: string,
  payload,
  headers: RawAxiosRequestHeaders = {},
) => axiosFdInstance.post(url, payload, { headers });

export const updateFormDataCall = async (
  url: string,
  payload,
  headers: RawAxiosRequestHeaders = {},
) => axiosFdInstance.put(url, payload, { headers });

export const getCallBlob = (
  url: string,
  headers: RawAxiosRequestHeaders = {},
) => axiosBlobInstance.get(url, { headers });
