/**
 * DropdownPopupButton
 */

import { memo } from 'react';

// Material
import { IconButton } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';

export interface DropdownPopupButtonProps {
  disabled: boolean;
  onChange?: () => void;
}

export const DropdownPopupButton = ({
  disabled,
  onChange,
}: DropdownPopupButtonProps) => (
  <div>
    <IconButton
      className="job-log-btn mt-22"
      disabled={disabled}
      onChange={onChange}
    >
      <ArticleIcon className="text-white" />
    </IconButton>
  </div>
);

export default memo(DropdownPopupButton);
