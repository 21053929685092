/**
 * MultiSelect
 */
import { memo } from 'react';

import {
  MenuItem,
  Select as MuiSelect,
  Checkbox,
  ListItemText,
} from '@mui/material';

// Types
import { MultiSelectType } from 'app/components/MultiSelect/types';
import { SelectOptionType } from 'app/components/Select/types';

// Services
import { defaultMultiSelectRenderValue } from 'app/components/MultiSelect/services';

export const MultiSelect = ({
  name,
  options,
  value,
  renderValue,
  placeholder = '',
  disabledOptions,
  className,
  ...props
}: MultiSelectType) => {
  const placeHolder = (
    <p className="f-14 f-w-400 l-h-20 text-hint text-truncate">{placeholder}</p>
  );
  return (
    <MuiSelect
      {...props}
      className={className}
      displayEmpty
      multiple
      name={name}
      value={value}
      renderValue={defaultMultiSelectRenderValue(
        options,
        placeHolder,
        renderValue,
      )}
    >
      {options.map((item: SelectOptionType) => (
        <MenuItem
          key={item.value}
          value={item.value}
          disabled={
            item.isDisabled ||
            (disabledOptions &&
              disabledOptions.indexOf(item.value.toString()) >= 0)
          }
        >
          <Checkbox
            checked={value.indexOf(item.value.toString()) >= 0}
            disabled={item.isDisabled}
          />
          <ListItemText key={item.value} primary={item.displayName} />

          {item.subText && (
            <p className="flex justify-end f-12">{`(${item.subText})`}</p>
          )}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default memo(MultiSelect);
