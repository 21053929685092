import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

import { SnackbarState, UpdateSnackbarActionProps } from 'store/snackbar/types';

export const initialState: SnackbarState = {
  message: '',
  theme: 'success',
};

const slice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    updateSnackbarAction: (state, { payload }: UpdateSnackbarActionProps) => ({
      ...state,
      ...payload,
    }),
    clearSnackbarAction: () => initialState,
  },
});

export const { name: sliceName, actions } = slice;

export const useSnackbarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

export default slice.reducer;
