/**
 * Pagination
 */

import { memo, useEffect, useState, ChangeEvent } from 'react';

// mui
import { Pagination as MIPagination } from '@mui/material';

// type
import { PaginationProps } from 'app/components/Pagination/types';

export const Pagination = ({
  page,
  pageCount,
  onPageChange,
}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    onPageChange(value);
  };

  return (
    <MIPagination
      count={pageCount}
      page={currentPage}
      variant="outlined"
      shape="rounded"
      onChange={handleChange}
    />
  );
};

export default memo(Pagination);
