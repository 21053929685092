import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';

import { JobLogType } from 'app/pages/JobLogsPage/types';

import { FIELD_LABELS } from 'utils/constants';

import { getModifiedJobName } from 'app/components/JobLogList/services';
import { JobPortfolioEnum } from 'app/pages/CreateEditJobLogModal/constants';

export const getArrayField = (fieldValue: any[], path?: string) => {
  return path ? fieldValue.map(obj => obj[path]) : fieldValue;
};

export const getModifiedFields = (jobLogDetails: JobLogType, value: string) => {
  let fieldValue = get(jobLogDetails, value);
  const callback = value['callback'];
  if (callback && typeof callback === 'function') {
    fieldValue = callback(jobLogDetails);
  }
  if (typeof fieldValue === 'boolean') {
    fieldValue = String(+fieldValue);
  }
  if (isPlainObject(fieldValue)) {
    // Concat object fields
    fieldValue = Object.values(fieldValue)
      .map((path: any) => capitalize(jobLogDetails[path]))
      .join(' ');
  }
  if (fieldValue instanceof Array) {
    fieldValue = getArrayField(fieldValue, '_id');
  }
  return fieldValue;
};

export const getModifiedClientList = (
  list: any,
  keyPath = '_id',
  displayNamePath: string,
  displaySubNamePath: string,
) => {
  if (!list?.length) {
    return [];
  }

  return list?.map(item => ({
    ...item,
    key: get(item, keyPath, ''),
    value: get(item, keyPath, ''),
    label: `${get(item, displayNamePath, '')}  (${get(
      item,
      displaySubNamePath,
      '',
    )})`,
  }));
};

export const getNoOptionText = (loading: boolean, searchText: string) => {
  if (loading) {
    return FIELD_LABELS.loading;
  } else if (searchText.length >= 5) {
    return FIELD_LABELS.notFound;
  }
  return FIELD_LABELS.searchClient;
};

export const getModifiedFormValues = (formData, isEditJob = false) => {
  const modifiedFormValues = {
    ...formData,
    sba: !!+formData.sba,
    foreClosure: !!+formData.foreClosure,
    externalDataNeeded: !!+formData.externalDataNeeded,
    priorReport: !!+formData.priorReport,
    loanNumber: formData.loanNumber,
  };

  if (isEditJob) {
    delete modifiedFormValues.portfolioType;

    return {
      ...modifiedFormValues,
      isParentPortfolio: formData.portfolioType === JobPortfolioEnum.parent,
    };
  }

  return modifiedFormValues;
};

export const getModifiedParentPortfolioList = (list: any, keyPath = '_id') => {
  if (!list?.length) {
    return [];
  }

  return list?.map(item => ({
    ...item,
    key: get(item, keyPath, ''),
    value: get(item, keyPath, ''),
    displayName: `${getModifiedJobName(item)} P${item.portfolioId} (P)`,
  }));
};
