import { API_ROUTES, getCall, getCallBlob, postFormDataCall } from 'utils/api';
import { getModifiedApiError } from 'utils/services/common.service';
import { UploadKeys } from '../types';
import { DownloadJobFilePayload } from './types';

export const uploadJobFilesApi = async (
  files: Array<any>,
  jobId: number,
  folderKey: UploadKeys,
) => {
  try {
    const fd = new FormData();
    files.map(file => {
      fd.append('files', file);
    });

    const { data } = await postFormDataCall(
      `${API_ROUTES.jobFileUpload}/${folderKey}/${jobId}`,
      fd,
    );
    return data;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};

export const getJobFolderDetailsApi = async payload => {
  try {
    const { data } = await getCall(
      `${API_ROUTES.job}/${payload.jobId}/files?isHistory=${
        payload.isHistory ?? false
      }`,
    );
    return data;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};

export const downloadJobFile = async (payload: DownloadJobFilePayload) => {
  try {
    const { path, jobId, fileId, isHistory } = payload;
    const { data } = await getCallBlob(
      `${API_ROUTES.job}/download/${path}/${jobId}/${fileId}?isHistory=${
        isHistory ?? false
      }`,
    );
    return data;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};

export const getSingedUrlJobFile = async payload => {
  try {
    const { path, jobId, fileId, isHistory } = payload;
    const { data } = await getCall(
      `${API_ROUTES.job}/generateLink/${path}/${jobId}/${fileId}?isHistory=${
        isHistory ?? false
      }`,
    );
    return data;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};
