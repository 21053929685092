import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import jobLogDetailsCenterSectionSaga from 'app/pages/JobLogDetailsCenterSection/slice/saga';

// Types
import { ErrorProps } from 'store/auth/types';
import {
  AwfUserDetailsType,
  OrmsUserDetailsType,
} from 'app/pages/UsersPage/types';
import {
  GetAwfUserListSuccessProps,
  GetOrmsUserListSuccessProps,
  JobLogDetailsCenterSectionState,
  UpdateConsultantQuoteProps,
  UpdateFinalReviewProps,
  UpdateJobExtDataProps,
  UpdateQuoteDeliveryProps,
} from 'app/pages/JobLogDetailsCenterSection/slice/types';
import { GetJobLogDetailsSuccessProps } from 'app/pages/JobLogDetailsPage/slice/types';
import { JobLogType } from 'app/pages/JobLogsPage/types';

export const initialState: JobLogDetailsCenterSectionState = {
  ormsUserListDetails: {
    loading: false,
    error: null,
    list: [] as OrmsUserDetailsType[],
  },
  awfExtConsUserDetails: {
    loading: false,
    error: null,
    list: [] as AwfUserDetailsType[],
  },
  awfIntConsUserDetails: {
    loading: false,
    error: null,
    list: [] as AwfUserDetailsType[],
  },
  awfContractorUserDetails: {
    loading: false,
    error: null,
    list: [] as AwfUserDetailsType[],
  },
  updateJobCenterSectionDetails: {
    loading: false,
    error: null,
    extDataSuccessDetails: {} as JobLogType,
    consultantQuoteSuccessDetails: {} as JobLogType,
    quoteDeliverySuccessDetails: {} as JobLogType,
    finalReviewSuccessDetails: {} as JobLogType,
  },
};

const slice = createSlice({
  name: 'jobLogDetailsCenterSection',
  initialState,
  reducers: {
    // get ORMS users
    getOrmsUserListAction: state => ({
      ...state,
      ormsUserListDetails: {
        ...initialState.ormsUserListDetails,
        loading: true,
      },
    }),
    getOrmsUserListSuccessAction: (
      state,
      { payload }: GetOrmsUserListSuccessProps,
    ) => ({
      ...state,
      ormsUserListDetails: {
        ...initialState.ormsUserListDetails,
        list: payload,
      },
    }),
    getOrmsUserListErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      ormsUserListDetails: {
        ...initialState.ormsUserListDetails,
        error: payload,
      },
    }),

    // get AWF External consultant users
    getAwfExtConsUsersListAction: state => ({
      ...state,
      awfExtConsUserDetails: {
        ...state.awfExtConsUserDetails,
        loading: true,
      },
    }),
    getAwfExtConsUsersListSuccessAction: (
      state,
      { payload }: GetAwfUserListSuccessProps,
    ) => ({
      ...state,
      awfExtConsUserDetails: {
        ...initialState.awfExtConsUserDetails,
        list: payload,
      },
    }),
    getAwfExtConsUsersListErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      awfExtConsUserDetails: {
        ...initialState.awfExtConsUserDetails,
        error: payload,
      },
    }),

    // get AWF Internal consultant users
    getAwfIntConsUsersListAction: state => ({
      ...state,
      awfIntConsUserDetails: {
        ...initialState.awfIntConsUserDetails,
        loading: true,
      },
    }),
    getAwfIntConsUsersListSuccessAction: (
      state,
      { payload }: GetAwfUserListSuccessProps,
    ) => ({
      ...state,
      awfIntConsUserDetails: {
        ...initialState.awfIntConsUserDetails,
        list: payload,
      },
    }),
    getAwfIntConsUsersListErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      awfIntConsUserDetails: {
        ...initialState.awfIntConsUserDetails,
        error: payload,
      },
    }),

    //get Awf Contractor
    getAwfContractorUsersListAction: state => ({
      ...state,
      awfContractorUserDetails: {
        ...initialState.awfContractorUserDetails,
        loading: true,
      },
    }),
    getAwfContractorUsersListSuccessAction: (
      state,
      { payload }: GetAwfUserListSuccessProps,
    ) => ({
      ...state,
      awfContractorUserDetails: {
        ...initialState.awfContractorUserDetails,
        list: payload,
      },
    }),
    getAwfContractorUsersListErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      awfContractorUserDetails: {
        ...initialState.awfContractorUserDetails,
        error: payload,
      },
    }),

    // update center section details error
    updateJobCenterSectionErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        error: payload,
      },
    }),

    // update Job Ext Data Details Section
    updateJobExtDataAction: (state, { payload }: UpdateJobExtDataProps) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        loading: true,
      },
    }),
    updateJobExtDataSuccessAction: (
      state,
      { payload }: GetJobLogDetailsSuccessProps,
    ) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        extDataSuccessDetails: payload,
      },
    }),

    // update Job Consultant Quote Details Section
    updateConsultantQuoteAction: (
      state,
      { payload }: UpdateConsultantQuoteProps,
    ) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        loading: true,
      },
    }),
    updateConsultantQuoteSuccessAction: (
      state,
      { payload }: GetJobLogDetailsSuccessProps,
    ) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        consultantQuoteSuccessDetails: payload,
      },
    }),

    // update Job Quote Delivery Details Section
    updateQuoteDeliveryAction: (
      state,
      { payload }: UpdateQuoteDeliveryProps,
    ) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        loading: true,
      },
    }),
    updateQuoteDeliverySuccessAction: (
      state,
      { payload }: GetJobLogDetailsSuccessProps,
    ) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        quoteDeliverySuccessDetails: payload,
      },
    }),

    // update Job Final Review Details Section
    updateFinalReviewAction: (state, { payload }: UpdateFinalReviewProps) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        loading: true,
      },
    }),
    updateFinalReviewSuccessAction: (
      state,
      { payload }: GetJobLogDetailsSuccessProps,
    ) => ({
      ...state,
      updateJobCenterSectionDetails: {
        ...initialState.updateJobCenterSectionDetails,
        finalReviewSuccessDetails: payload,
      },
    }),

    // Reset Error
    resetCenterSectionErrorAction: state => ({
      ...state,
      awfContractorUserDetails: {
        ...state.awfContractorUserDetails,
        error: initialState.awfContractorUserDetails.error,
      },
      awfIntConsUserDetails: {
        ...state.awfIntConsUserDetails,
        error: initialState.awfIntConsUserDetails.error,
      },
      awfExtConsUserDetails: {
        ...state.awfExtConsUserDetails,
        error: initialState.awfExtConsUserDetails.error,
      },
      ormsUserListDetails: {
        ...state.ormsUserListDetails,
        error: initialState.ormsUserListDetails.error,
      },
      updateJobCenterSectionDetails: {
        ...state.updateJobCenterSectionDetails,
        error: initialState.updateJobCenterSectionDetails.error,
      },
    }),

    // resetStateAction
    resetStateAction: () => initialState,
  },
});

export const { name: sliceName, actions } = slice;

export const useJobLogDetailsCenterSectionSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: jobLogDetailsCenterSectionSaga });
  return { actions: slice.actions };
};

export default slice.reducer;
