/**
 * ChatWidget
 */

import { memo, useCallback, useEffect, useRef, useState } from 'react';

// Material
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';

// Constants
import {
  BUTTON_COLORS,
  BUTTON_TYPES,
  BUTTON_VARIANT_THEMES,
} from 'styles/assets';

// Child Components
import { Button, Form, FormField } from 'app/components';
import ChatMessages from './ChatMessages';

// Services
import {
  addOlderMessages,
  convertStreamedMessageToViewType,
  getJobChatHistory,
  loadMoreMessages,
  postChatMessage,
  seenMessages,
} from 'app/components/ChatWidget/services';

// Types
import {
  ChatMessage,
  chatMessageInitalValues,
  TimedMessages,
} from 'app/components/ChatWidget/types';

// Styles
import './style.scss';
import { useForm, useFormContext } from 'react-hook-form';

// Constants
import { fieldNames } from './constants';

// Lodash
import { isEmpty } from 'lodash';

// Redux imports
import { useSelector } from 'react-redux';
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';
import { selectAuth } from 'store/auth/selectors';
import { socketConfig } from 'store/socket/selectors';
import { useSearchParams } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

export const ChatWidget = () => {
  const jobId = useSelector(selectJobLogDetails)._id;
  const user = useSelector(selectAuth).whoisApiDetails.whoisDetails;
  const socket = useSelector(socketConfig);

  const endOfChat = useRef<null | HTMLDivElement>(null);
  const loadMoreMessagesRef = useRef<null | HTMLDivElement>(null);
  const prevScrollPositionRef = useRef<null | HTMLDivElement>(null);
  const newMessagesDividerRef = useRef<null | HTMLDivElement>(null);

  const [chatDetails, setChatDetails] = useState({
    isChatOpen: false,
    isChatExpanded: false,
  });
  const [chatMessages, setChatMessages] = useState<TimedMessages>({});
  const [hasUpdate, setHasUpdate] = useState(false);
  const [updateMessage, setUpdateMessage] = useState<ChatMessage>(
    chatMessageInitalValues,
  );
  const [fetchedAllMessages, setFetchedAllMessages] = useState<boolean>(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState<string>('');
  const [params] = useSearchParams();

  const isHistoryPage = location.pathname.includes(ROUTES.historyPage);

  useEffect(() => {
    params.get('isChatOpen') === 'true' &&
      setChatDetails({
        isChatExpanded: true,
        isChatOpen: true,
      });

    newMessagesDividerRef &&
      newMessagesDividerRef.current &&
      newMessagesDividerRef.current.scrollIntoView();
  }, [params, newMessagesDividerRef.current]);

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      [fieldNames.messageField]: '',
    },
  });

  const setChatOpenClose = useCallback((isChatOpenValue: boolean) => {
    setChatDetails(prevState => ({
      ...prevState,
      isChatOpen: isChatOpenValue,
    }));
  }, []);

  const setChatExpand = useCallback((isChatExpandedValue: boolean) => {
    setChatDetails(prevState => ({
      ...prevState,
      isChatExpanded: isChatExpandedValue,
    }));
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async function () {
      if (jobId) {
        const messages = await getJobChatHistory(jobId, isHistoryPage);
        setPrevScrollPosition(messages[Object.keys(messages)[0]][0]._id);
        setChatMessages(messages);
      }
    })();

    if (socket !== null)
      socket.on('chat', message => {
        if (isMounted && jobId && !isEmpty(user)) {
          if (user._id !== message.userId && message.jobId === jobId) {
            const messageToUpdate: ChatMessage = {
              ...message,
            };
            setUpdateMessage(messageToUpdate);
            setHasUpdate(true);
          }
        }
      });

    return () => {
      isMounted = false;
    };
  }, [jobId, user, socket, newMessagesDividerRef]);

  useEffect(() => {
    if (hasUpdate) {
      const messages = convertStreamedMessageToViewType(
        chatMessages,
        updateMessage,
      );
      setChatMessages(messages);
      setHasUpdate(false);
      if (chatDetails.isChatOpen) seenMessages([], jobId);
    }

    chatDetails.isChatOpen &&
      newMessagesDividerRef &&
      newMessagesDividerRef.current &&
      newMessagesDividerRef.current.scrollIntoView();

    let readInterval;
    if (chatDetails.isChatOpen) {
      readInterval = setTimeout(() => {
        seenMessages([], jobId);
      }, 5000);
    }

    chatDetails.isChatOpen &&
      endOfChat &&
      endOfChat.current &&
      endOfChat.current.scrollIntoView();

    newMessagesDividerRef &&
      newMessagesDividerRef.current &&
      newMessagesDividerRef.current.scrollIntoView();

    return () => clearInterval(readInterval);
  }, [hasUpdate, chatDetails.isChatOpen, jobId, newMessagesDividerRef.current]);

  const sendMessage = useCallback(
    chat => {
      const message = chat.messageField;
      if (!isEmpty(message)) {
        form.reset();
        postChatMessage(jobId, message);
        setUpdateMessage({
          message,
          jobId,
          displayName: `${user.firstName} ${user.lastName}`,
          userId: user._id,
          seenBy: [],
          createdAt: new Date().toISOString(),
        });
        setHasUpdate(true);
      }
    },
    [jobId, user],
  );

  const loadMoreMessagesCallback = useCallback(
    async entries => {
      const [entry] = entries;
      if (entry.isIntersecting && !fetchedAllMessages) {
        const keys = Object.keys(chatMessages).sort();
        const moreMessages = await loadMoreMessages(
          jobId,
          isHistoryPage,
          chatMessages[keys[0]][0]._id!,
        );
        if (isEmpty(moreMessages)) {
          return setFetchedAllMessages(true);
        }

        setPrevScrollPosition(
          chatMessages[Object.keys(chatMessages)[0]][0]._id!,
        );
        setChatMessages({ ...addOlderMessages(chatMessages, moreMessages) });
      }
    },
    [chatMessages, fetchedAllMessages, prevScrollPositionRef.current],
  );

  useEffect(() => {
    if (
      prevScrollPositionRef &&
      prevScrollPositionRef.current &&
      prevScrollPosition
    )
      prevScrollPositionRef.current.scrollIntoView();
  }, [prevScrollPosition]);

  useEffect(() => {
    const observer = new IntersectionObserver(loadMoreMessagesCallback, {
      threshold: 1,
    });
    if (loadMoreMessagesRef.current)
      observer.observe(loadMoreMessagesRef.current);

    if (endOfChat && endOfChat.current && hasUpdate)
      endOfChat.current.scrollIntoView({ behavior: 'smooth' });

    return () => {
      if (loadMoreMessagesRef.current)
        observer.unobserve(loadMoreMessagesRef.current);
    };
  }, [
    loadMoreMessagesRef.current,
    endOfChat.current,
    chatDetails.isChatOpen,
    hasUpdate,
    { ...chatMessages },
  ]);
  return (
    <div className="chat-widget-section d-flex flex-column align-end">
      {chatDetails.isChatOpen && (
        <div className="chat-widget-container chat-widget-active">
          <div
            aria-label="chat-widget"
            className={`chat-widget-popup ${
              chatDetails.isChatExpanded ? 'chat-widget-popup-extend' : ''
            }`}
          >
            <div className="chat-widget-header d-flex align-center justify-between py-16 pl-16 pr-8">
              <h3 className="f-18 f-w-700 l-h-normal job-chat-header">
                Job chat
              </h3>
              <div className="p-4">
                {chatDetails.isChatExpanded ? (
                  <IconButton
                    aria-label="chat-expand-close"
                    onClick={() => setChatExpand(false)}
                  >
                    <CloseFullscreenIcon className="f-20 btn-chat-expand" />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="chat-expand-open"
                    onClick={() => setChatExpand(true)}
                  >
                    <OpenInFullIcon className="f-20 flip-horizontally" />
                  </IconButton>
                )}
              </div>
            </div>

            <div className="chat-widget-content">
              <ChatMessages
                chatMessages={chatMessages}
                loadMoreMessagesRef={loadMoreMessagesRef}
                lastScrollPosition={prevScrollPosition}
                prevScrollPositionRef={prevScrollPositionRef}
                newMessagesDividerRef={newMessagesDividerRef}
              />
              <div ref={endOfChat}></div>
            </div>

            {!isHistoryPage && (
              <Form formName="chatForm" onSubmit={sendMessage} form={form}>
                {/* CHAT FOOTER */}
                <div className="chat-widget-footer d-flex align-center px-16">
                  <FormControl variant="outlined" className="w-100 chat-input">
                    <FormField.Input
                      name={fieldNames.messageField}
                      formControlProps={{
                        variant: BUTTON_VARIANT_THEMES.outlined,
                        className: 'chat-input',
                      }}
                    />
                  </FormControl>

                  <Button
                    variant={BUTTON_VARIANT_THEMES.contained}
                    color={BUTTON_COLORS.primary}
                    className="icon-button ml-8"
                    type={BUTTON_TYPES.submit}
                    disabled={isEmpty(form.watch(fieldNames.messageField))}
                  >
                    <SendIcon className="f-20" />
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      )}

      <div className="chat-widget-action d-flex align-center justify-center cursor-pointer">
        {chatDetails.isChatOpen ? (
          <IconButton
            aria-label="chat-close"
            onClick={() => setChatOpenClose(false)}
          >
            <CloseIcon className="f-24 text-white" />
          </IconButton>
        ) : (
          <IconButton
            aria-label="chat-open"
            onClick={() => setChatOpenClose(true)}
          >
            <ChatIcon className="f-24 text-white" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default memo(ChatWidget);
