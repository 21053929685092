import { Typography } from '@mui/material';
import React from 'react';

function NoFilesView() {
  return (
    <div className="horizontal-center no-files-v-align">
      <Typography variant="h4" className="no-files-f-color">
        No files
      </Typography>
    </div>
  );
}

export default NoFilesView;
