import { MutableRefObject } from 'react';
import { WhoisDetailsType } from 'store/auth/types';

export interface ChatMsgType {
  from: string;
  date: string;
  message: string;
}

export interface ChatMessagesType {
  [key: string]: ChatMsgType[];
}

export interface ChatWidgetProps {
  messages?: ChatMessagesType;
}

export interface TimedMessages {
  [time: string]: ChatMessage[];
}
export interface ChatMessage {
  _id?: string;
  displayName: string;
  jobId: string;
  userId: string;
  message: string;
  seenBy: string[];
  createdAt: string;
  isNewMessagesDivider?: boolean;
  __v?: number;
}

export interface SendMessage {
  jobId: string;
  message: string;
}

export interface ChatMessagesProp {
  chatMessages: TimedMessages;
  loadMoreMessagesRef: React.MutableRefObject<HTMLDivElement | null>;
  lastScrollPosition: string;
  prevScrollPositionRef: React.MutableRefObject<HTMLDivElement | null>;
  newMessagesDividerRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const chatMessageInitalValues = {
  displayName: '',
  jobId: '',
  userId: '',
  message: '',
  seenBy: [],
  createdAt: '',
};

export interface SeenMessages {
  messageIds: string[];
}
