// Constants
import { Divider } from '@mui/material';
import { DATE_FORMAT } from 'utils/constants';

// Services
import { getFormattedDate } from 'utils/services/common.service';
import { getFormattedChatTime } from './services';

// Types
import { ChatMessage, ChatMessagesProp } from './types';

function ChatMessages({
  chatMessages,
  loadMoreMessagesRef,
  lastScrollPosition,
  prevScrollPositionRef,
  newMessagesDividerRef,
}: ChatMessagesProp) {
  return (
    <>
      {Object.entries(chatMessages).map(
        ([messageKey, messageValues], dateIndex) => (
          <div className="p-12" key={`messages-${messageKey}`}>
            <div className="text-center">
              <div className="f-10 f-w-600 text-primary-4 bg-primary-100 radius-20 d-inline-block py-3 px-10 mb-12">
                {getFormattedChatTime(messageValues[0]?.createdAt)}
              </div>
            </div>

            <div className="space-my-12">
              {messageValues.map((messageItem: ChatMessage, index) => (
                <div key={`message-item-${messageKey}-${index}`}>
                  {messageItem?.isNewMessagesDivider === true && (
                    <div className="my-18">
                      <div ref={newMessagesDividerRef}></div>
                      <Divider className="f-12 text-primary-4">
                        New Messages
                      </Divider>
                    </div>
                  )}

                  {dateIndex === 0 && index === 0 && (
                    <div ref={loadMoreMessagesRef}></div>
                  )}
                  {messageItem._id === lastScrollPosition && (
                    <div ref={prevScrollPositionRef}></div>
                  )}
                  <div className="d-flex">
                    {/* FROM */}
                    <p className="f-13 f-w-600 l-h-normal text-secondary mr-10">
                      {messageItem.displayName}
                    </p>
                    {/* TIME */}
                    <p className="f-12 f-w-500 l-h-normal text-hint">
                      {messageItem?.createdAt &&
                        getFormattedDate(
                          messageItem.createdAt,
                          DATE_FORMAT.timeFormat,
                        )}
                    </p>
                  </div>

                  {/* MESSAGE */}
                  <p className="f-13 f-w-400 l-h-18 text-tertiary mt-4">
                    {messageItem.message}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ),
      )}
    </>
  );
}

export default ChatMessages;
