import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import authSliceSaga from 'store/auth/saga';

// Types
import {
  ErrorProps,
  GetWhoisSuccessProps,
  AuthSliceState,
  WhoisDetailsType,
  CheckUserExistsActionProps,
  CheckUserExistsSuccessProps,
} from 'store/auth/types';

export const initialState: AuthSliceState = {
  validateUserByEmailDetails: {
    loading: false,
    error: null,
    isUserExist: null,
  },
  whoisApiDetails: {
    loading: false,
    error: null,
    whoisDetails: {} as WhoisDetailsType,
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Validate User by Email
    validateUserByEmailAction: (state, {}: CheckUserExistsActionProps) => ({
      ...state,
      validateUserByEmailDetails: {
        ...initialState.validateUserByEmailDetails,
        loading: true,
      },
    }),
    validateUserByEmailSuccessAction: (
      state,
      { payload }: CheckUserExistsSuccessProps,
    ) => ({
      ...state,
      validateUserByEmailDetails: {
        ...initialState.validateUserByEmailDetails,
        isUserExist: payload,
      },
    }),
    validateUserByEmailErrorAction: (state, { payload }: ErrorProps) => ({
      ...initialState,
      validateUserByEmailDetails: {
        ...initialState.validateUserByEmailDetails,
        error: payload,
      },
    }),
    // Reset Validate User by Email Error
    resetValidateUserErrorAction: state => ({
      ...state,
      validateUserByEmailDetails: {
        ...state.validateUserByEmailDetails,
        error: initialState.validateUserByEmailDetails.error,
      },
    }),

    // whois api
    whoisAction: state => ({
      ...state,
      whoisApiDetails: {
        ...initialState.whoisApiDetails,
        loading: true,
      },
    }),
    whoisSuccessAction: (state, { payload }: GetWhoisSuccessProps) => ({
      ...state,
      whoisApiDetails: {
        ...initialState.whoisApiDetails,
        whoisDetails: payload,
      },
    }),
    whoisErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      whoisApiDetails: {
        ...initialState.whoisApiDetails,
        error: payload,
      },
    }),
  },
});

export const { name: sliceName, actions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSliceSaga });
  return { actions: slice.actions };
};

export default slice.reducer;
