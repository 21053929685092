import { getAuth } from 'firebase/auth';

import { ROUTES } from 'utils/constants';

import initializeFirebase from 'utils/services/firebase.service';

const whiteListedErrorPath = [ROUTES.loginPage];

export const interceptor = axiosInstance => {
  axiosInstance.interceptors.response.use(
    async req => ({ ...req, withCredentials: true }),
    async error => {
      const errorData = error.response['data'];
      if (
        errorData &&
        errorData.statusCode === 401 &&
        !whiteListedErrorPath.includes(errorData.path)
      ) {
        return await getAuth(initializeFirebase.getInstance()).signOut();
      }
      return Promise.reject(error.response);
    },
  );
};
