/**
 * DatePicker
 */

import { memo } from 'react';
import { Dayjs } from 'dayjs';

import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

import {
  DatePickerType,
  OnChangeType,
  PickerType,
  RenderInputType,
} from 'app/components/DatePicker/types';

const defaultRenderInput =
  (
    pickerProps: PickerType & {
      error?: boolean;
      helperText?: string;
      placeholder?: string;
    },
    CustomInput?: RenderInputType,
  ) =>
  (props: TextFieldProps) => {
    if (CustomInput) {
      return (
        <CustomInput
          {...props}
          error={pickerProps.error}
          helperText={pickerProps.helperText}
        />
      );
    }
    return (
      <TextField
        {...props}
        inputProps={{
          ...props.inputProps,
          placeholder: pickerProps.placeholder ?? 'MMM DD YYYY',
        }}
        error={pickerProps.error}
        helperText={pickerProps.helperText}
      />
    );
  };

const defaultOnchange =
  (onChange: OnChangeType) =>
  (date?: Dayjs, keyboardInputValue?: string | undefined) => {
    const formattedDate = date?.format();

    if (onChange) {
      return onChange(date ? formattedDate : null, keyboardInputValue);
    }
  };

export const DatePicker = ({
  onChange,
  renderInput,
  ...props
}: DatePickerType) => (
  <MuiDatePicker
    toolbarPlaceholder="MM/DD/YYYY"
    {...props}
    renderInput={defaultRenderInput(
      {
        onChange,
        renderInput,
        ...props,
      },
      renderInput,
    )}
    onChange={defaultOnchange(onChange)}
  />
);

export default memo(DatePicker);
