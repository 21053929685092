import { API_ROUTES, getCall, putCall } from 'utils/api';
import { AwfUserTypeEnum } from 'utils/constants/enum';

import {
  getModifiedApiError,
  getModifiedApiUrl,
} from 'utils/services/common.service';

export const getAwfUserByFilterApi = async ({
  awfUserType,
}: {
  awfUserType: AwfUserTypeEnum;
}) => {
  try {
    const {
      data: { list },
    } = await getCall(
      `${API_ROUTES.awfByFilter}?filters[]=${awfUserType}&isDisabled=false&asc=true&sortBy=name`,
    );
    return list;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const updateExtDataApi = async payload => {
  try {
    const jobId = payload.jobId;
    delete payload.jobId;
    const { data } = await putCall(
      `${API_ROUTES.externalData}/${jobId}`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const updateConsultantQuoteApi = async payload => {
  try {
    const jobId = payload.jobId;
    delete payload.jobId;
    const { data } = await putCall(
      `${API_ROUTES.consultantQuote}/${jobId}`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const updateQuoteDeliveryApi = async payload => {
  try {
    const jobId = payload.jobId;
    delete payload.jobId;
    const { data } = await putCall(
      `${API_ROUTES.quoteDelivery}/${jobId}`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const updateFinalReviewApi = async payload => {
  try {
    const jobId = payload.jobId;
    delete payload.jobId;
    const { data } = await putCall(
      `${API_ROUTES.finalReview}/${jobId}`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const getOrmsList = async payload => {
  try {
    const ormsApi = getModifiedApiUrl(API_ROUTES.orms, {
      ...payload,
      isDisabled: false,
      isAsc: false,
    });
    const { data } = await getCall(ormsApi);
    return data.data.list;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};
