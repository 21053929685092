import { Link } from 'react-router-dom';
import get from 'lodash/get';

// Material
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';

// Types
import { TableColumnType } from 'app/components/Table/types';
import { ClientDefaultPocType } from 'app/pages/ClientsPage/types';

export const getEdit = (col: TableColumnType, value?: string) => (
  <Link to={col?.redirect ? `${col?.redirect}/${value}` : '#'}>
    <div
      aria-label="edit"
      className={`table-icon bg-transparent radius-4 d-flex align-center justify-center ${
        !col.disabled ? 'cursor-pointer' : ''
      }`}
    >
      <IconButton
        className="table-edit-icon"
        aria-label="close-icon"
        disabled={col?.disabled}
      >
        <Edit className="f-16 text-secondary" />
      </IconButton>
    </div>
  </Link>
);

export const redirectId = (col: TableColumnType, value: string, data) => (
  <Link
    to={
      col?.redirect
        ? `${col?.redirect}/${
            col.subPath ? get(data, col?.subPath, '') : value
          }`
        : '#'
    }
    target="_blank"
    style={{ color: 'black' }}
  >
    {value}
  </Link>
);

export const getPocValue = (
  col: TableColumnType,
  poc: ClientDefaultPocType[],
) => {
  const defaultPoc = poc.find(pocDetail => pocDetail.isDefault);

  return col?.subPath
    ? get(defaultPoc, col.path, '') + ' ' + get(defaultPoc, col.subPath!, '')
    : get(defaultPoc, col.path, '');
};

export const getPhoneWithCode = (phone: string, phoneCode: string) =>
  `(${phoneCode}) ${phone}`;
