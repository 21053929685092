/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for UsersPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const UsersPage = lazyLoad(
  () => import('./index'),
  module => module.UsersPage,
);

export default UsersPage;
