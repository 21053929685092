export const fieldNames = {
  reviewer: 'reviewer',
  internalConsultantReviewer: 'internalConsultantReviewer',
  ormsFinalReviewer: 'ormsFinalReviewer',
  isCompleted: 'isCompleted',
  initial: 'initial',
  completed: 'finalReviewStatus',
};

export const completeOption = [
  {
    key: 'yesCompleted',
    value: 'yesCompleted',
    displayName: 'Yes, Completed',
  },
  {
    key: 'billableCancel',
    value: 'billableCancel',
    displayName: 'Cancelled - Billable',
  },
  {
    key: 'noFeeCancel',
    value: 'noFeeCancel',
    displayName: 'Cancelled - No Fee',
  },
  {
    key: 'onHold',
    value: 'onHold',
    displayName: 'On Hold',
  },
];

export const technicalOrAdminUserIds = [
  '6419837740b12940d99f5feb',
  '641983818efd48e684e37b91',
];
