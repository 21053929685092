/**
 * Header
 */
import { memo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useSelector } from 'react-redux';

//Material
import { Menu, MenuItem, Button, IconButton, Badge } from '@mui/material';
import { NotificationsOutlined as NotificationsOutlinedIcon } from '@mui/icons-material';

// Constants
import { ROUTES } from 'utils/constants';

// Types
import { WhoisDetailsType } from 'store/auth/types';

import { selectNotification } from 'app/components/NotificationPopover/slice/selectors';

// Services
import {
  getFullName,
  setLocalStorageByKey,
} from 'utils/services/common.service';

//Styles
import { ORMSWhiteLogo } from 'styles/images';
import NotificationPopover from '../NotificationPopover';

export interface HeaderType {
  loggedInUser: WhoisDetailsType;
}

export const Header = ({ loggedInUser }: HeaderType) => {
  const [headerBtn, setOpenHeaderBtn] = useState<null | HTMLElement>(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showBadge, setBadgeStatus] = useState<Boolean>(false);

  // Notification popover
  const [notificationAnchor, setNotificationAnchor] =
    useState<HTMLButtonElement | null>(null);
  const isNotificationOpen = Boolean(notificationAnchor);
  const id = isNotificationOpen ? 'notification-popover' : undefined;

  const navigate = useNavigate();
  const auth = getAuth();
  const notificationList = useSelector(selectNotification).notifications;

  onAuthStateChanged(auth, user => {
    if (isEmpty(user)) {
      navigate(ROUTES.loginPage);
    }
  });

  const open = Boolean(headerBtn);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenHeaderBtn(event.currentTarget);
  };

  const handleClose = () => {
    setOpenHeaderBtn(null);
  };

  const openNotificationPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationAnchor(e.currentTarget);
  };

  const closeNotificationPopover = () => {
    setNotificationAnchor(null);
  };

  const onLogout = async () => {
    setOpenHeaderBtn(null);
    setLocalStorageByKey('');
    await getAuth().signOut();
  };

  return (
    <div className="dashboard-header bg-header d-flex align-center justify-between pl-24 px-32">
      <Link to={ROUTES.jobLogsPaginatedRoute} className="d-flex">
        <img src={ORMSWhiteLogo} alt="orms-logo" />
      </Link>

      <div className="d-flex align-center radius-6">
        <div className="mr-16">
          <IconButton aria-describedby={id} onClick={openNotificationPopover}>
            <Badge color="error" {...(showBadge ? { variant: 'dot' } : {})}>
              <NotificationsOutlinedIcon className="f-24 text-icon cursor-pointer" />
            </Badge>
          </IconButton>
          <NotificationPopover
            id={id}
            isNotificationOpen={isNotificationOpen}
            notificationAnchor={notificationAnchor}
            closeNotificationPopover={closeNotificationPopover}
            setNotificationCount={setNotificationCount}
            showBadge={setBadgeStatus}
          />
        </div>

        <div className="border-left-dark">
          <Button
            aria-label="header-btn"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className="header-btn f-14 l-h-32 f-w-600 text-light pl-16"
          >
            {getFullName(loggedInUser)}
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={headerBtn}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className="dropdown-menu"
          >
            <MenuItem aria-label="logout" onClick={onLogout}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
