/**
 * SiteLocationDetailsSection
 */

import { memo } from 'react';

import { BUTTON_VARIANT_THEMES } from 'styles/assets';

// Constants
import { ERROR_MESSAGES, FIELD_LABELS, PLACEHOLDERS } from 'utils/constants';

// Child Components
import { FormField } from 'app/components';

// Services
import {
  addressValidation,
  siteStateValidation,
  stringValidation,
} from 'app/components/Form/services';

import { fieldNames } from 'app/pages/CreateEditJobLogModal/constants';

export const SiteLocationDetailsSection = () => (
  <div className="bg-white radius-8 p-20 pb-24 mb-12">
    <p className="f-14 f-w-700 text-primary mb-16">Site Location Details</p>

    {/* SITE ADDRESS */}
    <FormField.Input
      name={fieldNames.siteLocationAddress}
      placeholder={PLACEHOLDERS.enterSiteAddress}
      formControlProps={{
        variant: BUTTON_VARIANT_THEMES.outlined,
        className: 'mb-16',
      }}
      labelProps={{
        displayName: FIELD_LABELS.siteAddress,
        required: true,
      }}
      rules={{
        required: ERROR_MESSAGES.siteAddressRequired,
        validate: {
          address: addressValidation(ERROR_MESSAGES.siteAddressInvalid),
        },
      }}
    />

    {/* SITE CITY & SITE STATE */}
    <div className="d-flex justify-between">
      <FormField.Input
        name={fieldNames.siteLocationCity}
        placeholder={PLACEHOLDERS.enterSiteCity}
        formControlProps={{
          variant: BUTTON_VARIANT_THEMES.outlined,
          className: 'w-50 mr-8',
        }}
        labelProps={{
          displayName: FIELD_LABELS.siteCity,
          required: true,
        }}
        rules={{
          required: ERROR_MESSAGES.siteCityRequired,
          validate: {
            city: addressValidation(ERROR_MESSAGES.siteCityInvalid),
          },
        }}
      />

      <FormField.Input
        name={fieldNames.siteLocationState}
        placeholder={PLACEHOLDERS.enterSiteState}
        formControlProps={{
          variant: BUTTON_VARIANT_THEMES.outlined,
          className: 'w-50 ml-8',
        }}
        labelProps={{
          displayName: FIELD_LABELS.siteState,
          required: true,
        }}
        rules={{
          required: ERROR_MESSAGES.siteStateRequired,
          validate: {
            state: siteStateValidation(ERROR_MESSAGES.siteStateInvalid),
          },
        }}
      />
    </div>
  </div>
);

export default memo(SiteLocationDetailsSection);
