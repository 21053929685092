/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for JobLogDetailsCenterSection
 *
 */

import { lazyLoad } from 'utils/loadable';

const JobLogDetailsCenterSection = lazyLoad(
  () => import('./index'),
  module => module.JobLogDetailsCenterSection,
);

export default JobLogDetailsCenterSection;
