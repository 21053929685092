/**
 * JobLogList
 */
import { memo, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

// Components
import { PortfolioSticker } from 'app/components';

// Material
import Chip from '@mui/material/Chip';
import ChatIcon from '@mui/icons-material/Chat';
import { IconButton } from '@mui/material';

// Constants
import { ROUTES } from 'utils/constants';
import { JobStageEnum } from 'utils/constants/enum';

// Types
import { JobLogType } from 'app/pages/JobLogsPage/types';

// Services
import {
  getClassNameByState,
  getModifiedJobName,
} from 'app/components/JobLogList/services';
import {
  getFormattedDate,
  getPlaceHolderJob,
} from 'utils/services/common.service';
import { IS_CHAT_OPEN_TRUE_PARAM } from 'utils/constants/common';
import dayjs from 'dayjs';

export interface JobLogListProps {
  list: JobLogType[];
  redirect?: string;
  isHistoryPage?: boolean;
  isAdmin?: boolean;
}
export const JobLogList = ({
  list,
  redirect = ROUTES.jobLogsPage,
  isHistoryPage = false,
  isAdmin = false,
}: JobLogListProps) => {
  const tableStartRef = useRef<null | HTMLDivElement>(null);

  const scrollToTop = () => {
    tableStartRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  });

  return (
    <div className="job-logs-items mb-6">
      {list.map((item, index) => {
        const classNameDetails = getClassNameByState(item?.jobStage);

        return (
          <Link
            aria-label={`job-log-row-${item.jobId}`}
            key={item.jobId}
            to={`${redirect}/${item.jobId}`}
          >
            <div
              className={`job-logs-item job-logs-${classNameDetails.cardClassName} d-flex py-6 px-16 job-log-item`}
              ref={!index ? tableStartRef : null}
            >
              <div className="w-100 flex-1">
                <div>
                  <h3 className="f-16 f-w-700 text-secondary">
                    {getModifiedJobName(item)}
                  </h3>
                </div>

                <p className="f-14 f-w-500 text-tertiary mt-4">
                  {getPlaceHolderJob(item)}
                </p>
              </div>

              <div className="mr-60">
                <p className="f-14 f-w-500 text-tertiary">
                  {getFormattedDate(
                    item?.quoteDeliveryDetails?.dueDate,
                    'MM/DD/YYYY',
                  )}
                </p>
              </div>

              <div className="ml-10 flex-2">
                <div className="d-flex c-g-8">
                  {item?.jobStage !== JobStageEnum?.completed && (
                    <Chip
                      label={classNameDetails?.displayName}
                      size="small"
                      className={classNameDetails?.chipClassName}
                    />
                  )}

                  <PortfolioSticker
                    portfolioId={item?.portfolioId}
                    childPortfolioId={item?.childPortfolioId}
                  />

                  <div>
                    {!isHistoryPage &&
                    dayjs().isAfter(
                      item?.quoteDeliveryDetails?.dueDate,
                      'day',
                    ) ? (
                      <Chip
                        label="OVERDUE"
                        size="small"
                        className="text-white on-hold-chip"
                      />
                    ) : (
                      <></>
                    )}
                    {item?.finalReview?.finalReviewStatus === 'onHold' && (
                      <Chip
                        label="ON HOLD"
                        size="small"
                        variant="filled"
                        className="text-white on-hold-chip ml-10"
                      />
                    )}
                  </div>
                </div>

                <p className="f-13 f-w-500 text-tertiary mt-6">
                  {`${item.siteLocation.address}, ${item.siteLocation.city}, ${item.siteLocation.state}.`}
                </p>
              </div>
              <div className="flex-1 unread-message-count-container">
                {item.unread && isAdmin && (
                  <div className="mt-6">
                    <Link
                      aria-label={`job-log-row-${item.jobId}`}
                      key={item.jobId}
                      to={`${redirect}/${item.jobId}${IS_CHAT_OPEN_TRUE_PARAM}`}
                    >
                      <IconButton className="job-log-chat-count d-flex">
                        <div className="flex-1">
                          <ChatIcon />
                        </div>
                        <div className="flex-1 f-12 f-w-500 ml-4 chat-count">
                          {item.unread > 99 ? '99+' : item.unread}
                        </div>
                      </IconButton>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};
export default memo(JobLogList);
