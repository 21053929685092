import { initializeApp, FirebaseApp } from 'firebase/app';

import config from 'config';

export default (function () {
  let firebaseInstance: FirebaseApp;

  function createInstance() {
    return initializeApp({
      apiKey: config.apiKey,
      authDomain: config.authDomain,
      projectId: config.projectId,
      storageBucket: config.storageBucket,
      messagingSenderId: config.messagingSenderId,
      appId: config.appId,
    });
  }

  return {
    getInstance: function () {
      if (!firebaseInstance) {
        firebaseInstance = createInstance();
      }

      return firebaseInstance;
    },
  };
})();
