/**
 * JobLogPortfolioSection
 */

import { memo, useCallback, useEffect, useState } from 'react';

import {
  AddPortfolioParentModal,
  Form,
  FormField,
  JobLogButtonLabel,
} from 'app/components';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT_THEMES,
  FIELD_LABELS,
} from 'utils/constants';

import { JobLogPortfolioDetailsType } from 'app/pages/JobLogsPage/types';
import {
  JobPortfolioEnum,
  portfolioOptions,
} from 'app/pages/CreateEditJobLogModal/constants';
import { useForm } from 'react-hook-form';

// Types
import { JobLogDropdownType } from 'app/pages/JobLogsPage/types';

import { FinalReviewStage } from 'app/pages/JobLogDetailsPage/constants';

import './styles.scss';
import { useSelector } from 'react-redux';
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';

export interface JobLogPortfolioSectionProps {
  portfolioDetails: JobLogPortfolioDetailsType;
  onCovertPortfolioClick: () => void;
  onPortfolioJobCompletionClick: () => void;
  onPortfolioCompletionClick: () => void;
  onPortfolioParentModalSave: any;
  parentPortfolioJobList: JobLogDropdownType[];
  disableJobConversion?: string;
  isHistoryPage: boolean;
  isSuperAdmin: boolean;
}

export const JobLogPortfolioSection = ({
  portfolioDetails,
  onCovertPortfolioClick,
  onPortfolioParentModalSave,
  onPortfolioJobCompletionClick,
  onPortfolioCompletionClick,
  parentPortfolioJobList,
  disableJobConversion,
  isHistoryPage,
  isSuperAdmin,
}: JobLogPortfolioSectionProps) => {
  const [isAddPortfolioParentModal, setIsAddPortfolioParentModal] =
    useState(false);
  const job = useSelector(selectJobLogDetails);

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const watchPortfolioType = form.watch('portfolioType', null);
  const watchPortfolioParentId = form.watch('portfolioParentId', '');

  useEffect(() => {
    if (watchPortfolioType === JobPortfolioEnum.child) {
      setIsAddPortfolioParentModal(true);
    }
    if (watchPortfolioType === JobPortfolioEnum.parent) {
      onCovertPortfolioClick();
      form.setValue('portfolioType', null);
    }
  }, [watchPortfolioType]);

  const onAddPortfolioParentModalClose = useCallback(() => {
    setIsAddPortfolioParentModal(false);
    form.resetField('portfolioType');
  }, []);

  const onCloseParentSelect = useCallback(() => {
    onPortfolioParentModalSave(watchPortfolioParentId);
    form.setValue('portfolioType', null);
    setIsAddPortfolioParentModal(false);
  }, [watchPortfolioParentId]);

  return (
    <div className="bg-white mt-12 p-24 radius-8">
      <h5 className="f-14 l-h-22 f-w-700 text-black-8 mb-16">Portfolio</h5>

      {(portfolioDetails?.portfolioId ||
        portfolioDetails.parentPortfolioJobId) && (
        <>
          <div className="d-flex align-center justify-between mb-16">
            <p className="f-14 l-h-16 f-w-500 text-secondary">
              Portfolio Job Completion
            </p>

            <JobLogButtonLabel
              isButtonVisible={!portfolioDetails?.isPortfolioJobCompleted}
              buttonProps={{
                variant: BUTTON_VARIANT_THEMES.contained,
                name: FIELD_LABELS.yesCompleted,
                'aria-label': 'yesPortfolioJobCompleted',
                size: BUTTON_SIZE.medium,
                onClick: onPortfolioJobCompletionClick,
                disabled: isHistoryPage || !job.isInvoiceSent,
              }}
              label={FIELD_LABELS.completed}
              labelAriaName="yesPortfolioJobCompletedLabelSection"
            />
          </div>

          {!portfolioDetails?.parentPortfolioJobId && (
            <div className="d-flex align-center justify-between mb-16">
              <p className="f-14 l-h-16 f-w-500 text-secondary">
                Portfolio Completion
              </p>

              <JobLogButtonLabel
                isButtonVisible={!portfolioDetails?.isPortfolioCompleted}
                buttonProps={{
                  variant: BUTTON_VARIANT_THEMES.contained,
                  name: FIELD_LABELS.yesCompleted,
                  'aria-label': 'yesPortfolioCompleted',
                  size: BUTTON_SIZE.medium,
                  onClick: onPortfolioCompletionClick,
                  disabled:
                    !portfolioDetails?.isPortfolioJobCompleted ||
                    isHistoryPage ||
                    !isSuperAdmin ||
                    job.hasActiveChildJobs,
                }}
                label={FIELD_LABELS.completed}
                labelAriaName="yesPortfolioCompletedLabelSection"
              />
            </div>
          )}
        </>
      )}

      {!portfolioDetails.portfolioId && (
        <div className="d-flex align-center justify-between">
          <p className="f-14 l-h-16 f-w-500 text-secondary">
            Portfolio Conversion
          </p>
          <Form
            formName="job-log-form"
            className="selectWidth"
            form={form}
            onSubmit={() => null}
          >
            <>
              {isAddPortfolioParentModal && (
                <AddPortfolioParentModal
                  onModalClose={onAddPortfolioParentModalClose}
                  parentPortfolioJobList={parentPortfolioJobList}
                  onModalSave={onCloseParentSelect}
                />
              )}
            </>
            <FormField.Select
              name="portfolioType"
              disabled={isHistoryPage}
              aria-label="portfolioTypeSelect"
              placeholder="Portfolio"
              defaultValue=""
              className="drawer-dropdown-menu minw-140 d-flex"
              options={portfolioOptions}
            />
          </Form>
        </div>
      )}
    </div>
  );
};

export default memo(JobLogPortfolioSection);
