const fieldLabels = {
  notApplicable: '-',
  zero: '0',
  one: '1',

  // Title
  addAwfUser: 'Add AWF Users',
  editAwfUser: 'Edit AWF Users',
  disableAwf: 'Disable AWF',
  invoices: 'Invoices',
  generateInvoice: 'Generate Invoice CSV',

  // Empty Messages
  noResultFound: 'No result found',
  clientNotFound: 'Client records not found.',
  jobLogNotFound: 'Job Log records not found.',
  scopeNotFound: 'Scopes records not found.',
  usersNotFound: 'Users records not found.',
  invoiceNotFound: 'Invoices records not found.',
  historyNotFound: 'History records not found.',
  dataQuoteNotFound: 'Data And Quote records not found',

  // Buttons
  submitBtn: 'Submit',
  cancelBtn: 'Cancel',
  refreshBtn: 'Reupload',
  createBtn: 'Create',
  saveBtn: 'Save',
  editBtn: 'Edit',
  createNewJobBtn: 'Create New Job',
  saveChangesBtn: 'Save Changes',
  addClientBtn: 'Add Client',
  addPocBtn: 'Add POC',
  loginBtn: 'Login',
  backToLoginBtn: 'Back to Login',
  resendOtpBtn: 'Resend OTP',
  yesOrdered: 'Yes, Ordered',
  yesReceived: 'Yes, Received',
  yesQuoted: 'Yes, Quoted',
  yesCompleted: 'Yes, Completed',
  yesSent: 'Yes, Sent',
  yesConvert: 'Yes, Convert',
  ordered: 'Ordered',
  received: 'Received',
  quoted: 'Quoted',
  sent: 'Sent',
  uploadCertificate: 'Upload Certificate',
  override: 'Override',
  createParent: 'Create Parent',
  createChild: 'Create Child',
  createPortfolioChild: 'Create Portfolio Child',
  duplicate: 'Duplicate',
  generateCSV: 'Generate CSV',
  addTemplate: 'Add Template',
  removeTemplate: 'Remove Template',
  sort: 'Sort By',
  filter: 'Filter',
  generateInvoiceLabel: 'Generate Invoice',
  endAndMoveNow: 'End & Move Now',
  termsAndConditions: 'Terms and Conditions',
  support: 'Support',

  // Inputs
  name: 'Name',
  scopeName: 'Scope Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  pocEmail: 'POC Email',
  emailAddress: 'Email Address',
  password: 'Password',
  rememberMe: 'Remember Me',
  verifyOTP: 'Verify OTP',
  clientName: 'Client Name',
  acronym: 'Acronym',
  phoneCode: 'Phone Code',
  phone: 'Phone Number',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  deliveryEmailSecondSentence:
    'Delivery Email 1st Paragraph, Second Sentence (Specific to Client Delivery)',
  deliveryEmailThirdSentence:
    'Delivery Email 1st Paragraph, Third Sentence (Specific to Client Delivery)',
  deliveryEmailFourthSentence:
    'Delivery Email 1st Paragraph, Fourth Sentence (Specific to Client Delivery)',
  defaultPoc: 'Default POC',
  clientJobName: 'Job Name',
  propertyType: 'Property Type',
  siteAcres: 'Site Acres',
  siteSquareFootage: 'Site Square Footage',
  constructionYear: 'Construction Year',
  jobRecipients: 'Job Recipients',
  createdBy: 'Job Created by',
  jobId: 'Job ID',
  client: 'Client',
  loanNumber: 'Loan Number',
  category: 'Category',
  scopeCategory: 'Scope Category',
  scope: 'Scope',
  portfolioId: 'Portfolio Id',
  priorReport: 'Prior Report',
  sba: 'SBA',
  naicsCode: 'NAICS Code',
  foreclosure: 'Potential Foreclosure',
  externalDataNeeded: 'External Data Needed?',
  siteAddress: 'Site Address',
  siteCity: 'Site City',
  siteState: 'Site State Abbreviation',
  YES: 'YES',
  NO: 'NO',
  yes: 'Yes',
  no: 'No',
  initials: 'Initials',
  group: 'Group',
  superAdmin: 'Super Admin',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  dueDate: 'Due Date',
  incidentReportDate: 'Incident Report Date',
  price: 'Price',
  registeredEmail: 'Registered Email',
  consultantAssignment: 'Consultant Assignment',
  completed: 'Completed',
  jobTemplate: 'Job Template',
  awfCompanyName: 'AWF Company Name',
  awfAbbreviation: 'AWF Abbreviation',
  awfGroup: 'AWF Group',
  phoneNumber: 'Phone Number',
  mobilePhone: 'Mobile Phone',
  insuranceCertificate: 'Insurance Certificate',
  insuranceFileUpload: 'Insurance File Upload',
  expirationDate: 'Expiration Date',
  pocInitials: 'POC Initials',
  jobNumber: 'Job Number',
  reportedBy: 'Reported By',
  internalStaffRequestsQuote: 'Internal Staff Requests Quote',
  assign: 'Assign',
  consultant: 'Consultant',
  externalConsultant: 'External Consultant',
  externalConsultantEngaged: 'External Consultant Engaged',
  clientApproval: 'Client Approval',
  quoteDays: 'Quote Days',
  quoteAmount: 'Quote Amount',
  jobDueDate: 'Job Due Date',
  adminJobDueDate: 'Admin Job Due Date',
  oRMSFinalReview: 'ORMS Final Review',
  internalConsultantReviewer: 'Internal Consultant Reviewer',
  reviewer: 'Reviewer',
  addParent: 'Choose Portfolio Parent',
  templateName: 'Template Name',
  finalReview: 'Final Review',
  jobStage: 'Job Stage',
  portfolio: 'Portfolio',
  sortBy: 'Sort',
  ascending: 'Ascending',
  awfUser: 'AWF User',
  orderFromDate: 'Order From Date',
  orderToDate: 'Order To Date',
  requestFromDate: 'Request From Date',
  requestToDate: 'Request To Date',
  receivedFromDate: 'Received From Date',
  receivedToDate: 'Received To Date',
  quotesFromDate: 'Quotes From Date',
  quotesToDate: 'Quotes To Date',
  isOrdered: 'Is Ordered',
  isQuotes: 'Is Quotes',
  dueFromDate: 'Due Date From',
  dueToDate: 'Due Date To',

  // Options
  searchClient: 'Search Clients',
  notFound: 'Option not found',
  loading: 'Loading...',

  // Switch
  disableScope: 'Disable Scope',
  disableTemplate: 'Disable Template',
  assignedToMe: 'Assigned To Me',
};

export default fieldLabels;
