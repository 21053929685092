/**
 * JobLogExtDataSection
 */

import { memo } from 'react';

import { TextField } from '@mui/material';

import { DatePicker, JobLogButtonLabel } from 'app/components';

import {
  BUTTON_SIZE,
  BUTTON_VARIANT_THEMES,
  FIELD_LABELS,
  PLACEHOLDERS,
} from 'utils/constants';

import {
  getDayjsDate,
  getInitialFromNames,
} from 'utils/services/common.service';

import { JobLogExtDataSectionProps } from 'app/components/JobLogExtDataSection/types';
import { isEmpty } from 'lodash';

export const JobLogExtDataSection = ({
  jobExternalDataDetail,
  onJobExtBtnClick,
  isExternalDataNeeded,
  isHistoryPage,
  isPortfolioJobCompleted,
}: JobLogExtDataSectionProps) => {
  const onExternalDataOrderClick = () => {
    onJobExtBtnClick({
      isExternalDataOrdered: true,
      received: false,
    });
  };

  const onExternalReceivedClick = () => {
    onJobExtBtnClick({
      isExternalDataOrdered: true,
      received: true,
    });
  };

  return (
    <div className="bg-white mt-12 p-24 radius-8">
      <h5 className="f-14 l-h-22 f-w-700 text-black-8 mb-16">
        External Data Details
      </h5>

      <div className="d-flex align-center justify-between mb-16">
        <p className="f-14 l-h-16 f-w-500 text-secondary">
          External Data Ordered?
        </p>

        <div className="d-flex justify-end">
          <JobLogButtonLabel
            isButtonVisible={!jobExternalDataDetail?.isExternalDataOrdered}
            buttonProps={{
              name: FIELD_LABELS.yesOrdered,
              'aria-label': 'yesOrdered',
              size: BUTTON_SIZE.medium,
              onClick: onExternalDataOrderClick,
              disabled:
                !isExternalDataNeeded ||
                isHistoryPage ||
                isPortfolioJobCompleted,
            }}
            label={FIELD_LABELS.ordered}
            labelAriaName="orderedLabelSection"
          />
          <div className="w-25 ml-8">
            <TextField
              name="orderedInitial"
              placeholder={PLACEHOLDERS.initial}
              value={
                isEmpty(jobExternalDataDetail?.externalDataOrderedBy)
                  ? ''
                  : getInitialFromNames(
                      jobExternalDataDetail?.externalDataOrderedBy,
                    )
              }
              variant={BUTTON_VARIANT_THEMES.outlined}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="d-flex align-center justify-between mb-16">
        <p className="f-14 l-h-16 f-w-500 text-secondary">Ordered On</p>

        <DatePicker
          toolbarPlaceholder={PLACEHOLDERS.selectConstructionDate}
          className="job-date-picker"
          aria-label="orderedOn"
          disabled
          value={getDayjsDate(jobExternalDataDetail?.orderedOn)}
        />
      </div>

      <div className="d-flex align-center justify-between">
        <p className="f-14 l-h-16 f-w-500 text-secondary">Received</p>

        <div className="d-flex justify-end">
          <JobLogButtonLabel
            isButtonVisible={!jobExternalDataDetail?.received}
            buttonProps={{
              name: FIELD_LABELS.yesReceived,
              'aria-label': 'yesReceived',
              size: BUTTON_SIZE.medium,
              onClick: onExternalReceivedClick,
              disabled:
                !isExternalDataNeeded ||
                isHistoryPage ||
                isPortfolioJobCompleted,
            }}
            label={FIELD_LABELS.received}
            labelAriaName="receivedLabelSection"
          />
          <div className="w-25 ml-8">
            <TextField
              name="receivedInitial"
              placeholder={PLACEHOLDERS.initial}
              variant={BUTTON_VARIANT_THEMES.outlined}
              value={
                isEmpty(jobExternalDataDetail?.receivedBy)
                  ? ''
                  : getInitialFromNames(jobExternalDataDetail?.receivedBy)
              }
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(JobLogExtDataSection);
