import isUndefined from 'lodash/isUndefined';

import { fieldName } from 'app/components/JobLogConsultantQuoteSection/constants';

import { JobConsultantQuoteDetailsType } from 'app/pages/JobLogsPage/types';
import { isEmpty, values } from 'lodash';

export const getInternalQuoteValue = (internalQuote: boolean | undefined) => {
  if (isUndefined(internalQuote)) {
    return '';
  }
  return internalQuote ? '1' : '0';
};

export const getModifiedFormValues = (
  consultantQuoteDetails?: any,
  isHistoryPage?: boolean,
) => ({
  [fieldName.assignTo]: isHistoryPage
    ? consultantQuoteDetails?.assignTo?.userId?.toString() ?? ''
    : consultantQuoteDetails?.assignTo?.toString() ?? '',
  [fieldName.consultants]: isHistoryPage
    ? consultantQuoteDetails?.consultants?.userId?.toString() ?? ''
    : consultantQuoteDetails?.consultants?.toString() ?? '',
  [fieldName.internalQuote]: getInternalQuoteValue(
    consultantQuoteDetails?.internalQuote,
  ),
  [fieldName.isQuoted]: consultantQuoteDetails?.isQuoted,
});

export const getModifiedSubmitValues = consultantQuoteDetails => {
  Object.entries(consultantQuoteDetails).map(([key, values]) => {
    if (values === '' && key !== 'internalQuote') {
      consultantQuoteDetails[`${key}`] = null;
    }
    if (key === 'internalQuote') {
      consultantQuoteDetails[`${key}`] = isEmpty(values) ? null : !!+values!;
    }
  });
  return consultantQuoteDetails;
};
