/**
 * SearchInput
 */

import { useState, ChangeEvent, memo } from 'react';

// MUI
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// Types
import { SearchInputProps } from 'app/components/SearchInput/types';

export const SearchInput = ({
  searchKey,
  onSubmit,
  placeholder,
  className = '',
  disabled,
}: SearchInputProps) => {
  const [searchText, setSearchText] = useState(searchKey);

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (searchText !== '' && event.target.value === '') {
      setSearchText('');
      onSubmit('');
    } else {
      setSearchText(event?.target?.value);
      onSubmit(event?.target?.value);
    }
  };

  return (
    <div className={`${className}`}>
      <TextField
        className="search-input w-100"
        aria-label="search"
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        onChange={onChangeValue}
        value={searchText}
        variant="outlined"
        disabled={disabled ?? false}
        autoFocus={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className="" />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default memo(SearchInput);
