/**
 * SideMenu
 */

import { memo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Constants
import {
  sideMenuSuperAdminList,
  sideMenuNonAdminList,
  sideMenuAdminList,
} from 'app/components/SideMenu/constants';

// Types
import { SideMenuType } from 'app/components/SideMenu/types';
import { PolicyTypes, RolesEnum } from 'utils/constants/enum';

// Slice
import { selectWhoisDetails } from 'store/auth/selectors';

//Styles
import './style.scss';
import Button from '../Button';
import { BUTTON_COLORS, BUTTON_VARIANT_THEMES } from 'styles/assets';
import { FIELD_LABELS } from 'utils/constants';

import { usePoliciesSlice } from 'app/pages/HomePage/slice';
import {
  selectSupport,
  selectTermsAndConditions,
} from 'app/pages/HomePage/slice/selectors';

import { openBlobFile } from 'utils/services/common.service';
import { isEmpty, isNull } from 'lodash';

export const SideMenu = () => {
  const { actions } = usePoliciesSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getPoliciesAction());
  }, []);

  const { pathname } = useLocation();

  const { whoisDetails } = useSelector(selectWhoisDetails);

  const isSuperAdmin = whoisDetails?.roleId?.name === RolesEnum.superAdmin;

  const isAdmin = whoisDetails?.roleId?.name === RolesEnum.ormsAdmin;

  const termsAndConditionsPdf = useSelector(selectTermsAndConditions);
  const supportPdf = useSelector(selectSupport);

  const getActiveClass = (pathName: string) =>
    pathname.includes(pathName) ? 'SideMenu-link-active' : 'SideMenu-link';

  const sideMenuList = isSuperAdmin
    ? sideMenuSuperAdminList
    : isAdmin
    ? sideMenuAdminList
    : sideMenuNonAdminList;

  const showFileInNewTab = (type: PolicyTypes) => {
    const file =
      type === PolicyTypes.termsAndConditions
        ? termsAndConditionsPdf
        : supportPdf;
    openBlobFile(file, '');
  };

  return (
    <div className="dashboard-SideMenu d-flex flex-column justify-between pl-24 py-16 px-12">
      <div className="space-my-4">
        {sideMenuList.map((icon: SideMenuType) => (
          <Link
            key={icon.key}
            aria-label={icon.key}
            to={icon.redirectTo}
            className={`${icon.className} ${getActiveClass(icon.pathName)}`}
          >
            {icon.getIcon()}
            <p className="f-15 f-w-600 text-tertiary flex-1 ml-10">
              {icon.displayName}
            </p>
          </Link>
        ))}
      </div>
      <div>
        <Button
          aria-label="support"
          className="mb-8"
          color={BUTTON_COLORS.primary}
          variant={BUTTON_VARIANT_THEMES.contained}
          name={FIELD_LABELS.support}
          fullWidth
          onClick={() => {
            showFileInNewTab(PolicyTypes.support);
          }}
          disabled={isNull(supportPdf)}
        />
        <Button
          aria-label="termsAndConditions"
          color={BUTTON_COLORS.primary}
          variant={BUTTON_VARIANT_THEMES.contained}
          name={FIELD_LABELS.termsAndConditions}
          fullWidth
          onClick={() => {
            showFileInNewTab(PolicyTypes.termsAndConditions);
          }}
          disabled={isNull(termsAndConditionsPdf)}
        />
      </div>
    </div>
  );
};

export default memo(SideMenu);
