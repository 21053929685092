/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for ClientsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const ClientsPage = lazyLoad(
  () => import('./index'),
  module => module.ClientsPage,
);

export default ClientsPage;
