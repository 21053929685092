import { all, call, put, takeLatest } from 'redux-saga/effects';

import { actions } from 'app/components/JobLogAssociatesSection/slice';
import { actions as jobLogActions } from 'app/pages/JobLogDetailsPage/slice';

import { UpdateJobAssociateProps } from './types';
import { jobLogAssociatesApi } from './api';

export function* uploadJobAssociation({ payload }: UpdateJobAssociateProps) {
  try {
    const data = yield call(jobLogAssociatesApi, payload);
    yield put(actions.updateJobAssociateSuccessProps(data));

    yield put(jobLogActions.updateJobLogDetailsSuccessAction(data[0]));
  } catch (err: any) {
    yield put(actions.updateJobAssociateErrorProps(err?.message));
  }
}

export function* watchUploadJobAssociation() {
  yield takeLatest(
    actions.updateJobAssociationAction.type,
    uploadJobAssociation,
  );
}

export default function* jobLogAssociatesSaga() {
  yield all([watchUploadJobAssociation()]);
}
