import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import jobLogDetailsPageSaga from 'app/pages/JobLogDetailsPage/slice/saga';

// Types
import { ErrorProps } from 'store/auth/types';
import {
  GetJobLogDetailsProps,
  GetJobLogDetailsSuccessProps,
  JobLogDetailsState,
  AddJobTemplateProps,
  AddJobTemplateSuccessProps,
  SetPageDetailsProps,
} from 'app/pages/JobLogDetailsPage/slice/types';

import { JobLogType } from 'app/pages/JobLogsPage/types';

export const initialState: JobLogDetailsState = {
  loading: false,
  error: null,
  jobLogDetails: {} as JobLogType,
  jobTemplate: {} as JobLogType,
  isHistoryPage: false,
};

const slice = createSlice({
  name: 'jobLogDetailsPage',
  initialState,
  reducers: {
    // get JobLogDetails
    getJobLogDetailsAction: (state, { payload }: GetJobLogDetailsProps) => ({
      ...state,
      loading: true,
    }),
    getJobLogDetailsSuccessAction: (
      state,
      { payload }: GetJobLogDetailsSuccessProps,
    ) => ({
      ...state,
      loading: initialState.loading,
      jobLogDetails: payload,
    }),
    getJobLogDetailsErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      loading: initialState.loading,
      error: payload,
    }),

    updateJobLogDetailsSuccessAction: (
      state,
      { payload }: GetJobLogDetailsSuccessProps,
    ) => ({
      ...state,
      jobLogDetails: { ...state.jobLogDetails, ...payload },
    }),

    addJobTemplateAction: (state, { payload }: AddJobTemplateProps) => ({
      ...state,
      loading: true,
    }),

    addJobTemplateSuccessAction: (
      state,
      { payload }: AddJobTemplateSuccessProps,
    ) => ({
      ...state,
      loading: false,
      jobTemplate: payload,
    }),

    addJobTemplateErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    setPageDetails: (state, { payload }: SetPageDetailsProps) => ({
      ...state,
      isHistoryPage: payload,
    }),

    // Reset Error
    resetJobDetailsErrorAction: state => ({
      ...state,
      error: initialState.error,
    }),

    resetJobDetailsStateAction: () => initialState,
  },
});

export const { name: sliceName, actions } = slice;

export const useJobLogDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: jobLogDetailsPageSaga });
  return { actions: slice.actions };
};

export default slice.reducer;
