/**
 * PasswordInput
 */

import { memo, useState, forwardRef } from 'react';

// Material
import { IconButton, OutlinedInput, InputAdornment } from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

export const PasswordInput = forwardRef(({ ...props }, ref) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleClickShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <OutlinedInput
      onChange={handleClickShowPassword}
      {...props}
      ref={ref}
      type={isPasswordVisible ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle-password-visibility"
            onClick={handleClickShowPassword}
          >
            {isPasswordVisible ? (
              <VisibilityOffIcon aria-label="visibilityOff" />
            ) : (
              <VisibilityIcon aria-label="visibilityOn" />
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
});

PasswordInput.displayName = 'PasswordInput';

export default memo(PasswordInput);
