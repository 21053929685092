/**
 * JobLogDetailRowCol
 */

import {
  JobLogDetailRowColProps,
  JobLogDetailRowColCellProps,
  FieldArrayType,
  JobLogDetailRowColListProps,
} from 'app/pages/JobLogDetailsPage/types';

import { getTableValueByKey } from 'app/components/Table/table-services';
import { currencyDetails } from 'utils/constants';

export const JobLogDetailRowCol = ({
  divClassName,
  leftCelDetails,
  rightCelDetails,
  ...props
}: JobLogDetailRowColProps) => (
  <div className={divClassName} {...props}>
    <JobLogDetailRowCol.Cell celDetails={leftCelDetails} />

    <JobLogDetailRowCol.Cell celDetails={rightCelDetails} />
  </div>
);

JobLogDetailRowCol.Cell = ({ celDetails }: JobLogDetailRowColCellProps) => (
  <div className={celDetails?.divClassName}>
    <p className={celDetails?.labelClassName}>{celDetails?.label}</p>
    <p className={celDetails?.valueClassName}>{celDetails?.value}</p>
  </div>
);

JobLogDetailRowCol.RowColList = ({
  rowColFieldList,
  data,
}: JobLogDetailRowColListProps) => {
  const getRowColKey = (item: FieldArrayType): string =>
    `row-col-${item.leftCelDetails.key}-${item.rightCelDetails.key}`;

  return (
    <>
      {rowColFieldList.map((item: FieldArrayType) => (
        <JobLogDetailRowCol
          key={getRowColKey(item)}
          aria-label={getRowColKey(item)}
          divClassName={item.divClassName}
          leftCelDetails={{
            ...item.leftCelDetails,
            label: item.leftCelDetails.displayName,
            value: getTableValueByKey(item.leftCelDetails, data),
          }}
          rightCelDetails={{
            ...item.rightCelDetails,
            label: item.rightCelDetails.displayName,
            value: getTableValueByKey(item.rightCelDetails, data),
          }}
        />
      ))}
    </>
  );
};

export default JobLogDetailRowCol;
