export interface JobFolderType {
  id: string;
  folderName: string;
  createdAt: string;
  users: string[];
  folderKey: UploadKeys;
}

export interface FolderModal {
  handleClose: () => void;
  open: boolean;
  folderData: FolderData;
  isHistoryPage: boolean;
}

export interface FolderData {
  folderName: string;
  folderKey: UploadKeys;
  files: JobFile[];
}

export interface FolderFiles {
  path: string;
  jobId: number;
  fileName: string;
  fileSize: number;
  owner: Owner;
  createdAt: string;
}

export interface Owner {
  email: string;
  firstName: string;
  initial: string | null;
  lastName: string;
  _id: string;
}

export interface LoaderType {
  loading: boolean;
  error: string | null | any;
}
export interface JobFiles {
  folderKey: UploadKeys;
  files: JobFile[];
  isUploaderView?: boolean;
  setDownloadFile?: Function;
  selectAll?: string[];
  setLoader?: Function;
}

export enum UploadKeys {
  orms = 'orms',
  deliverable = 'deliverable',
  shared = 'shared',
  externalConsultant = 'externalConsultant',
  internalConsultant = 'internalConsultant',
  contractor = 'contractor',
}

export interface JobFileUploderViewModal {
  folderKey: UploadKeys;
  backToFileUploderViews: (value: boolean) => void;
}

export interface JobFolderType {
  _id: UploadKeys;
  files: Array<JobFile>;
}

export interface JobFile {
  _id: string;
  fileName: string;
  owner: JobOwner;
  path: string;
  jobId: string;
  fileSize: number;
  createdAt: string;
  publicUrl?: string;
  expirePublicUrlAt?: Date;
  updatedAt: string;
}

export interface JobOwner {
  _id: string;
  firstName: string;
  lastName: string;
  initial: string | null;
  email: string;
}
