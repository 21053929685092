/**
 * Table
 */

import { memo, useEffect, useRef } from 'react';

//Material
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

// Services
import { getTableValueByKey } from 'app/components/Table/table-services';

// Types
import { TableColumnType, TableProps } from 'app/components/Table/types';

export const Table = ({
  className = '',
  tableColumns,
  tableData,
}: TableProps) => {
  const tableStartRef = useRef<null | any>(null);

  const scrollToTop = () => {
    tableStartRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToTop();
  });
  return (
    <TableContainer
      className={`table-container ${className}`}
      aria-label="table-container"
    >
      <MuiTable stickyHeader ref={tableStartRef}>
        <TableHead aria-label="table-header">
          <TableRow>
            {tableColumns.map((col: TableColumnType) => (
              <TableCell key={col.key}>{col.displayName}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody aria-label="table-body">
          {tableData.map(data => (
            <TableRow key={data?._id}>
              {tableColumns.map((col: TableColumnType) => (
                <TableCell key={col.key}>
                  {getTableValueByKey(col, data)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default memo(Table);
