/**
 * JobLogQuoteDeliverySection
 */

import { memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import {
  booleanDropdownList,
  BUTTON_COLORS,
  BUTTON_SIZE,
  BUTTON_TYPES,
  ERROR_MESSAGES,
  BUTTON_VARIANT_THEMES,
  FIELD_LABELS,
  PLACEHOLDERS,
  UserTypeEnum,
} from 'utils/constants';

import { getQuoteDays } from 'utils/services/common.service';

// Material
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

// Child Components
import {
  Button,
  ButtonTooltip,
  Form,
  FormField,
  JobLogButtonLabel,
  JobDueDateAdminModal,
} from 'app/components';

// Constants
import { fieldNames } from 'app/components/JobLogQuoteDeliverySection/constants';

// Types
import { JobQuoteDeliveryType } from 'app/pages/JobLogsPage/types';
import { JobLogQuoteDeliverySectionProps } from 'app/components/JobLogQuoteDeliverySection/types';

// Services
import {
  getModifiedFormValues,
  getQuoteDeliverObject,
} from 'app/components/JobLogQuoteDeliverySection/services';
import { numberValidation } from 'app/components/Form/services';
import { RolesEnum } from 'utils/constants/enum';
import { InputAdornment } from '@mui/material';
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';
import { useSelector } from 'react-redux';

interface JobLogQuoteDeliverySectionType {
  dueDate: null | string;
  amount: string;
  quoteDays: number;
  clientApproval: string;
  isExtConsultantEngaged: string;
  isECReportReceived: boolean;
}

export const JobLogQuoteDeliverySection = ({
  quotedAt,
  scopeDueDate,
  quoteDeliveryDetails,
  isInternalStaffRequest,
  whoisDetails,
  onQuoteDeliverySubmit,
  createdAt,
  isHistoryPage,
  isPortfolioJobCompleted,
}: JobLogQuoteDeliverySectionProps) => {
  const [adminJobDueDateModal, setAdminJobDueDateModal] = useState(false);

  const jobDetails = useSelector(selectJobLogDetails);
  const isValidUser =
    whoisDetails?.roleId?.name === RolesEnum.ormsAdmin ||
    whoisDetails?.roleId?.name === RolesEnum.superAdmin;

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      dueDate: null,
      amount: '',
      quoteDays: 0,
      clientApproval: '',
      isExtConsultantEngaged: '',
      isECReportReceived: false,
    } as JobLogQuoteDeliverySectionType,
  });

  const isFormDirty = form.formState.isDirty;
  const isFormValid = form.formState.isValid;

  const watchJobDueDate = form.watch('dueDate');
  const watchClientApproval = form.watch('clientApproval');
  const watchExternalConsultantEngaged = form.watch('isExtConsultantEngaged');
  const watchIsECReportReceived = form.watch('isECReportReceived');

  const isDisableField =
    !isInternalStaffRequest ||
    (quoteDeliveryDetails?.isECReportReceived ?? false);

  // For auto fill the fields while the quoteDeliveryDetails is already updated
  useEffect(() => {
    if (!isEmpty(quoteDeliveryDetails)) {
      quoteDeliveryDetails &&
        form.reset(getModifiedFormValues(quoteDeliveryDetails, jobDetails), {
          keepDirty: false,
        });
    }

    form.setValue(
      'quoteDays',
      getQuoteDays(
        quoteDeliveryDetails?.dueDate ?? null,
        quoteDeliveryDetails?.updatedAt,
      ),
      { shouldDirty: false },
    );
  }, [
    quoteDeliveryDetails,
    quotedAt,
    scopeDueDate,
    isInternalStaffRequest,
    jobDetails,
  ]);

  useEffect(() => {
    form.setValue(
      'quoteDays',
      getQuoteDays(watchJobDueDate, quoteDeliveryDetails?.updatedAt),
      { shouldDirty: false },
    );
  }, [watchJobDueDate]);

  const onQuoteToClientClick = useCallback(() => {
    form.setValue('isECReportReceived', true, { shouldDirty: true });
  }, []);

  const onFormSubmit = useCallback(
    (quoteDeliveryApiData: JobQuoteDeliveryType) => {
      onQuoteDeliverySubmit(
        getQuoteDeliverObject(quoteDeliveryApiData, watchIsECReportReceived),
      );
    },
    [watchIsECReportReceived],
  );

  const onDueDateModalClose = useCallback(() => {
    setAdminJobDueDateModal(false);
  }, []);

  const onJobDueDateChange = useCallback(newJobDateValue => {
    form.setValue('dueDate', newJobDateValue);
    onDueDateModalClose();
  }, []);

  const onCancel = () => {
    if (!isEmpty(quoteDeliveryDetails)) {
      quoteDeliveryDetails &&
        form.reset(getModifiedFormValues(quoteDeliveryDetails, jobDetails), {
          keepDirty: false,
        });
    } else {
      form.reset();
    }
  };

  return (
    <div className="bg-white mt-12 p-24 radius-8">
      <Form
        formName="jobConsultantQuoteForm"
        form={form}
        onSubmit={onFormSubmit}
      >
        <div className="d-flex align-center justify-between mb-16">
          <h5 className="f-14 l-h-22 f-w-700 text-black-8">
            Quote Delivery Details
          </h5>

          {/* FORM CANCEL & SAVE */}
          {isFormDirty && isFormValid && (
            <div className="d-flex">
              <ButtonTooltip.Cancel buttonProps={{ onClick: onCancel }} />
              <ButtonTooltip.Save />
            </div>
          )}
        </div>

        <>
          {adminJobDueDateModal && (
            <JobDueDateAdminModal
              handleClose={onDueDateModalClose}
              onUpdateJobDueDate={onJobDueDateChange}
            />
          )}
        </>

        <div className="d-flex align-start justify-between mb-16">
          {/* Job Due Date */}
          <div className="d-flex flex-1 align-content-end mr-20">
            <FormField.DatePicker
              name={fieldNames.dueDate}
              defaultValue={null}
              formControlProps={{
                variant: BUTTON_VARIANT_THEMES.outlined,
              }}
              labelProps={{
                className: 'flex-1 form-label pb-6',
                displayName: FIELD_LABELS.jobDueDate,
              }}
              minDate={new Date()}
              disabled
            />

            <Button
              name={FIELD_LABELS.override}
              aria-label="overrideBtn"
              className="ml-8"
              color={BUTTON_COLORS.success}
              variant={BUTTON_VARIANT_THEMES.contained}
              size={BUTTON_SIZE.medium}
              type={BUTTON_TYPES.button}
              onClick={() => setAdminJobDueDateModal(true)}
              // NOTE: Disable when user is not SuperAdmin / OMRS
              disabled={
                !isValidUser || isHistoryPage || isPortfolioJobCompleted
              }
            />
          </div>

          {/* Quote Amount */}
          <FormField.Input
            name={fieldNames.amount}
            type="number"
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.quoteAmount,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            rules={{
              validate: {
                amountInNumber: numberValidation(ERROR_MESSAGES.amountInvalid),
              },
            }}
            disabled={
              isDisableField || isHistoryPage || isPortfolioJobCompleted
            }
          />
        </div>

        <div className="d-flex justify-between mb-16">
          {/* Quote Days  */}
          <FormField.Input
            name={fieldNames.quoteDays}
            aria-label="quoteDays"
            // value={getQuoteDays(watchJobDueDate, createdAt)}
            placeholder={PLACEHOLDERS.quoteDays}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mr-20',
            }}
            labelProps={{
              className: 'd-flex align-center form-label pb-6',
              displayName: FIELD_LABELS.quoteDays,
              icon: (
                <Tooltip title="Does Not Exclude Holidays" placement="right">
                  <InfoIcon className="f-16 ml-4" />
                </Tooltip>
              ),
            }}
            disabled
          />

          {/* Client Approval */}
          <FormField.Select
            name={fieldNames.clientApproval}
            handleClear={() => {
              form.setValue('clientApproval', '', { shouldDirty: true });
            }}
            aria-label="clientApprovalSelect"
            placeholder={PLACEHOLDERS.selectYesNo}
            defaultValue=""
            options={booleanDropdownList}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.clientApproval,
            }}
            disabled={
              isDisableField || isHistoryPage || isPortfolioJobCompleted
            }
          />
        </div>

        <div className="d-flex align-end justify-between">
          {/* External Consultant Engaged */}
          <FormField.Select
            name={fieldNames.isExtConsultantEngaged}
            handleClear={() => {
              form.setValue('isExtConsultantEngaged', '', {
                shouldDirty: true,
              });
            }}
            aria-label="extConsultantEngagedSelect"
            placeholder={PLACEHOLDERS.selectYesNo}
            defaultValue=""
            options={booleanDropdownList}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'mr-20',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.externalConsultantEngaged,
            }}
            disabled={
              isDisableField || isHistoryPage || isPortfolioJobCompleted
            }
          />

          <div className="d-flex align-center w-100">
            <p className="f-14 f-w-500 l-h-16 text-secondary mr-6 w-100">
              EC Report Received
            </p>

            <JobLogButtonLabel
              isButtonVisible={!watchIsECReportReceived}
              buttonProps={{
                name: FIELD_LABELS.yesReceived,
                'aria-label': 'yesReceived',
                className: 'w-nowrap px-24',
                size: BUTTON_SIZE.medium,
                onClick: onQuoteToClientClick,
                disabled:
                  !+watchClientApproval ||
                  !+watchExternalConsultantEngaged ||
                  isHistoryPage ||
                  isPortfolioJobCompleted,
              }}
              label={FIELD_LABELS.received}
              labelAriaName="receivedLabelSection"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default memo(JobLogQuoteDeliverySection);
