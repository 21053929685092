export enum RolesEnum {
  superAdmin = 'superAdmin',
  ormsAdmin = 'ormsAdmin',
  awfExternalConsultant = 'awfExternalConsultant',
  awfInternalConsultant = 'awfInternalConsultant',
  awfContractor = 'awfContractor',
  clientPoc = 'clientPoc',
}

export enum UserTypeEnum {
  admin = 'admin',
  orms = 'orms',
  awf = 'awf',
}

export enum AwfUserTypeEnum {
  awfExternalConsultant = 'awfExternalConsultant',
  awfInternalConsultant = 'awfInternalConsultant',
  awfContractor = 'awfContractor',
}

export enum ActionEnum {
  add = 'add',
  edit = 'edit',
}

export enum JobStageEnum {
  jobCreated = 'jobCreated',
  dataQuote = 'dataQuote',
  externalConsultantDeliverables = 'externalConsultantDeliverables',
  internalReview = 'internalReview',
  internalConsultantReview = 'internalConsultantReview',
  contractorReview = 'contractorReview',
  ormsFinal = 'ormsFinal',
  completed = 'completed',
}

export enum PolicyTypes {
  support = 'support',
  termsAndConditions = 'termsAndConditions',
}
