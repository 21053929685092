/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for CreateEditClientPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const CreateEditClientPage = lazyLoad(
  () => import('./index'),
  module => module.CreateEditClientPage,
);

export default CreateEditClientPage;
