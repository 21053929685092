import { ROUTES } from 'utils/constants';
import { NotificationType } from './slice/types';

export const redirectTO = {
  [NotificationType.scope]: ROUTES.editScopePage,
  [NotificationType.orms]: ROUTES.editOrmsUserPage,
  [NotificationType.awf]: ROUTES.editAwfUserPage,
  [NotificationType.job]: ROUTES.jobLogsPage,
  [NotificationType.eris]: ROUTES.jobLogsPage,
};
