import { call, put, takeLatest, all } from 'redux-saga/effects';
import { actions } from 'app/pages/HomePage/slice';

import { policiesApi } from 'app/pages/HomePage/slice/api';

import { PolicyTypes } from 'utils/constants/enum';

export function* getPolicies() {
  try {
    const termsAndConditionsPdf = yield call(policiesApi, {
      type: PolicyTypes.termsAndConditions,
    });
    yield put(actions.getTermsAndConditionSuccessAction(termsAndConditionsPdf));

    const supportPdf = yield call(policiesApi, { type: PolicyTypes.support });
    yield put(actions.getSupportSuccessAction(supportPdf));
  } catch (err: any) {
    yield put(actions.getPoliciesError(err?.message));
  }
}

export function* watchGetPolicies() {
  yield takeLatest(actions.getPoliciesAction.type, getPolicies);
}

export default function* policiesSaga() {
  yield all([watchGetPolicies()]);
}
