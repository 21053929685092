/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for HistoryPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const HistoryPage = lazyLoad(
  () => import('./index'),
  module => module.HistoryPage,
);

export default HistoryPage;
