/**
 * Selectors for Snackbar
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState, sliceName } from 'store/snackbar';

export const selectSnackbar = (state: RootState) =>
  state?.[sliceName] || initialState;

export const selectMessage = createSelector(
  [selectSnackbar],
  subState => subState.message,
);

export const selectTheme = createSelector(
  [selectSnackbar],
  subState => subState.theme,
);
