/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from 'utils/constants';

// Components
import { LoginPage, HomePage } from 'app/pages';
import { ProtectedRoute } from 'app/components';

// Slice
import { useAuthSlice } from 'store/auth';

// Hooks
import useSnackbar from 'app/hooks/useSnackbar';
import { useSnackbarSlice } from 'store/snackbar';
import { useNotificationSlice } from './components/NotificationPopover/slice';

export const App = () => {
  // To inject 'auth' slice for all the pages(containers)
  useAuthSlice();
  useNotificationSlice();

  // To inject 'snackbar' slice for all pages(containers)
  useSnackbarSlice();

  // To inject SuccessBar
  const { Snackbar } = useSnackbar();

  return (
    <>
      {/* Snackbar for all pages */}
      <Snackbar />

      <Routes>
        {/* LOGIN PAGE */}
        <Route path={ROUTES.loginPage} element={<LoginPage />} />

        {/* HOME PAGE */}
        <Route
          path={ROUTES.homePage}
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />

        {/* Redirect to LoginPage whenever a route doesn't match */}
        <Route
          path={ROUTES.notFoundPage}
          element={<Navigate to={ROUTES.loginPage} replace />}
        />
      </Routes>
    </>
  );
};

export default App;
