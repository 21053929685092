/**
 * Select
 */

import { memo, ReactNode } from 'react';

import { IconButton, MenuItem, Select as MuiSelect } from '@mui/material';

import { SelectType, SelectOptionType } from 'app/components/Select/types';

import ClearIcon from '@mui/icons-material/Clear';
import { dropdownDimensions } from '../JobLogConsultantQuoteSection/constants';
// defaultRenderValue
export const defaultRenderValue =
  (
    options: SelectType['options'],
    placeHolder?: ReactNode,
    renderValue?: SelectType['renderValue'] | undefined,
  ) =>
  (selected: string) => {
    if (!selected && placeHolder) return placeHolder;

    if (!!renderValue) {
      return renderValue(selected);
    }

    return options.find(option => option.value === selected)?.displayName;
  };

export const Select = ({
  options,
  value,
  renderValue,
  defaultValue,
  MenuProps,
  placeholder,
  handleClear,
  disabled,
  ...props
}: SelectType) => {
  const placeHolder = (
    <p className="f-14 f-w-400 l-h-20 text-hint text-truncate">{placeholder}</p>
  );
  return (
    <MuiSelect
      className="overflow"
      displayEmpty
      value={value}
      defaultValue={defaultValue || ''}
      {...props}
      renderValue={defaultRenderValue(options, placeHolder, renderValue)}
      // this is to fix the size of the select
      MenuProps={{ ...MenuProps, PaperProps: { sx: dropdownDimensions } }}
      endAdornment={
        value &&
        !disabled &&
        handleClear && (
          <IconButton
            onClick={() => {
              handleClear();
            }}
            size="small"
            className="mr-12"
          >
            <ClearIcon />
          </IconButton>
        )
      }
      disabled={disabled}
    >
      {options.map((item: SelectOptionType) => (
        <MenuItem
          key={item.key}
          aria-label={item.key}
          value={item.value}
          disabled={item.isDisabled}
        >
          {item.displayName}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default memo(Select);
