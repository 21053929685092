/**
 * ErrorAlert
 */

import { memo } from 'react';

// Material
import Alert from '@mui/material/Alert';

interface ErrorAlertProps {
  error?: string | null;
  onCloseError?: () => void | undefined;
}

export const ErrorAlert = ({ error = null, onCloseError }: ErrorAlertProps) => (
  <Alert
    aria-label="alert"
    onClose={() => {
      onCloseError && onCloseError();
    }}
    severity="error"
    className="mb-16"
  >
    {error}
  </Alert>
);

export default memo(ErrorAlert);
