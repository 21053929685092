import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import createEditJobLogModalSaga from 'app/pages/CreateEditJobLogModal/slice/saga';

import { ErrorProps } from 'store/auth/types';
import {
  CreateEditJobLogModalState,
  GetScopeCategoriesSuccessProps,
  GetClientSuccessProps,
  CreateJobActionProps,
  CreateJobSuccessProps,
  GetScopeActionProps,
  GetScopeSuccessProps,
  GetClientActionProps,
  ConvertPortfolioParentActionProps,
  ConvertPortfolioParentSuccessProps,
  GetParentJobSuccessProps,
  GetParentJobActionProps,
  ConvertPortfolioChildActionProps,
  ConvertPortfolioChildSuccessProps,
  DuplicatePortfolioActionProps,
  DuplicatePortfolioSuccessProps,
} from 'app/pages/CreateEditJobLogModal/slice/types';
import { ClientDetailsType } from 'app/pages/ClientsPage/types';
import {
  ScopeDetailsType,
  ScopeCategoryType,
} from 'app/pages/ScopesPage/types';
import { JobLogType } from 'app/pages/JobLogsPage/types';
import {
  GetClientByIdActionProps,
  GetClientByIdSuccessProps,
} from 'app/pages/CreateEditClientPage/slice/types';

export const initialState: CreateEditJobLogModalState = {
  // Use 'loading' 'error' for Create and Edit
  loading: false,
  error: null,
  createJobSuccessDetails: {} as JobLogType,
  editJobSuccessDetails: {} as JobLogType,

  scopeCategoriesDetails: {
    loading: false,
    error: null,
    list: [] as ScopeCategoryType[],
  },
  clientDetails: {
    loading: false,
    error: null,
    list: [] as ClientDetailsType[],
  },
  scopeDetails: {
    loading: false,
    error: null,
    list: [] as ScopeDetailsType[],
  },
  convertPortfolioParent: {
    loading: false,
    error: null,
    successDetail: {} as JobLogType,
  },
  parentPortfolioListDetails: {
    loading: false,
    error: null,
    list: [] as JobLogType[],
  },
  convertPortfolioChild: {
    loading: false,
    error: null,
    successDetail: {} as JobLogType,
  },
  duplicatePortfolio: {
    loading: false,
    error: null,
    successDetail: {} as JobLogType,
  },
  clientDetailsById: {
    loading: false,
    error: null,
    successDetail: {} as ClientDetailsType,
  },
};

const slice = createSlice({
  name: 'createEditJobLogModal',
  initialState,
  reducers: {
    // get Scopes Categories api
    getScopeCategoriesAction: state => ({
      ...state,
      scopeCategoriesDetails: {
        ...initialState.scopeCategoriesDetails,
        loading: true,
      },
    }),
    getScopeCategoriesSuccessAction: (
      state,
      { payload }: GetScopeCategoriesSuccessProps,
    ) => ({
      ...state,
      scopeCategoriesDetails: {
        ...initialState.scopeCategoriesDetails,
        list: payload,
      },
    }),
    getScopeCategoriesErrorAction: (state, { payload }: ErrorProps) => ({
      ...initialState,
      scopeCategoriesDetails: {
        ...initialState.scopeCategoriesDetails,
        error: payload,
      },
    }),

    // get Scopes api
    getScopeAction: (state, { payload }: GetScopeActionProps) => ({
      ...state,
      scopeDetails: {
        ...initialState.scopeDetails,
        loading: true,
      },
    }),
    getScopeSuccessAction: (state, { payload }: GetScopeSuccessProps) => ({
      ...state,
      scopeDetails: {
        ...initialState.scopeDetails,
        list: payload,
      },
    }),
    getScopeErrorAction: (state, { payload }: ErrorProps) => ({
      ...initialState,
      scopeDetails: {
        ...initialState.scopeDetails,
        error: payload,
      },
    }),

    // get Client api
    getClientAction: (state, { payload }: GetClientActionProps) => ({
      ...state,
      clientDetails: {
        ...initialState.clientDetails,
        loading: true,
      },
    }),
    getClientSuccessAction: (state, { payload }: GetClientSuccessProps) => ({
      ...state,
      clientDetails: {
        ...initialState.clientDetails,
        list: payload,
      },
    }),
    getClientErrorAction: (state, { payload }: ErrorProps) => ({
      ...initialState,
      clientDetails: {
        ...initialState.clientDetails,
        error: payload,
      },
    }),

    // reset client
    resetClientDetailsAction: state => ({
      ...state,
      clientDetails: {
        ...state.clientDetails,
        list: [],
      },
    }),

    // create Job api
    createJobAction: (state, { payload }: CreateJobActionProps) => ({
      ...state,
      loading: true,
      error: initialState.error,
      createJobSuccessDetails: initialState.createJobSuccessDetails,
    }),
    createJobSuccessAction: (state, { payload }: CreateJobSuccessProps) => ({
      ...state,
      loading: initialState.loading,
      error: initialState.error,
      createJobSuccessDetails: payload,
    }),
    createJobErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      loading: initialState.loading,
      error: payload,
      createJobSuccessDetails: initialState.createJobSuccessDetails,
    }),
    resetCreateEditErrorAction: state => ({
      ...state,
      loading: initialState.loading,
      error: initialState.error,
      createJobSuccessDetails: initialState.createJobSuccessDetails,
      editJobSuccessDetails: initialState.editJobSuccessDetails,
    }),

    // edit Job api
    editJobAction: (state, { payload }: CreateJobActionProps) => ({
      ...state,
      loading: true,
      error: initialState.error,
      editJobSuccessDetails: initialState.editJobSuccessDetails,
    }),
    editJobSuccessAction: (state, { payload }: CreateJobSuccessProps) => ({
      ...state,
      loading: initialState.loading,
      error: initialState.error,
      editJobSuccessDetails: payload,
    }),
    editJobErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      loading: initialState.loading,
      error: payload,
      editJobSuccessDetails: initialState.editJobSuccessDetails,
    }),

    // covert a job to portfolio parent
    convertPortfolioParentAction: (
      state,
      { payload }: ConvertPortfolioParentActionProps,
    ) => ({
      ...state,
      convertPortfolioParent: {
        ...state.convertPortfolioParent,
        loading: true,
      },
    }),
    convertPortfolioParentSuccessAction: (
      state,
      { payload }: ConvertPortfolioParentSuccessProps,
    ) => ({
      ...state,
      convertPortfolioParent: {
        ...state.convertPortfolioParent,
        loading: initialState.convertPortfolioParent.loading,
        successDetail: payload,
      },
    }),
    convertPortfolioParentErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      convertPortfolioParent: {
        ...initialState.convertPortfolioParent,
        error: payload,
      },
    }),

    // get Parent Portfolio Job List
    getParentPortfolioListAction: (
      state,
      { payload }: GetParentJobActionProps,
    ) => ({
      ...state,
      parentPortfolioListDetails: {
        ...initialState.parentPortfolioListDetails,
        loading: true,
      },
    }),
    getParentPortfolioListSuccessAction: (
      state,
      { payload }: GetParentJobSuccessProps,
    ) => ({
      ...state,
      parentPortfolioListDetails: {
        ...initialState.parentPortfolioListDetails,
        list: payload,
      },
    }),
    getParentPortfolioListErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      parentPortfolioListDetails: {
        ...initialState.parentPortfolioListDetails,
        error: payload,
      },
    }),

    // covert a job to portfolio child
    convertPortfolioChildAction: (
      state,
      { payload }: ConvertPortfolioChildActionProps,
    ) => ({
      ...state,
      convertPortfolioChild: {
        ...initialState.convertPortfolioChild,
        loading: true,
      },
    }),
    convertPortfolioChildSuccessAction: (
      state,
      { payload }: ConvertPortfolioChildSuccessProps,
    ) => ({
      ...state,
      convertPortfolioChild: {
        ...initialState.convertPortfolioChild,
        successDetail: payload,
      },
    }),
    convertPortfolioChildErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      convertPortfolioChild: {
        ...initialState.convertPortfolioChild,
        error: payload,
      },
    }),

    // duplicate a portfolio parent for child
    duplicatePortfolioAction: (
      state,
      { payload }: DuplicatePortfolioActionProps,
    ) => ({
      ...state,
      duplicatePortfolio: {
        ...initialState.duplicatePortfolio,
        loading: true,
      },
    }),
    duplicatePortfolioSuccessAction: (
      state,
      { payload }: DuplicatePortfolioSuccessProps,
    ) => ({
      ...state,
      duplicatePortfolio: {
        ...initialState.duplicatePortfolio,
        successDetail: payload,
      },
    }),
    duplicatePortfolioErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      duplicatePortfolio: {
        ...initialState.duplicatePortfolio,
        error: payload,
      },
    }),

    // get Client details by Id
    getClientByIdAction: (state, { payload }: GetClientByIdActionProps) => ({
      ...state,
      clientDetailsById: {
        loading: true,
        error: initialState.clientDetailsById.error,
        successDetail: initialState.clientDetailsById.successDetail,
      },
    }),
    getClientByIdSuccessAction: (
      state,
      { payload }: GetClientByIdSuccessProps,
    ) => ({
      ...state,
      clientDetailsById: {
        loading: initialState.clientDetailsById.loading,
        successDetail: payload,
        error: initialState.clientDetailsById.error,
      },
    }),
    getClientByIdErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      clientDetailsById: {
        loading: initialState.clientDetailsById.loading,
        successDetail: initialState.clientDetailsById.successDetail,
        error: payload,
      },
    }),

    // Reset initialState
    resetCreateEditStateAction: () => initialState,
  },
});

export const { name: sliceName, actions } = slice;

export const useCreateEditJobLogModalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: createEditJobLogModalSaga });
  return { actions: slice.actions };
};

export default slice.reducer;
