import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { RootState } from 'types';

import { initialState } from 'app/pages/CreateEditJobLogModal/slice';

import { modifyDropdown } from 'utils/services/common.service';

import {
  getModifiedClientList,
  getModifiedParentPortfolioList,
} from 'app/pages/CreateEditJobLogModal/services';
import { sortBy } from 'lodash';

const selectCreateEditJobLogModalSlice = (state: RootState) =>
  state?.createEditJobLogModal || initialState;

export const selectCreateEditJobLogModal = createSelector(
  [selectCreateEditJobLogModalSlice],
  state => state,
);

export const selectLoading = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState =>
    subState.loading ||
    subState.scopeCategoriesDetails.loading ||
    subState.scopeDetails.loading ||
    subState.convertPortfolioParent.loading ||
    subState.parentPortfolioListDetails.loading ||
    subState.convertPortfolioChild.loading ||
    subState.duplicatePortfolio.loading ||
    subState.clientDetailsById.loading,
);

export const selectError = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState =>
    subState.error ||
    subState.scopeCategoriesDetails.error ||
    subState.scopeDetails.error ||
    subState.clientDetails.error ||
    subState.convertPortfolioParent.error ||
    subState.parentPortfolioListDetails.error ||
    subState.convertPortfolioChild.error ||
    subState.duplicatePortfolio.error ||
    subState.clientDetailsById.error,
);

export const selectScopeCategoryList = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState =>
    sortBy(
      modifyDropdown(
        subState.scopeCategoriesDetails.list,
        '_id',
        'displayName',
      ),
      ['displayName'],
    ),
);

export const selectClientDetails = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState => ({
    ...subState.clientDetails,
    list: getModifiedClientList(
      subState.clientDetails.list,
      '_id',
      'name',
      'acronym',
    ),
  }),
);

export const selectScopeDetails = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState => ({
    ...subState.scopeDetails,
    list: modifyDropdown(subState.scopeDetails.list, '_id', 'acronym'),
  }),
);

export const selectCreateJobSuccessDetails = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState => subState.createJobSuccessDetails,
);

export const selectConvertPortfolioSuccessDetails = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState => subState.convertPortfolioParent.successDetail,
);

export const selectParentPortfolioJobList = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState =>
    getModifiedParentPortfolioList(
      subState.parentPortfolioListDetails.list,
      'jobId',
    ),
);

export const selectConvertChildSuccessDetails = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState => subState.convertPortfolioChild.successDetail,
);

export const selectIsApiSuccess = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState =>
    !isEmpty(subState.createJobSuccessDetails) ||
    !isEmpty(subState.editJobSuccessDetails) ||
    !isEmpty(subState.convertPortfolioChild.successDetail) ||
    !isEmpty(subState.convertPortfolioParent.successDetail) ||
    !isEmpty(subState.duplicatePortfolio.successDetail),
);

export const selectClientDetailsByIds = createSelector(
  [selectCreateEditJobLogModalSlice],
  subState => subState.clientDetailsById.successDetail,
);
