// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Redux imports
import { actions } from 'store/auth';
import { useSocketSlice } from 'store/socket/index';
import { selectWhoisDetails } from 'store/auth/selectors';

import { LoaderErrorWrapper } from 'app/components';

// Constants
import { commonRoutes } from 'utils/constants/routes';

// Socket impo
import { io } from 'socket.io-client';
import config from 'config';

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { socketActions } = useSocketSlice();

  const [socketEstablished, setSocketEstablished] = useState(false);

  const auth = getAuth();

  const apiKeySecret = config.apiKeySecret;

  // const token = auth.currentUser;
  const loggedInUserDetails = useSelector(selectWhoisDetails);

  useEffect(() => {
    if (loggedInUserDetails.whoisDetails._id && !socketEstablished) {
      const URL = config.apiUrl;

      const socket = io(`${URL!}`, {
        transports: ['websocket'],
        path: '/ormscore/socket.io',
        query: {
          apikey: apiKeySecret ? apiKeySecret : '',
        },
        withCredentials: true,
        auth: {
          token: btoa(loggedInUserDetails.whoisDetails._id),
          // apikey: apiKeySecret,
        },
      });

      socket.on('connect', () => {
        if (socket.connected) dispatch(socketActions.setSocket({ socket }));
        else dispatch(socketActions.setSocket({ socket: null }));
      });

      socket.on('disconnect', () => {
        dispatch(socketActions.setSocket({ socket: null }));
      });
      setSocketEstablished(true);
    }
  }, [loggedInUserDetails]);

  useEffect(() => {
    if (isEmpty(loggedInUserDetails.whoisDetails)) {
      dispatch(actions.whoisAction());
    }
  }, []);

  onAuthStateChanged(auth, async user => {
    if (!user) {
      navigate(commonRoutes.loginPage);
    }
  });

  return (
    <LoaderErrorWrapper loading={loggedInUserDetails.loading} error={null}>
      {children}
    </LoaderErrorWrapper>
  );
};

export default ProtectedRoute;
