const HELMET_MESSAGES = {
  loginTitle: 'ORMS LOGIN',
  loginDesc: 'ORMS Login Page',
  forgotPwdTitle: 'ORMS FORGOT PASSWORD',
  forgotPwdDesc: 'ORMS Forgot Password Page',
  createPwdTitle: 'ORMS CREATE PASSWORD',
  createPwdDesc: 'ORMS Create Password Page',
  loginMFAVerificationTitle: 'ORMS LOGIN MFA VERIFICATION',
  loginMFAVerificationDesc: 'ORMS Login MFA Verification Page',
  homeTitle: 'ORMS HOME',
  homeDesc: 'ORMS Home Page',
  jobLogsTitle: 'ORMS JOB LOG',
  jobLogsDes: 'ORMS Job Log Page',
  jobLogDetailsTitle: 'ORMS JOB LOG DETAILS',
  jobLogDetailsDes: 'ORMS Job Log Details Page',
  jobStageTitle: 'ORMS JOB STAGE',
  jobStageDes: 'ORMS Job Stage Page',
  dataQuoteTitle: 'ORMS DATA QUOTE',
  dataQuoteDes: 'ORMS Data Quote Page',
  invoiceTitle: 'ORMS INVOICE',
  invoiceDes: 'ORMS Invoice Page',
  historyTitle: 'ORMS HISTORY',
  historyDes: 'ORMS History Page',
  clientsTitle: 'ORMS CLIENTS',
  clientsDes: 'ORMS Client Page',
  createClientTitle: 'ORMS ADD CLIENT',
  createClientDes: 'ORMS Add Client',
  scopesTitle: 'ORMS SCOPE',
  scopesDes: 'ORMS Scope Page',
  createScopeTitle: 'ORMS ADD SCOPE',
  createScopeDes: 'ORMS Add Scope',
  usersTitle: 'ORMS USERS',
  usersDes: 'ORMS Users Page',
  createOrmsUserTitle: 'ORMS ADD ORMS USER',
  createOrmsUserDes: 'ORMS add Orms User',
  createAwfUserTitle: 'ORMS ADD AWF USER',
  createAwfUserDes: 'ORMS add Awf User',
};

export default HELMET_MESSAGES;
