import { API_ROUTES, postCall, putCall } from 'utils/api';
import { INVOICE_ACTION } from 'utils/constants/common';

import { getModifiedApiError } from 'utils/services/common.service';

export const convertPortfolioParentApi = async payload => {
  try {
    const { data } = await putCall(
      `${API_ROUTES.convertParent}/${payload.jobId}`,
      {},
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const convertPortfolioChildApi = async payload => {
  try {
    const jobId = payload.jobId;
    delete payload.jobId;

    const { data } = await putCall(
      `${API_ROUTES.convertChild}/${jobId}`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const portfolioJobCompletionApi = async payload => {
  try {
    const { data } = await putCall(
      `${API_ROUTES.portfolioJobCompletion}/${payload.jobId}`,
      {},
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const portfolioCompletionApi = async payload => {
  try {
    const { data } = await putCall(
      `${API_ROUTES.portfolioCompletion}/${payload.jobId}`,
      {},
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const postInvoiceDetailsApi = async (payload, jobId) => {
  try {
    const { data } = await postCall(
      `${API_ROUTES.invoice}/${jobId}/${INVOICE_ACTION.generate}`,
      payload,
    );
    return data;
  } catch (error) {
    return { error: getModifiedApiError(error) };
  }
};

export const updateInvoiceDetailsApi = async (payload, jobId) => {
  try {
    const { data } = await postCall(
      `${API_ROUTES.invoice}/${jobId}/${INVOICE_ACTION.update}`,
      payload,
    );
    return data;
  } catch (error) {
    return { error: getModifiedApiError(error) };
  }
};
