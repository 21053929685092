import { concat, isUndefined } from 'lodash';
import { getFormattedDate, sortObjByKey } from 'utils/services/common.service';
import {
  jobChatHistoryApi,
  seenMessagesApi,
  sendMessageApi,
  subscribeChatApi,
} from './api';
import { ChatMessage, SendMessage, TimedMessages } from './types';

export const getFormattedChatTime = (chatTime: string) => {
  const dt = new Date(chatTime);
  const date = dt.getDate();

  const diff_days = new Date().getDate() - date;
  const diff_months = new Date().getMonth() - dt.getMonth();
  const diff_years = new Date().getFullYear() - dt.getFullYear();

  const isToday = diff_years === 0 && diff_months === 0 && diff_days === 0;
  const isYesterday = diff_years === 0 && diff_months === 0 && diff_days === 1;

  if (isToday) return 'Today';
  else if (isYesterday) return 'Yesterday';

  return getFormattedDate(chatTime);
};

export const getJobChatHistory = async (jobId, isHistory) => {
  return await jobChatHistoryApi(jobId, isHistory);
};

export const postChatMessage = async (jobId, message) => {
  const payload: SendMessage = {
    jobId,
    message,
  };
  return await sendMessageApi(payload);
};

export const subscribeChat = async jobId => {
  return await subscribeChatApi(jobId);
};

export const convertStreamedMessageToViewType = (
  messages: TimedMessages,
  incomingMessage: ChatMessage,
) => {
  const today = getFormattedDate(new Date().toString(), 'YYYY-MM-DD');
  if (isUndefined(messages[today])) messages[today] = [];
  messages[today].push({
    ...incomingMessage,
  });
  return messages;
};

export const seenMessages = async (messageIds: string[], jobId: string) => {
  return await seenMessagesApi({ messageIds }, jobId);
};

export const loadMoreMessages = async (
  jobId: string,
  isHistory: boolean,
  messageId: string,
) => {
  return await jobChatHistoryApi(jobId, isHistory, messageId);
};

export const addOlderMessages = (
  messages: TimedMessages,
  olderMessages: TimedMessages,
): TimedMessages => {
  for (const oldMessageDate in olderMessages) {
    if (!isUndefined(messages[oldMessageDate])) {
      messages[oldMessageDate] = concat(
        olderMessages[oldMessageDate],
        messages[oldMessageDate],
      );
    } else {
      messages[oldMessageDate] = [...olderMessages[oldMessageDate]];
    }
  }
  return sortObjByKey(messages);
};
