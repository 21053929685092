/**
 * Loader
 */

import { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingType {
  className?: string;
}

export const Loader = ({ className }: LoadingType) => {
  return (
    <div
      aria-label="loader"
      className={`loader-container d-flex align-center justify-center ${className}`}
    >
      <CircularProgress />
    </div>
  );
};

export default memo(Loader);
