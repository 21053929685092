import { memo } from 'react';

import Chip from '@mui/material/Chip';

import { MultiSelectChipProps } from 'app/components/MultiSelect/types';

export const MultiSelectChip = ({
  defaultDetails,
  additionalDetails,
}: MultiSelectChipProps) => (
  <>
    <Chip
      key={defaultDetails.key}
      label={defaultDetails.label}
      aria-label={defaultDetails.key}
      className={defaultDetails.className}
    />

    {additionalDetails.length > 1 && (
      <Chip
        key="length-chip"
        aria-label="length-chip"
        label={`${additionalDetails.length - 1}+`}
        className={additionalDetails.className}
      />
    )}
  </>
);

export default memo(MultiSelectChip);
