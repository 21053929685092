// Redux, saga, rtk imports
import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import jobFolderDetailsModalSaga from './saga';

// Types and interface imports
import {
  GetJobFolderDetailsProps,
  GetJobFolderDetailsSuccessProps,
  GetPublicUrlProps,
  JobFolderDetailsState,
} from './types';
import { ErrorProps } from 'store/auth/types';
import { JobFolderType } from '../types';

export const initialState: JobFolderDetailsState = {
  loading: false,
  error: null,
  jobFolderDetails: [] as JobFolderType[],
};

const slice = createSlice({
  name: 'jobFolderDetailsModal',
  initialState,
  reducers: {
    getJobFolderDetailsActions: (
      state,
      { payload }: GetJobFolderDetailsProps,
    ) => ({
      ...state,
      loading: true,
    }),

    getSingedUrlAction: (state, { payload }: GetPublicUrlProps) => ({
      ...state,
      loading: true,
    }),

    getJobFolderSuccessAction: (
      state,
      { payload }: GetJobFolderDetailsSuccessProps,
    ) => ({
      ...state,
      loading: initialState.loading,
      jobFolderDetails: payload,
    }),

    getJobFolderDetailsErrorAction: (state, { payload }: ErrorProps) => ({
      ...state,
      loading: initialState.loading,
      error: payload,
    }),

    resetJobFolderDetailsErrorAction: state => ({
      ...state,
      error: initialState.error,
    }),

    resetJobFolderDetailsStateAction: () => initialState,
  },
});

export const { name: sliceName, actions } = slice;

export const useJobFolderDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: jobFolderDetailsModalSaga });
  return { jobFolderActions: slice.actions };
};

export default slice.reducer;
