/**
 * Routes
 */

export const defaultPage = '1';
export const defaultPageSize = '10';

export const commonRoutes = {
  notFoundPage: '*',
  orms: '/orms',
  loginPage: '/login',
  forgotPasswordPage: '/forgot-password',
  createPasswordPage: '/create-password/:token',
  awf: '/awf',
};

export const ormsPages = {
  jobLogs: '/job-logs',
  jobLogDetails: '/job-logs/:jobId',
  jobHistoryDetails: '/history/:jobId',
  jobStageDetails: '/job-stage/:jobId',
  jobStage: '/job-stage',
  dataQuote: '/data-quote',
  invoices: '/invoices',
  history: '/history',
  clients: '/clients',
  scopes: '/scopes',
  users: '/users',
};

export const createEdit = {
  create: '/create',
  edit: '/edit',
};

export const ormsActionPages = {
  createClients: `${ormsPages.clients}${createEdit.create}`,
  editClient: `${ormsPages.clients}${createEdit.edit}/:id`,
  createScope: `${ormsPages.scopes}${createEdit.create}`,
  editScope: `${ormsPages.scopes}${createEdit.edit}/:id`,
};

export const usersActionsPages = {
  ormsUsers: `${ormsPages.users}${commonRoutes.orms}`,
  awfUsers: `${ormsPages.users}${commonRoutes.awf}`,
  createOrmsUser: `${ormsPages.users}${commonRoutes.orms}${createEdit.create}`,
  createAwfUser: `${ormsPages.users}${commonRoutes.awf}${createEdit.create}`,
  editOrmsUser: `${ormsPages.users}${commonRoutes.orms}${createEdit.edit}/:id`,
  editAwfUser: `${ormsPages.users}${commonRoutes.awf}${createEdit.edit}/:id`,
};

const paginationQueryParams = `?page=${defaultPage}&pageSize=${defaultPageSize}`;

export const paginatedRoutes = {
  jobLogsPaginatedRoute: `${commonRoutes.orms}${ormsPages.jobLogs}${paginationQueryParams}`,
  dataQuotePaginatedRoute: `${commonRoutes.orms}${ormsPages.dataQuote}${paginationQueryParams}`,
  invoicesPaginatedRoute: `${commonRoutes.orms}${ormsPages.invoices}${paginationQueryParams}`,
  clientsPaginatedRoute: `${commonRoutes.orms}${ormsPages.clients}${paginationQueryParams}`,
  scopesPaginatedRoute: `${commonRoutes.orms}${ormsPages.scopes}${paginationQueryParams}`,
  ormsUsersPaginatedRoute: `${commonRoutes.orms}${usersActionsPages.ormsUsers}${paginationQueryParams}`,
  awfUsersPaginatedRoute: `${commonRoutes.orms}${usersActionsPages.awfUsers}${paginationQueryParams}`,
};

export const ROUTES = {
  ...commonRoutes,
  ...ormsPages,
  ...ormsActionPages,
  ...paginatedRoutes,
  ...usersActionsPages,

  homePage: `${commonRoutes.orms}/*`,
  jobLogsPage: `${commonRoutes.orms}${ormsPages.jobLogs}`,
  jobLogDetailsPage: `${commonRoutes.orms}${ormsPages.jobLogDetails}`,
  jobStagePage: `${commonRoutes.orms}${ormsPages.jobStage}`,
  dataQuotePage: `${commonRoutes.orms}${ormsPages.dataQuote}`,
  invoicesPage: `${commonRoutes.orms}${ormsPages.invoices}`,
  historyPage: `${commonRoutes.orms}${ormsPages.history}`,
  clientsPage: `${commonRoutes.orms}${ormsPages.clients}`,
  createClientsPage: `${commonRoutes.orms}${ormsActionPages.createClients}`,
  editClientsPage: `${commonRoutes.orms}${ormsPages.clients}${createEdit.edit}`,
  scopesPage: `${commonRoutes.orms}${ormsPages.scopes}`,
  createScopePage: `${commonRoutes.orms}${ormsActionPages.createScope}`,
  editScopePage: `${commonRoutes.orms}${ormsPages.scopes}${createEdit.edit}`,
  usersPage: `${commonRoutes.orms}${ormsPages.users}`,
  ormsUsersPage: `${commonRoutes.orms}${usersActionsPages.ormsUsers}`,
  awfUsersPage: `${commonRoutes.orms}${usersActionsPages.awfUsers}`,
  createOrmsUserPage: `${commonRoutes.orms}${usersActionsPages.createOrmsUser}`,
  editOrmsUserPage: `${commonRoutes.orms}${ormsPages.users}${commonRoutes.orms}${createEdit.edit}`,
  createAwfUserPage: `${commonRoutes.orms}${usersActionsPages.createAwfUser}`,
  editAwfUserPage: `${commonRoutes.orms}${ormsPages.users}${commonRoutes.awf}${createEdit.edit}`,
};

export default ROUTES;
