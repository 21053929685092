import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

export const getModifiedApiData = data => {
  for (const key in data) {
    if (data[key] === '' || data[key] === undefined || isEqual(data[key], [])) {
      delete data[key];
    } else if (data[key] === '0') {
      data[key] = false;
    } else if (data[key] === '1') {
      data[key] = true;
    }
  }
  return data;
};

export const getBooleanValue = (internalQuote: boolean | undefined | null) => {
  if (isUndefined(internalQuote) || isNull(internalQuote)) {
    return '';
  }

  return internalQuote ? '1' : '0';
};

export const setModifiedValue = data => {
  return {
    ...data,
    sba: getBooleanValue(data?.sba),
  };
};
