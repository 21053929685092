/**
 * @description Allows to display badges over the favicon in the browser tab
 * @class TabBadger
 *
 * @public isBadgeVisibile
 * @returns boolean
 * @description Returns the status of the badge in the browser tab
 * @example new TabBadger().isBadgeVisibile
 *
 * @public badgeVisibility
 * @type boolean
 * @returns undefined
 * @description Sets the badge in the browser tab
 * @example new TabBadger().badgeVisibility = true
 *
 * @author @sarathsekaran
 * @version 0.0.1
 */

export class TabBadger {
  private canvas;
  private src;
  private ctx;
  private faviconSize;
  private img;
  private radius;
  private offset;
  private badgeSize;
  private backgroundColor;
  private size;
  private position;
  private visible;
  private onChange;

  constructor() {
    Object.assign(this, {
      backgroundColor: '#f00',
      color: '#fff',
      size: 0.7,
      position: 'se',
      radius: 25,
      onChange() {
        return;
      },
    });
    this.visible = false;
    this.canvas = document.createElement('canvas');
    this.src = this.src || this.faviconEL?.getAttribute('href');
    this.ctx = this.canvas.getContext('2d');
    this.ctx.fillStyle = '#ff0';
  }

  private faviconEL = document.querySelector('link[rel$=icon]');

  private _drawIcon() {
    this.ctx.clearRect(0, 0, this.faviconSize, this.faviconSize);
    this.ctx.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
  }

  private _drawShape() {
    const r = this.radius;
    const xa = this.offset.x;
    const ya = this.offset.y;
    const xb = this.offset.x + this.badgeSize;
    const yb = this.offset.y + this.badgeSize;
    this.ctx.beginPath();
    this.ctx.moveTo(xb - r, ya);
    this.ctx.quadraticCurveTo(xb, ya, xb, ya + r);
    this.ctx.lineTo(xb, yb - r);
    this.ctx.quadraticCurveTo(xb, yb, xb - r, yb);
    this.ctx.lineTo(xa + r, yb);
    this.ctx.quadraticCurveTo(xa, yb, xa, yb - r);
    this.ctx.lineTo(xa, ya + r);
    this.ctx.quadraticCurveTo(xa, ya, xa + r, ya);
    this.ctx.fillStyle = this.backgroundColor;
    this.ctx.fill();
    this.ctx.closePath();
  }

  private _drawFavicon() {
    this.faviconEL?.setAttribute('href', this.dataURL);
  }

  private _draw() {
    this._drawIcon();
    if (this.isBadgeVisibile) this._drawShape();
    this._drawFavicon();
  }

  private _setup() {
    this.faviconSize = this.img.naturalWidth;
    this.badgeSize = this.faviconSize * this.size;
    this.canvas.width = this.faviconSize;
    this.canvas.height = this.faviconSize;
    const sd = this.faviconSize - this.badgeSize;
    const sd2 = sd / 2;
    this.offset = {
      n: { x: sd2, y: 0 },
      e: { x: sd, y: sd2 },
      s: { x: sd2, y: sd },
      w: { x: 0, y: sd2 },
      nw: { x: 0, y: 0 },
      ne: { x: sd, y: 0 },
      sw: { x: 0, y: sd },
      se: { x: sd, y: sd },
    }[this.position];
  }

  private update() {
    if (this.img) {
      this._draw();
      if (this.onChange) this.onChange.call(this);
    } else {
      this.img = new Image();
      this.img.addEventListener('load', () => {
        this._setup();
        this._draw();
        if (this.onChange) this.onChange.call(this);
      });
      this.img.src = this.src;
    }
  }

  private get dataURL() {
    return this.canvas.toDataURL();
  }

  get isBadgeVisibile(): boolean {
    return this.visible;
  }

  set badgeVisibility(val: boolean) {
    this.visible = val;
    this.update();
  }
}
