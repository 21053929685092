/**
 * CreateEditJobLogModal
 */

import { useEffect, useCallback, memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

// Material
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Constants
import {
  BUTTON_COLORS,
  BUTTON_TYPES,
  ROUTES,
  BUTTON_VARIANT_THEMES,
} from 'utils/constants';
import {
  fieldNames,
  JobAddEditTypeEnum,
  modelDetailsByType,
  portfolioOptions,
  prefillFieldsForJobPath,
  JobPortfolioEnum,
} from 'app/pages/CreateEditJobLogModal/constants';

// Child Components
import {
  Form,
  Button,
  LoaderErrorWrapper,
  AddPortfolioParentModal,
  FormField,
} from 'app/components';
import JobDetailsSection from 'app/pages/CreateEditJobLogModal/JobDetailsSection';
import ClientJobDetailsSection from 'app/pages/CreateEditJobLogModal/ClientJobDetailsSection';
import SiteLocationDetailsSection from 'app/pages/CreateEditJobLogModal/SiteLocationDetailsSection';

// Slice - Actions & Selectors
import { useCreateEditJobLogModalSlice } from 'app/pages/CreateEditJobLogModal/slice';
import {
  selectLoading,
  selectError,
  selectScopeCategoryList,
  selectClientDetails,
  selectScopeDetails,
  selectIsApiSuccess,
  selectParentPortfolioJobList,
  selectClientDetailsByIds,
} from 'app/pages/CreateEditJobLogModal/slice/selectors';
import {
  selectJobLogDetails,
  selectJobDetailsClientList,
} from 'app/pages/JobLogDetailsPage/slice/selectors';
import { selectWhoisDetails } from 'store/auth/selectors';

// Services
import { modifyDropdown } from 'utils/services/common.service';
import { getModifiedFields } from 'app/pages/CreateEditJobLogModal/services';
import { getModifiedFormValues } from 'app/pages/CreateEditJobLogModal/services';

// Types
import {
  CreateEditJobLogModalProps,
  CreateJobDetailFormType,
} from 'app/pages/CreateEditJobLogModal/types';

import 'app/pages/CreateEditJobLogModal/style.scss';

export const CreateEditJobLogModal = ({
  jobId,
  action,
  onModalClose,
}: CreateEditJobLogModalProps) => {
  const { actions } = useCreateEditJobLogModalSlice();

  const [isAddPortfolioParentModal, setIsAddPortfolioParentModal] =
    useState(false);

  const [isDuplicate, setIsDuplicate] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDuplicate) action = JobAddEditTypeEnum.duplicateJob;
  }, [isDuplicate]);

  const isEditJob = action === JobAddEditTypeEnum.editJob;
  const isDuplicateJob = action === JobAddEditTypeEnum.duplicateJob;

  // Selector - CreateEditJobLogModal
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const clientListDetails = useSelector(selectClientDetails);
  const scopeCategoryList = useSelector(selectScopeCategoryList);
  const scopeListDetails = useSelector(selectScopeDetails);
  const whoisDetails = useSelector(selectWhoisDetails);
  const isApiSuccess = useSelector(selectIsApiSuccess);
  const parentPortfolioJobList = useSelector(selectParentPortfolioJobList);
  const clientById = useSelector(selectClientDetailsByIds);

  // Selector - JobLogDetailsPage
  const jobLogDetails = useSelector(selectJobLogDetails);
  const jobDetailsClientList = useSelector(selectJobDetailsClientList);

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const isFormDirty = form.formState.isDirty;
  const isFormValid = form.formState.isValid;
  const isEditFormUpdated = !isEditJob || !isEmpty(form.formState.dirtyFields);

  const watchClientId = form.watch(fieldNames.clientId);
  const watchScopeCategory = form.watch(fieldNames.scopeCategory);
  const watchSearchClient = form.watch(fieldNames.searchClient, '');
  const watchPortfolioType = form.watch(fieldNames.portfolioType, null);
  const watchPortfolioParentId = form.watch(fieldNames.portfolioParentId, '');

  useEffect(() => {
    dispatch(actions.getScopeCategoriesAction());

    // Trigger ParentPortfolios List when 'action' is 'editJob'
    isEditJob &&
      dispatch(
        actions.getParentPortfolioListAction({
          portfolio: JobPortfolioEnum.parent,
        }),
      );

    return () => {
      dispatch(actions.resetCreateEditStateAction());
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(watchClientId)) {
      dispatch(actions.getClientByIdAction(watchClientId));
    } else {
      form.setValue(fieldNames.clientJobRecipients, []);
    }
  }, [watchClientId]);

  useEffect(() => {
    if (watchPortfolioType === JobPortfolioEnum.child) {
      setIsAddPortfolioParentModal(true);
    }
  }, [watchPortfolioType]);

  // NOTE: this function is used to wait for 1 second to get the text in search field.
  const debounceGetClient = debounce(
    () => dispatch(actions.getClientAction({ searchKey: watchSearchClient })),
    1000,
  );

  /** NOTE:
   * Client API is retrieved, when the searchKey is greater than 5.
   * Client API is reset, when searchKey is less than 5.
   */
  useEffect(() => {
    if (watchSearchClient.length >= 5) {
      if (watchClientId === '') debounceGetClient();
    } else if (watchSearchClient.length < 5 && clientListDetails.list.length) {
      dispatch(actions.resetClientDetailsAction());
    }
  }, [watchSearchClient]);

  useEffect(() => {
    if ((isEditJob || isDuplicateJob) && !isEmpty(jobLogDetails)) {
      if (jobLogDetails?.client?.name) {
        dispatch(
          actions.getClientAction({
            searchKey: jobLogDetails?.client?.name,
          }),
        );
      }

      Object.entries(prefillFieldsForJobPath).map((value: [string, any]) => {
        const key = fieldNames[value[0]];
        const fieldValue = getModifiedFields(jobLogDetails, value[1]);

        form.setValue(key, fieldValue);
      });

      // if the isDuplicateJob is true then the scopeCategory and scopeId field should be empty
      if (isDuplicateJob || isDuplicate) {
        form.setValue(fieldNames.scopeCategory, '');
        form.setValue(fieldNames.scopeId, '');
        form.setValue(fieldNames.priorReport, '');
        form.setValue(fieldNames.externalDataNeeded, '');
      }
    }
  }, [isEditJob, isDuplicateJob, jobLogDetails, isDuplicate]);

  useEffect(() => {
    if (isApiSuccess) {
      onModalClose({ createApiSuccess: true });

      // If the job create or duplicate successfully then it redirect to jobLogPage
      if (!isEditJob) {
        navigate(ROUTES.jobLogsPaginatedRoute);
      }
    }
  }, [isApiSuccess]);

  useEffect(() => {
    if (!isEmpty(watchScopeCategory)) {
      dispatch(actions.getScopeAction({ categoryId: watchScopeCategory }));
    }
  }, [watchScopeCategory]);

  const onCloseError = useCallback(() => {
    dispatch(actions.resetCreateEditErrorAction());
  }, []);

  // On form change, clear the api error in redux-state
  useEffect(() => {
    if (!isEmpty(error)) {
      onCloseError();
    }
  }, [isFormDirty]);

  const triggerAddEditApi = useCallback(
    createEditJobApiData => {
      if (isEditJob && !isDuplicate && jobId) {
        createEditJobApiData?.isParentPortfolio &&
          dispatch(actions.convertPortfolioParentAction({ jobId }));

        dispatch(actions.editJobAction(createEditJobApiData));
      } else if ((isDuplicateJob || isDuplicate) && jobId) {
        dispatch(
          actions.duplicatePortfolioAction({
            createJob: createEditJobApiData,
            parentJobId: jobId!,
          }),
        );
      } else {
        dispatch(actions.createJobAction(createEditJobApiData));
      }
    },
    [isEditJob, isDuplicateJob, jobId, isDuplicate],
  );

  const onFormSubmit = useCallback(
    (formData: CreateJobDetailFormType) => {
      const createEditJobApiData = getModifiedFormValues(formData, isEditJob);
      triggerAddEditApi(createEditJobApiData);
      form.reset({}, { keepValues: true, keepDirty: false });
    },
    [isEditJob, isDuplicateJob, jobId, isDuplicate],
  );

  const jobRecipientsList = useMemo(() => {
    const jobRecipients = [clientById];

    if (
      (isEditJob || isDuplicateJob) &&
      watchClientId === jobLogDetails?.client._id
    ) {
      const list = jobLogDetails?.clientJob.jobRecipients.map(
        field => field._id ?? field,
      );

      form.setValue(fieldNames.clientJobRecipients, list);
    } else {
      jobRecipients[0]?.poc?.forEach(pocDetails => {
        if (pocDetails.isDefault) {
          form.setValue(fieldNames.clientJobRecipients, [
            pocDetails.userId._id,
          ]);
        }
      });
    }

    return jobRecipients.length
      ? modifyDropdown(
          jobRecipients[0]?.poc,
          'userId._id',
          'userId.firstName',
          'userId.lastName',
        )
      : [];
  }, [
    clientById,
    watchClientId,
    isEditJob,
    JSON.stringify(clientListDetails.list),
    isDuplicateJob,
  ]);

  const onCloseModalClick = useCallback(() => {
    onModalClose({ createApiSuccess: false });
  }, []);

  const onAddPortfolioParentModalClose = useCallback(() => {
    setIsAddPortfolioParentModal(false);
    form.resetField(fieldNames.portfolioType);
  }, []);

  const onPortfolioParentModalSave = useCallback(() => {
    onAddPortfolioParentModalClose();
    dispatch(
      actions.convertPortfolioChildAction({
        jobId: jobId!,
        parentJobId: watchPortfolioParentId,
      }),
    );
  }, [watchPortfolioParentId]);

  return (
    <Drawer anchor="right" open={true} onClose={onCloseModalClick}>
      <LoaderErrorWrapper
        loading={loading}
        error={error}
        onCloseError={onCloseError}
      >
        <Form formName="job-log-form" onSubmit={onFormSubmit} form={form}>
          <div className="side-drawer-section">
            <div className="side-drawer-header d-flex align-center justify-between py-12 px-24">
              <h3 className="f-20 f-w-700 text-primary" aria-label="title">
                {/* if the action is null then the default action is createJob */}
                {
                  modelDetailsByType[
                    (isDuplicate ? JobAddEditTypeEnum.duplicateJob : action) ??
                      JobAddEditTypeEnum.createJob
                  ].title
                }
              </h3>

              {!jobLogDetails?.parentPortfolioJobId &&
                jobLogDetails?.portfolioId &&
                !isDuplicate &&
                action === JobAddEditTypeEnum.editJob && (
                  <Button
                    className="duplicate-btn"
                    key="deduplicate"
                    aria-label="deduplicateBtn"
                    color={BUTTON_COLORS.primary}
                    variant={BUTTON_VARIANT_THEMES.contained}
                    onClick={() => setIsDuplicate(true)}
                    name="Duplicate"
                  />
                )}

              <IconButton
                aria-label="close-icon"
                className="d-flex"
                onClick={onCloseModalClick}
              >
                <CloseIcon className="text-secondary" />
              </IconButton>
            </div>

            {isAddPortfolioParentModal && (
              <AddPortfolioParentModal
                onModalClose={onAddPortfolioParentModalClose}
                parentPortfolioJobList={parentPortfolioJobList}
                onModalSave={onPortfolioParentModalSave}
              />
            )}

            <div className="side-drawer-content p-16">
              {/* JOB ID DETAILS SECTION */}
              <JobDetailsSection
                clientList={clientListDetails.list}
                autoCompleteDefaultValue={
                  !isEmpty(jobDetailsClientList?.[0].value)
                    ? jobDetailsClientList[0]
                    : ''
                }
                scopeCategoryList={scopeCategoryList}
                scopeList={scopeListDetails.list}
                scopeLoading={scopeListDetails.loading}
                isClientListLoading={clientListDetails.loading}
                whoisDetails={whoisDetails.whoisDetails}
                isEditJob={isEditJob}
              />

              {/* CLIENT JOB DETAILS SECTION */}
              <ClientJobDetailsSection
                jobRecipientsList={
                  !isEmpty(watchClientId) ? jobRecipientsList : []
                }
              />

              {/* SITE LOCATION DETAILS SECTION */}
              <SiteLocationDetailsSection />
            </div>

            {/* FOOTER */}
            <div className="side-drawer-footer d-flex justify-end py-16 px-24">
              {!isDuplicate &&
                isEditJob &&
                !(
                  jobLogDetails?.portfolioId ||
                  jobLogDetails?.parentPortfolioJobId
                ) && (
                  <FormField.Select
                    name={fieldNames.portfolioType}
                    aria-label="portfolioTypeSelect"
                    placeholder="Portfolio"
                    defaultValue=""
                    className="drawer-dropdown-menu mw-140 d-flex"
                    options={portfolioOptions}
                    formControlProps={{
                      variant: BUTTON_VARIANT_THEMES.outlined,
                      className: 'flex-1 mw-140 mr-12',
                    }}
                  />
                )}

              <Button
                //  if the action is null then the default action is createJob
                name={
                  isDuplicate
                    ? modelDetailsByType[JobAddEditTypeEnum.duplicateJob]
                        .footerBtnName
                    : modelDetailsByType[action ?? JobAddEditTypeEnum.createJob]
                        .footerBtnName
                }
                aria-label="submit"
                variant={BUTTON_VARIANT_THEMES.contained}
                color={BUTTON_COLORS.primary}
                type={BUTTON_TYPES.submit}
                disabled={!isFormValid || !isEditFormUpdated}
              />
            </div>
          </div>
        </Form>
      </LoaderErrorWrapper>
    </Drawer>
  );
};

export default memo(CreateEditJobLogModal);
