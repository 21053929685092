/**
 * ButtonLink
 */

import { memo } from 'react';
import { Link } from 'react-router-dom';

// Material
import { Button as MuiButton } from '@mui/material';

import { ButtonProps } from 'app/components/Button/types';

export interface ButtonLinkProps extends ButtonProps {
  redirectTo: string;
}

export const ButtonLink = ({
  color,
  variant,
  className = '',
  name,
  type,
  children,
  disabled,
  redirectTo,
  ...props
}: ButtonLinkProps) => (
  <Link
    to={redirectTo}
    onClick={event => {
      if (disabled) {
        event.preventDefault();
      }
    }}
  >
    <MuiButton
      variant={variant}
      className={className}
      color={color}
      type={type ?? 'button'}
      disabled={disabled}
      {...props}
    >
      {name || children}
    </MuiButton>
  </Link>
);

export default memo(ButtonLink);
