/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for InvoicesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const InvoicesPage = lazyLoad(
  () => import('./index'),
  module => module.InvoicesPage,
);

export default InvoicesPage;
