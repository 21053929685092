/**
 * services
 */

// MUI
import { MultiSelectType } from 'app/components/MultiSelect/types';

import MuliSelectChip from 'app/components/MultiSelect/MultiSelectChip';
import { SelectOptionType } from 'app/components/Select/types';
import { ReactNode } from 'react';

export const getSelectedFieldByLength = (
  options: SelectOptionType[],
  selected: string[],
  length: number,
): SelectOptionType | undefined =>
  options.find((option: SelectOptionType) => selected[length] === option.value);

// defaultMultiSelectRenderValue
export const defaultMultiSelectRenderValue =
  (
    options: SelectOptionType[],
    placeHolder?: ReactNode,
    renderValue?: MultiSelectType['renderValue'] | undefined,
  ) =>
  (selected: string[] | [] = []) => {
    if (selected?.length === 0 && placeHolder) return placeHolder;

    if (!!renderValue) {
      return renderValue(selected);
    }

    const selectedFields = getSelectedFieldByLength(
      options,
      selected,
      selected.length > 1 ? selected.length - 1 : 0,
    );

    return (
      selectedFields?.displayName && (
        <MuliSelectChip
          defaultDetails={{
            key: selectedFields?.displayName,
            label: selectedFields?.displayName,
          }}
          additionalDetails={{
            length: selected.length,
            className: 'ml-4',
          }}
        />
      )
    );
  };
