// React imports
import { Button } from '@mui/material';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React router imports
import { useParams } from 'react-router-dom';

// Dropzone imports
import Dropzone from 'react-dropzone';
import JobFilesView from './JobFilesView';

// Interfaces and types
import { JobFile, JobFileUploderViewModal } from './types';

// Slice
import { uploadJobFilesApi } from './slice/api';

// Redux imports
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';

// Styles
import './styles.scss';
import { BUTTON_VARIANT_THEMES } from 'styles/assets';
import LoaderErrorWrapper from '../LoaderErrorWrapper';

// utils
import { join } from 'lodash';
import { useJobFolderDetailsSlice } from './slice';
import { MAX_UPLOAD_SIZE } from 'utils/constants';

function JobFileUploderView(props: JobFileUploderViewModal) {
  const { folderKey } = props;
  const [addedFiles, setAddedFiles] = useState<JobFile[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [uploadBtn, setUploadBtn] = useState<boolean>(false);
  const [uploadJobResponse, setUploadJobResponse] = useState({
    loading: false,
    error: [],
    response: '',
  });

  const job = useSelector(selectJobLogDetails);
  const dispatch = useDispatch();
  const { jobFolderActions } = useJobFolderDetailsSlice();
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();
  const jobId = jobIdParam ? parseInt(jobIdParam) : 0;

  const getErrorMessage = (exceededUploadSize = false) => {
    if (exceededUploadSize) {
    }
    if (uploadJobResponse?.error?.length) {
      const errorMessage = join(uploadJobResponse.error, ', ');
      return `${errorMessage} file(s) failed to upload`;
    }
  };

  const addFiles = acceptedFiles => {
    const totalSize =
      files.reduce((total, file) => total + file.size, 0) +
      acceptedFiles.reduce((total, file) => total + file.size, 0);
    if (totalSize >= MAX_UPLOAD_SIZE) {
      return alert(
        `You can not upload more than ${MAX_UPLOAD_SIZE / 1024 ** 2}MB`,
      );
    }

    const _files: JobFile[] = acceptedFiles.map(file => {
      return {
        id: file.lastModified,
        displayName: file.name,
        fileName: file.path,
      };
    });
    setAddedFiles(prev => [...prev, ..._files]);
    setFiles(prev => [...prev, ...acceptedFiles]);
    setUploadBtn(true);
  };

  const uploadFiles = async () => {
    setUploadJobResponse(preState => ({ ...preState, loading: true }));
    const uploadJob = await uploadJobFilesApi(files, job.jobId, folderKey);
    if (uploadJob?.error?.length) {
      setUploadJobResponse(preState => ({
        ...preState,
        error: uploadJob?.error,
        loading: false,
      }));
    } else {
      setUploadJobResponse(preState => ({
        ...preState,
        response: uploadJob.message,
        loading: false,
      }));

      props.backToFileUploderViews(false);
    }
    dispatch(
      jobFolderActions.getJobFolderDetailsActions({
        jobId,
      }),
    );
    setUploadBtn(false);
    setFiles([]);
    setAddedFiles([]);
  };

  const clearErrorMesage = () => {
    setUploadJobResponse(preState => ({
      ...preState,
      loading: false,
      error: [],
    }));
  };

  return (
    <LoaderErrorWrapper
      loading={uploadJobResponse.loading}
      error={getErrorMessage()}
      onCloseError={clearErrorMesage}
    >
      <div className="container">
        <div className="h-230">
          <Dropzone onDrop={acceptedFiles => addFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className="dropzone cursor-pointer">
                  <input {...getInputProps()} />
                  <p className="dropzone-vertical-center-label">
                    Drag and drop some files here, or click to select files
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div className="d-flex">
          {uploadBtn && (
            <Button
              type="button"
              onClick={uploadFiles}
              className="my-8 flex-end"
              variant={BUTTON_VARIANT_THEMES.contained}
            >
              Upload
            </Button>
          )}
        </div>
        <div className="overflow-y-scroll h-200">
          <JobFilesView
            folderKey={folderKey}
            files={addedFiles}
            isUploaderView={true}
          />
        </div>
      </div>
    </LoaderErrorWrapper>
  );
}

export default memo(JobFileUploderView);
