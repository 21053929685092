import { API_ROUTES, getCall } from 'utils/api';

import {
  getModifiedApiError,
  getModifiedApiUrl,
} from 'utils/services/common.service';

export const jobLogsPageApi = async payload => {
  try {
    const jobLogApiUrl = getModifiedApiUrl(API_ROUTES.job, {
      ...payload,
      isAsc: false, // isAsc=false for now, since we don't have any sorting feature
    });

    const { data } = await getCall(jobLogApiUrl, payload);
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};
