import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import jobLogAssociatesSaga from './saga';
import {
  JobLogAssociatesState,
  UpdateJobAssociateProps,
  UpdateJobAssociateSuccessProps,
} from './types';
import { ErrorProps } from 'store/auth/types';

export const initialState: JobLogAssociatesState = {
  loading: false,
  error: null,
  successData: {},
};

const slice = createSlice({
  name: 'jobLogAssociates',
  initialState,
  reducers: {
    // update jobLog Association
    updateJobAssociationAction: (
      state,
      { payload }: UpdateJobAssociateProps,
    ) => ({
      ...state,
      loading: true,
    }),

    updateJobAssociateSuccessProps: (
      state,
      { payload }: UpdateJobAssociateSuccessProps,
    ) => ({
      ...state,
      loading: initialState.loading,
      successData: payload,
    }),

    updateJobAssociateErrorProps: (state, { payload }: ErrorProps) => ({
      ...state,
      loading: initialState.loading,
      error: payload,
    }),

    resetError: state => ({
      ...state,
      error: null,
    }),

    restState: state => ({
      ...initialState,
    }),
  },
});

export const { name: sliceName, actions } = slice;

export const useJobLogAssociatesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: jobLogAssociatesSaga });
  return { actions: slice.actions };
};

export default slice.reducer;
