/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for CreateEditOrmsUserPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const CreateEditOrmsUserPage = lazyLoad(
  () => import('./index'),
  module => module.CreateEditOrmsUserPage,
);

export default CreateEditOrmsUserPage;
