/**
 * Common Themes Styles ClassNames for Material will be defined here
 */

export enum BUTTON_COLORS {
  'inherit' = 'inherit',
  'primary' = 'primary',
  'secondary' = 'secondary',
  'success' = 'success',
  'error' = 'error',
  'info' = 'info',
  'warning' = 'warning',
}

export enum BUTTON_VARIANT_THEMES {
  'text' = 'text',
  'outlined' = 'outlined',
  'contained' = 'contained',
}

export enum BUTTON_TYPES {
  'button' = 'button',
  'submit' = 'submit',
  'reset' = 'reset',
}

export enum BUTTON_SIZE {
  'small' = 'small',
  'medium' = 'medium',
  'large' = 'large',
}

export enum BUTTON_TARGETS {
  blank = '_blank',
}

export const COMMON_CLASSNAMES = {
  mediumBtnClass: 'MuiButton-sizeMedium',
  tableEmpty: 'table-empty',
  tableContent: 'table-content',
};
