import { createSelector } from '@reduxjs/toolkit';

import { initialState } from 'app/pages/JobLogDetailsCenterSection/slice';

import { modifyDropdown } from 'utils/services/common.service';

import { RootState } from 'types';

const selectJobLogDetailsCenterSectionSlice = (state: RootState) =>
  state?.jobLogDetailsCenterSection || initialState;

export const selectJobLogDetailsCenterSection = createSelector(
  [selectJobLogDetailsCenterSectionSlice],
  state => state,
);

export const selectCenterSectionLoading = createSelector(
  [selectJobLogDetailsCenterSectionSlice],
  state =>
    state.ormsUserListDetails.loading ||
    state.awfExtConsUserDetails.loading ||
    state.awfIntConsUserDetails.loading ||
    state.awfContractorUserDetails.loading ||
    state.updateJobCenterSectionDetails.loading,
);

export const selectCenterSectionError = createSelector(
  [selectJobLogDetailsCenterSectionSlice],
  state =>
    state.ormsUserListDetails.error ||
    state.awfExtConsUserDetails.error ||
    state.awfIntConsUserDetails.error ||
    state.awfContractorUserDetails.error ||
    state.updateJobCenterSectionDetails.error,
);

export const selectOrmsUserList = createSelector(
  [selectJobLogDetailsCenterSectionSlice],
  state =>
    modifyDropdown(
      state.ormsUserListDetails.list,
      '_id',
      'firstName',
      'lastName',
    ),
);

export const selectAwfExtConsUserList = createSelector(
  [selectJobLogDetailsCenterSectionSlice],
  state =>
    modifyDropdown(
      state.awfExtConsUserDetails.list,
      '_id',
      'firstName',
      'lastName',
    ),
);

export const selectAwfInternalConsUserList = createSelector(
  [selectJobLogDetailsCenterSectionSlice],
  state =>
    modifyDropdown(
      state.awfIntConsUserDetails.list,
      '_id',
      'firstName',
      'lastName',
    ),
);

export const selectAwfContractorUserList = createSelector(
  [selectJobLogDetailsCenterSectionSlice],
  state =>
    modifyDropdown(
      state.awfContractorUserDetails.list,
      '_id',
      'firstName',
      'lastName',
    ),
);

export const selectAwfContractorOrmsUserList = createSelector(
  [selectJobLogDetailsCenterSectionSlice],
  state => [
    ...modifyDropdown(
      state.ormsUserListDetails.list,
      '_id',
      'firstName',
      'lastName',
    ),
    ...modifyDropdown(
      state.awfContractorUserDetails.list,
      '_id',
      'firstName',
      'lastName',
    ),
  ],
);
