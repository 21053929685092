const placeholders = {
  search: 'Search by Name',
  searchClient: 'Search by',
  searchJob: 'Search',
  searchScopeName: 'Search by Scope Name',
  searchHistory: 'Search Job Log History',
  searchFirstName: 'Search by First Name',
  SearchAWFCompanyName: 'Search',

  // Input Placeholder
  enterPassword: '**************',
  enterClientName: 'Enter Client Name',
  enterAcronym: 'Enter Acronym',
  enterAwfAbbreviation: 'Enter AWF Abbreviation',
  enterPhoneCode: 'Enter Phone Code',
  enterPhone: 'Enter Phone Number',
  enterAddress: 'Enter Address',
  enterCity: 'Enter City',
  enterState: 'Enter State',
  enterZip: 'Enter Zip',
  enterDeliveryEmail1:
    'Delivery Email 1st Paragraph, Second Sentence (Specific to Client Delivery)',
  enterDeliveryEmail2:
    'Delivery Email 1st Paragraph, Third Sentence (Specific to Client Delivery)',
  enterDeliveryEmail3:
    'Delivery Email 1st Paragraph, Fourth Sentence (Specific to Client Delivery)',
  enterFirstName: 'Enter First Name',
  enterLastName: 'Enter Last Name',
  enterEmail: 'Enter Email',
  enterPocEmail: 'Enter POC Email',
  enterYourEmail: 'Enter your Email',
  enterNaicsCode: 'Enter NAICS Code',
  enterInitials: 'Enter Initials',
  enterScopeName: 'Enter Scope Name',
  enterDueDate: 'Enter Due Date',
  enterPrice: 'Enter Price',
  enterRegisteredEmail: 'Enter your registered email',
  enterConsultantAssignment: 'Enter Consultant Assignment',
  enterOneTimePassword: 'Enter One Time Password',
  enterLoanNumber: 'Enter Loan Number',
  enterClientJobName: 'Enter Job Name',
  enterPropertyType: 'Enter Property Type',
  enterSiteAcres: 'Enter Site Acres',
  enterSiteSquareFootage: 'Enter Site Square Footage',
  enterSiteAddress: 'Enter Site Address',
  enterSiteCity: 'Enter Site City',
  enterSiteState: 'Enter Site State Abbreviation',
  date: 'DD MMM YYYY',
  enterCompanyName: 'Enter AWF company name',
  enterInsuranceCertificate: 'Enter Insurance Certificate',
  enterExpirationDate: 'Enter Expiration Date',
  enterPocInitials: 'Enter POC Initials',
  enterJobNumber: 'Enter Job Number',
  noFileSelected: 'No file selected',
  quoteDays: 'Auto Populate the # of Business Days',
  quoteAmount: '$',
  initial: 'Initial',
  enterIncidentReportDate: 'Enter Incident Report Date',

  // Select Placeholder
  select: 'Select',
  selectClient: 'Search Client by typing 5 characters',
  selectScopeCategory: 'Select Scope Category',
  selectScope: 'Select Scope',
  selectPortfolio: 'Select Portfolio Id',
  selectConstructionDate: 'Select Construction Year',
  selectJobRecipients: 'Select Job Recipients',
  selectYesNo: 'Select yes or no',
  selectGroup: 'Select Group',
  selectTemplate: 'Select Template',
  selectReportedBy: 'Select Reported By',
  selectJobLog: 'Select Job Log',
  selectDueDate: 'Select Due Date',
  selectORMSMember: 'Select ORMS member',
  selectExternalConsultant: 'Select External Consultant',
  selectORMS: 'Select ORMS',
  selectInternalConsultant: 'Select Internal Consultant (AWF)',
  selectContractor: 'Select Contractor (AWF) or ORMS',
  selectJob: 'Select any one job',
  selectChooseYesNo: 'Choose Yes or No',
  selectFinalReview: 'Select Final Review',
  SelectJobStage: ' Select Job Stage',
  SelectPortfolio: 'Select portfolio Type',
  selectSortBy: 'Select Sort By',
  selectAsc: 'Select Asc Or Des',
  selectAwfUser: 'Select AWF User',
  selectTemplateName: 'Select Template Name',
};

export default placeholders;
