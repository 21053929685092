import { API_ROUTES, getCall, postCall, putCall } from 'utils/api';
import { getModifiedApiError } from 'utils/services/common.service';
import { SeenMessages, SendMessage } from './types';

export const jobChatHistoryApi = async (
  jobId: string,
  isHistory?: boolean,
  messageId?: string,
) => {
  try {
    let url;
    if (messageId)
      url = `${API_ROUTES.chatHistory}?jobId=${jobId}&messageId=${messageId}&isHistory=${isHistory}`;
    else
      url = `${API_ROUTES.chatHistory}?jobId=${jobId}&isHistory=${isHistory}`;
    const { data } = await getCall(url);
    return data;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};

export const sendMessageApi = async (payload: SendMessage) => {
  try {
    const { data } = await postCall(`${API_ROUTES.sendMessage}`, payload);
    return data;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};

export const subscribeChatApi = async jobId => {
  try {
    const { data } = await getCall(
      `${API_ROUTES.subscribeChat}?streamId=${jobId}`,
    );
    return data;
  } catch (error) {
    return undefined;
  }
};

// NOTE: payload in the function parameter can be used if the messages in the viewport only has be set as read
export const seenMessagesApi = async (payload: SeenMessages, jobId: string) => {
  try {
    const { data } = await putCall(`${API_ROUTES.chat}/${jobId}/seenBy`);
    return data;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};
