import { call, put, takeLatest, all } from 'redux-saga/effects';

import { actions } from 'app/components/NotificationPopover/slice';

import {
  markAllAsReadApi,
  notificationApi,
  updateNotificationApi,
} from './api';
import { PayloadAction } from '@reduxjs/toolkit';

export function* getNotification() {
  try {
    const { list, hasUnReadMsg } = yield call(notificationApi);
    yield put(actions.pushNotification(list));
    yield put(actions.hasUnReadMsg(hasUnReadMsg));
  } catch (err: any) {
    yield put(actions.getNotificationError(err?.message));
  }
}

export function* updatetNotificationStatus({
  payload,
}: PayloadAction<string[]>) {
  try {
    const { list, hasUnReadMsg } = yield call(updateNotificationApi, payload);
    yield put(actions.setNotificationList(list));
    yield put(actions.hasUnReadMsg(hasUnReadMsg));
  } catch (err: any) {
    yield put(actions.getNotificationError(err?.message));
  }
}

export function* markAllAsRead() {
  try {
    const { list, hasUnReadMsg } = yield call(markAllAsReadApi);
    yield put(actions.setNotificationList(list));
    yield put(actions.hasUnReadMsg(hasUnReadMsg));
  } catch (err: any) {
    yield put(actions.getNotificationError(err?.message));
  }
}

export function* watchGetNotification() {
  yield takeLatest(actions.getNotificationAction.type, getNotification);
}

export function* watchupdatetNotificationStatus() {
  yield takeLatest(
    actions.updateNotificationStatus.type,
    updatetNotificationStatus,
  );
}

export function* watchMarkAllasRead() {
  yield takeLatest(actions.markAllAsRead.type, markAllAsRead);
}

export function* notificationSaga() {
  yield all([
    watchGetNotification(),
    watchupdatetNotificationStatus(),
    watchMarkAllasRead(),
  ]);
}
