/**
 * TableFooter
 */

import { memo } from 'react';

// Children Components
import { Pagination, Select } from 'app/components';

// Router imports
import { useLocation } from 'react-router-dom';

// Constants
import { showEntriesOptions } from 'app/components/TableFooter/constants';
import { defaultPageSize } from 'utils/constants/routes';
import { pageRecords } from 'utils/constants/common';

//Material
import { SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';

// Types
import { TableFooterType } from 'app/components/TableFooter/types';

export const TableFooter = ({
  page,
  pageSize,
  pageCount,
  showEntriesChange,
  onPageChange,
}: TableFooterType) => {
  const location = useLocation();
  const currentPage = `/${location.pathname.split('/')[2]}`;

  if (localStorage.getItem('pageRecords')) {
    const prevPageCount = JSON.parse(localStorage.getItem('pageRecords')!);
    pageSize = parseInt(prevPageCount[currentPage]);
  } else {
    localStorage.setItem('pageRecords', JSON.stringify(pageRecords));
    pageSize = parseInt(defaultPageSize);
  }

  const onPageSizeChange = (changedPageSize: number) => {
    const prevPageCount = JSON.parse(localStorage.getItem('pageRecords')!);
    pageSize = changedPageSize;
    const pageRecords = {
      ...prevPageCount,
      [currentPage]: changedPageSize,
    };
    localStorage.setItem('pageRecords', JSON.stringify(pageRecords));
  };

  return (pageSize > 10 || pageCount > 1) && page <= pageCount ? (
    <div className="table-footer">
      <div className="table-select d-flex justify-between mt-12">
        <FormControl variant="outlined" className="pagination-select mw-160">
          <Select
            name="showEntries"
            placeholder={`${pageSize} records per page`}
            aria-label="showEntries"
            options={showEntriesOptions}
            onChange={(event: SelectChangeEvent) => {
              showEntriesChange(Number(event.target.value));
              onPageSizeChange(Number(event.target.value));
            }}
          />
        </FormControl>

        {pageCount > 1 && (
          <div className="d-flex align-center">
            <Pagination
              page={page}
              pageCount={pageCount}
              onPageChange={onPageChange}
            />
            <p className="f-12 f-w-500 text-tertiary ml-12">{`Showing ${page} of ${pageCount}`}</p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default memo(TableFooter);
