import { all, call, put, takeLatest } from 'redux-saga/effects';

import { actions } from 'app/pages/JobLogDetailsPage/slice';

import {
  getJobLogDetailsApi,
  addTemplateApi,
  getHistoryJobLogDetailsApi,
} from 'app/pages/JobLogDetailsPage/slice/api';

import {
  GetJobLogDetailsProps,
  AddJobTemplateProps,
} from 'app/pages/JobLogDetailsPage/slice/types';

// Constants
import { SUCCESS_MESSAGES } from 'utils/constants';

// Snackbar
import { actions as snackbarActions } from 'store/snackbar';
import { formatSnackBarSuccess } from 'utils/services/common.service';

export function* getJobLogDetails({
  payload: { jobId, isHistoryPage = false },
}: GetJobLogDetailsProps) {
  try {
    const jobDetails = isHistoryPage
      ? yield call(getHistoryJobLogDetailsApi, jobId)
      : yield call(getJobLogDetailsApi, jobId);
    yield put(
      actions.getJobLogDetailsSuccessAction(
        isHistoryPage ? jobDetails : jobDetails.data,
      ),
    );
  } catch (err: any) {
    yield put(actions.getJobLogDetailsErrorAction(err?.message));
  }
}

export function* watchGetJobLogDetails() {
  yield takeLatest(actions.getJobLogDetailsAction.type, getJobLogDetails);
}

export function* addJobTemplate({ payload }: AddJobTemplateProps) {
  try {
    const { data } = yield call(addTemplateApi, payload);
    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(SUCCESS_MESSAGES.jobTemplateUpdateSuccessfully),
      ),
    );
    yield put(actions.addJobTemplateSuccessAction(data));
    yield put(actions.updateJobLogDetailsSuccessAction(data));
  } catch (err: any) {
    yield put(actions.addJobTemplateErrorAction(err?.message));
  }
}

export function* watchAddJobTemplate() {
  yield takeLatest(actions.addJobTemplateAction.type, addJobTemplate);
}

export default function* jobLogDetailsPageSaga() {
  yield all([watchGetJobLogDetails(), watchAddJobTemplate()]);
}
