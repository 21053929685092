/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for JobStagePage
 *
 */

import { lazyLoad } from 'utils/loadable';

const JobStagePage = lazyLoad(
  () => import('./index'),
  module => module.JobStagePage,
);

export default JobStagePage;
