/* --- STATE --- */

import { PayloadAction } from '@reduxjs/toolkit';

export enum ActionType {
  create = 'create',
  edit = 'edit',
  delete = 'delete',
}

export enum NotificationType {
  job = 'job',
  awf = 'awf',
  scope = 'scope',
  orms = 'orms',
  eris = 'eris',
}

export interface ReadStatusType {
  id: string;
  isMsgRead: boolean;
}
export interface NotificationDetails {
  actionType: ActionType;
  title: string;
  message: string;
  notificationType: NotificationType;
  notifiedBy: Date;
  createdAt: Date;
  updatedAt: Date;
  readStatus: ReadStatusType[];
  performedOn: string;
}

export interface NotificationState {
  notifications: NotificationDetails[];
  error: null | string;
  hasUnReadMsg?: boolean;
}

export interface NotificationId {
  id: string;
}
