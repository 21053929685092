/**
 * Field
 */

import { TextField, Switch } from '@mui/material';

// Components
import PasswordInput from 'app/components/PasswordInput';
import Checkbox from 'app/components/Checkbox';
import Select from 'app/components/Select';
import MultiSelect from 'app/components/MultiSelect';
import DatePicker from 'app/components/DatePicker';
import AutocompleteSelect from 'app/components/AutocompleteSelect';

import { generateField, typeOfFields } from 'app/components/Form/GenerateField';

const Field = {
  Input: generateField(TextField),
  PasswordInput: generateField(PasswordInput),
  Toggle: generateField(Switch, typeOfFields.toggle),
  Checkbox: generateField(Checkbox, typeOfFields.checkbox),
  Select: generateField(Select, typeOfFields.control),
  MultiSelect: generateField(MultiSelect, typeOfFields.control),
  DatePicker: generateField(DatePicker, typeOfFields.control),
  Autocomplete: generateField(AutocompleteSelect, typeOfFields.control),
};

export default Field;
