import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from 'app/pages/JobLogDetailsPage/slice';

import { getModifiedClientList } from 'app/pages/CreateEditJobLogModal/services';
import { getModifiedJobTemplates } from 'app/pages/JobLogDetailsPage/services';
import { JobInvoiceType } from 'app/pages/JobLogsPage/types';

export const selectJobLogDetailsSlice = (state: RootState) =>
  state?.jobLogDetailsPage || initialState;

export const selectJobLogDetails = createSelector(
  [selectJobLogDetailsSlice],
  state => state.jobLogDetails,
);

export const selectJobLogDetailsLoading = createSelector(
  [selectJobLogDetailsSlice],
  state => state.loading,
);

export const selectJobLogDetailsError = createSelector(
  [selectJobLogDetailsSlice],
  state => state.error,
);

export const selectJobDetailsClientList = createSelector(
  [selectJobLogDetailsSlice],
  state =>
    getModifiedClientList(
      [state.jobLogDetails?.client],
      '_id',
      'name',
      'acronym',
    ),
);

export const selectJobTemplates = createSelector(
  [selectJobLogDetailsSlice],
  state =>
    getModifiedJobTemplates(state.jobLogDetails?.scope?.jobTemplate ?? []),
);

export const selectJobInvoices = createSelector(
  [selectJobLogDetailsSlice],
  state => state.jobLogDetails?.invoiceDetails ?? ({} as JobInvoiceType),
);

export const selectUploadTemplate = createSelector(
  [selectJobLogDetailsSlice],
  state => state.jobTemplate,
);

export const selectPageDetails = createSelector(
  [selectJobLogDetailsSlice],
  state => state.isHistoryPage,
);
