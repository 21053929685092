import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { fieldNames } from 'app/components/JobLogFinalReviewSection/constants';

import { JobFinalReviewType } from 'app/pages/JobLogsPage/types';
import { USER } from 'utils/constants/common';

export const getModifiedFormValues = (
  finalReviewDetails?: any,
  isHistoryPage?: boolean,
) => ({
  [fieldNames.reviewer]: isHistoryPage
    ? finalReviewDetails?.reviewer?.userId
    : finalReviewDetails?.reviewer,
  [fieldNames.internalConsultantReviewer]: isHistoryPage
    ? finalReviewDetails?.internalConsultantReviewer?.userId
    : finalReviewDetails?.internalConsultantReviewer,
  [fieldNames.ormsFinalReviewer]: isHistoryPage
    ? finalReviewDetails?.ormsFinalReviewer?.userId
    : finalReviewDetails?.ormsFinalReviewer,
  [fieldNames.completed]: finalReviewDetails?.finalReviewStatus,
});

export const getFinalReviewObject = (
  finalReviewApiData: JobFinalReviewType,
) => {
  Object.entries(finalReviewApiData).map(([key, values]) => {
    if (isEmpty(values)) {
      finalReviewApiData[`${key}`] = null;
    }
  });
  return finalReviewApiData;
};

export const sortedFinalReviewerList = ormsUserList => {
  const admin = ormsUserList.filter(user => user._id === USER.admin);
  const technical = ormsUserList.filter(user => user._id === USER.technical);

  const otherUser = ormsUserList.filter(
    user => ![USER.admin, USER.technical].includes(user._id),
  );

  return [...admin, ...technical, ...sortBy(otherUser, ['displayName'])];
};
