/**
 * COMMON CONSTANTS
 */

import { FIELD_LABELS } from '.';
import { ormsPages } from './routes';

export const STORAGE_KEYS = {
  emailForSignIn: 'emailForSignIn',
};

export const IMAGE_URLS = {
  omrsLogoUrl:
    'https://storage.googleapis.com/ormsweb/src/styles/images/logo.svg',
};

export const PATTERNS = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-z\-0-9]+\.)+[A-Za-z]{2,}))$/,
  password:
    /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,})./,
  acronym: /^[A-Z][A-Z]+$/,
  scopeAcronym: /^[A-Za-z][A-Za-z0-9\-]+$/,
  clientAcronym: /^[A-Za-z0-9]+$/,
  awfAcronym: /^[A-Za-z0-9]+$/,
  allowZeroNumber: /^[0-9][0-9]*$/,
  number: /^[1-9][0-9]*$/,
  siteNumber: /^[0-9][0-9,.]*$/,
  price: /^[0-9]*$/,
  zip: /^[0-9][0-9]*$/,
  address: /[a-zA-Z]/,
  string: /^[A-Za-z][A-Za-z\s]*$/,
  name: /^[A-Za-z][A-Za-z\s]*$/,
  companyName: /^[A-Za-z0-9][A-Za-z0-9&-,.' -]*$/,
  clientName: /^[A-Za-z0-9][A-Za-z0-9.\/\s]*$/,
  alphabets: /^[A-Za-z]+$/,
  clientJobName: /^[\w\s-]*/,
  propertyType: /^[a-zA-Z0-9][\w\s-]*/,
  alphaNumeric: /^[A-Za-z0-9 -]*[A-Za-z0-9][A-Za-z0-9 -]*$/,
  loanNumber: /^[A-Za-z0-9 -]*[A-Za-z0-9][A-Za-z0-9-.]*$/,
  alphaDashes: /^[A-Za-z -]*[A-Za-z][A-Za-z -]*$/,
};

export const DATE_FORMAT = {
  formatDate: 'MMM DD YYYY',
  yearOnlyFormat: 'YYYY',
  timeFormat: 'hh:mm a',
  dateTimeTZFormat: 'DD MMM YYYY HH:mm z',
};

export const booleanValue = {
  zero: FIELD_LABELS.zero,
  one: FIELD_LABELS.one,
};

export const booleanApiValue = {
  [booleanValue.zero]: false,
  [booleanValue.one]: true,
};

export const booleanDropdownList = [
  {
    key: 'yes',
    value: booleanValue.one,
    displayName: FIELD_LABELS.yes,
  },
  {
    key: 'no',
    value: booleanValue.zero,
    displayName: FIELD_LABELS.no,
  },
];

// const emptyTableTile = 'No result found';

export const tableEmptyMessages = {
  clientsPage: {
    title: FIELD_LABELS.noResultFound,
    description: FIELD_LABELS.clientNotFound,
  },
  jobLogsPage: {
    title: FIELD_LABELS.noResultFound,
    description: FIELD_LABELS.jobLogNotFound,
  },
  scopesPage: {
    title: FIELD_LABELS.noResultFound,
    description: FIELD_LABELS.scopeNotFound,
  },
  usersPage: {
    title: FIELD_LABELS.noResultFound,
    description: FIELD_LABELS.usersNotFound,
  },
  invoicesPage: {
    title: FIELD_LABELS.noResultFound,
    description: FIELD_LABELS.invoiceNotFound,
  },
  historyPage: {
    title: FIELD_LABELS.noResultFound,
    description: FIELD_LABELS.historyNotFound,
  },
  dataQuotePage: {
    title: FIELD_LABELS.noResultFound,
    description: FIELD_LABELS.jobLogNotFound,
  },
  jobStagePage: {
    title: FIELD_LABELS.noResultFound,
    description: FIELD_LABELS.jobLogNotFound,
  },
};

export const defaultPhoneCode = '+1';

export const currencyDetails = {
  dollar: Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }),
};

export const COMING_SOON = 'Coming soon...';

export const jobStageOptions = [
  {
    key: 'jobCreated',
    value: 'jobCreated',
    displayName: 'Job Created',
  },
  {
    key: 'dataQuote',
    value: 'dataQuote',
    displayName: 'Data & Quote',
  },
  {
    key: 'externalConsultantDeliverables',
    value: 'externalConsultantDeliverables',
    displayName: 'External Consultant Deliverables',
  },
  {
    key: 'internalReview',
    value: 'internalReview',
    displayName: 'Internal Review',
  },
  {
    key: 'internalConsultantReview',
    value: 'internalConsultantReview',
    displayName: 'Internal Consultant Review',
  },
  {
    key: 'contractorReview',
    value: 'contractorReview',
    displayName: 'Contractor Review',
  },
  {
    key: 'ormsFinal',
    value: 'ormsFinal',
    displayName: 'ORMS Final',
  },
  {
    key: 'completed',
    value: 'completed',
    displayName: 'Completed',
  },
];

export const defaultPageSize = 10;

export const pageRecords = {
  [ormsPages.jobLogs]: defaultPageSize,
  [ormsPages.dataQuote]: defaultPageSize,
  [ormsPages.invoices]: defaultPageSize,
  [ormsPages.history]: defaultPageSize,
  [ormsPages.clients]: defaultPageSize,
  [ormsPages.scopes]: defaultPageSize,
  [ormsPages.users]: defaultPageSize,
};

export const USER = {
  admin: '641983818efd48e684e37b91',
  technical: '6419837740b12940d99f5feb',
};

export const INVOICE_ACTION = {
  generate: 'generate',
  update: 'update',
};

export const IS_CHAT_OPEN_TRUE_PARAM = '?isChatOpen=true';
