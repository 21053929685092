/**
 * ClientJobDetailsSection
 */

import { memo } from 'react';
import { BUTTON_VARIANT_THEMES } from 'styles/assets';
import { useFormContext } from 'react-hook-form';

// Constants
import {
  currencyDetails,
  ERROR_MESSAGES,
  FIELD_LABELS,
  PLACEHOLDERS,
} from 'utils/constants';

// Child Components
import { FormField } from 'app/components';

// Services
import {
  clientJobNameValidation,
  propertyTypeValidation,
  siteValidation,
} from 'app/components/Form/services';

// Types
import { fieldNames } from 'app/pages/CreateEditJobLogModal/constants';
// Types
import { ClientJobDetailsSectionProps } from 'app/pages/CreateEditJobLogModal/types';

export const ClientJobDetailsSection = ({
  jobRecipientsList,
}: ClientJobDetailsSectionProps) => {
  const { getValues } = useFormContext();
  return (
    <div className="bg-white radius-8 p-20 pb-24 mb-12">
      <p className="f-14 f-w-700 text-primary mb-16">Job Details</p>

      {/* CLIENT JOB NAME & PROPERTY TYPE */}
      <div className="d-flex justify-between">
        <FormField.Input
          name={fieldNames.clientName}
          placeholder={PLACEHOLDERS.enterClientJobName}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mb-16 mr-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.clientJobName,
          }}
          rules={{
            validate: {
              clientJobName: clientJobNameValidation(
                ERROR_MESSAGES.clientJobNameInvalid,
              ),
            },
          }}
        />

        <div className="d-flex w-50 ml-8">
          <FormField.Input
            name={fieldNames.clientPropertyType}
            placeholder={PLACEHOLDERS.enterPropertyType}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16 mr-8',
            }}
            labelProps={{
              displayName: FIELD_LABELS.propertyType,
            }}
            rules={{
              validate: {
                propertyType: propertyTypeValidation(
                  ERROR_MESSAGES.propertyTypeInvalid,
                ),
              },
            }}
          />
        </div>
      </div>

      {/* SITE ACRES & SITE SQUARE FOOTAGE */}
      <div className="d-flex justify-between">
        <FormField.Input
          name={fieldNames.clientSiteAcres}
          placeholder={PLACEHOLDERS.enterSiteAcres}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mb-16 mr-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.siteAcres,
          }}
          rules={{
            validate: {
              siteAcres: siteValidation(ERROR_MESSAGES.siteAcresInvalid),
            },
          }}
        />

        <FormField.Input
          name={fieldNames.clientSiteSquareFootage}
          placeholder={PLACEHOLDERS.enterSiteSquareFootage}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mb-16 ml-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.siteSquareFootage,
          }}
          rules={{
            validate: {
              siteSquareFootage: siteValidation(
                ERROR_MESSAGES.siteSquareFootageInvalid,
              ),
            },
          }}
        />
      </div>

      {/* CONSTRUCTION YEAR & JOB RECIPIENTS */}
      <div className="d-flex justify-between">
        <FormField.DatePicker
          name={fieldNames.clientConstructionYear}
          placeholder={PLACEHOLDERS.selectConstructionDate}
          views={['year']}
          defaultValue={null}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mr-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.constructionYear,
          }}
        />

        {/* JOB RECIPIENTS */}
        <FormField.MultiSelect
          name={fieldNames.clientJobRecipients}
          aria-label="jobRecipientsSelect"
          placeholder={PLACEHOLDERS.selectJobRecipients}
          defaultValue={[]}
          options={jobRecipientsList}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mb-16 ml-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.jobRecipients,
            required: true,
          }}
          rules={{
            required: ERROR_MESSAGES.jobRecipientsRequired,
          }}
          disabled={!jobRecipientsList.length}
        />
      </div>
    </div>
  );
};

export default memo(ClientJobDetailsSection);
