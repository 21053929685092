export const showEntriesOptions = [
  {
    key: 'page10',
    displayName: '10 records per page',
    value: '10',
    isDisabled: false,
  },
  {
    key: 'page20',
    displayName: '20 records per page',
    value: '20',
    isDisabled: false,
  },
  {
    key: 'page50',
    displayName: '50 records per page',
    value: '50',
    isDisabled: false,
  },
  {
    key: 'page100',
    displayName: '100 records per page',
    value: '100',
    isDisabled: false,
  },
];
