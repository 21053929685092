/**
 * JobLogFinalReviewSection
 */

import { memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import {
  BUTTON_VARIANT_THEMES,
  FIELD_LABELS,
  PLACEHOLDERS,
} from 'utils/constants';

import { ButtonTooltip, Form, FormField } from 'app/components';

import {
  fieldNames,
  technicalOrAdminUserIds,
} from 'app/components/JobLogFinalReviewSection/constants';

// Types
import { JobFinalReviewType } from 'app/pages/JobLogsPage/types';
import { JobLogFinalReviewSectionProps } from 'app/components/JobLogFinalReviewSection/types';

// Services
import {
  getModifiedFormValues,
  getFinalReviewObject,
  sortedFinalReviewerList,
} from 'app/components/JobLogFinalReviewSection/services';
import { getInitialFromNames } from 'utils/services/common.service';

// Constants
import { completeOption } from 'app/components/JobLogFinalReviewSection/constants';
import { FinalReviewStage } from 'app/pages/JobLogDetailsPage/constants';
import { UserListDropdownType } from '../JobLogConsultantQuoteSection/types';

export const JobLogFinalReviewSection = ({
  finalReviewDetails,
  ormsUserList,
  intConsultantAwfUserList,
  awfContractorAndOrmsUserList,
  onFinalReviewSubmit,
  isHistoryPage,
  isPortfolioJobCompleted,
}: JobLogFinalReviewSectionProps) => {
  const [arrayAwfContractorAndOrmsUse, setArrayAwfContractorAndOrmsUse] =
    useState<UserListDropdownType[]>([]);
  const [arrayIntConsultantAwfUserList, setArrayIntConsultantAwfUserList] =
    useState<UserListDropdownType[]>([]);
  const [arrayOrmsUser, setArrayOrmsUser] = useState<UserListDropdownType[]>(
    [],
  );
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [fieldNames.reviewer]: '',
      [fieldNames.internalConsultantReviewer]: '',
      [fieldNames.ormsFinalReviewer]: '',
      [fieldNames.initial]: '',
      [fieldNames.completed]: '',
    },
  });

  const isFormDirty = form.formState.isDirty;

  const watchIsCompleted = form.watch(fieldNames.isCompleted) ?? false;
  const watchOrms = form.watch(fieldNames.ormsFinalReviewer);
  const watchFinalReviewStatus = form.watch(fieldNames.completed);

  // For auto fill the fields while the finalReviewDetails is already updated
  useEffect(() => {
    if (!isEmpty(finalReviewDetails)) {
      finalReviewDetails &&
        form.reset(getModifiedFormValues(finalReviewDetails, isHistoryPage), {
          keepDirty: false,
        });
    }
  }, [finalReviewDetails]);

  useEffect(() => {
    if (awfContractorAndOrmsUserList?.length) {
      setArrayAwfContractorAndOrmsUse(
        sortBy(awfContractorAndOrmsUserList, ['displayName']),
      );
    }
  }, [awfContractorAndOrmsUserList]);

  useEffect(() => {
    if (intConsultantAwfUserList?.length) {
      setArrayIntConsultantAwfUserList(
        sortBy(intConsultantAwfUserList, ['displayName']),
      );
    }
  }, [intConsultantAwfUserList]);

  useEffect(() => {
    if (ormsUserList?.length) {
      setArrayOrmsUser(sortedFinalReviewerList(ormsUserList));
    }
  }, [ormsUserList]);

  const onFormSubmit = useCallback(
    (finalReviewApiData: JobFinalReviewType) => {
      onFinalReviewSubmit(getFinalReviewObject(finalReviewApiData));
    },
    [watchIsCompleted],
  );

  const onCancel = useCallback(() => {
    if (!isEmpty(finalReviewDetails)) {
      finalReviewDetails &&
        form.reset(getModifiedFormValues(finalReviewDetails, isHistoryPage), {
          keepDirty: false,
        });
    } else {
      form.reset();
    }
  }, [finalReviewDetails]);

  return (
    <div className="bg-white mt-12 p-24 radius-8">
      <Form formName="jobFinalReviewForm" form={form} onSubmit={onFormSubmit}>
        <div className="d-flex align-center justify-between mb-16">
          <h5 className="f-14 l-h-22 f-w-700 text-black-8">
            Draft & Final Review Details
          </h5>

          {/* FORM CANCEL & SAVE */}
          {isFormDirty && (
            <div className="d-flex">
              <ButtonTooltip.Cancel buttonProps={{ onClick: onCancel }} />
              <ButtonTooltip.Save />
            </div>
          )}
        </div>

        <div className="d-flex align-center justify-between mb-16">
          {/* Reviewer */}
          <FormField.Select
            name={fieldNames.reviewer}
            handleClear={() => {
              form.setValue(fieldNames.reviewer, '', { shouldDirty: true });
            }}
            aria-label="reviewerSelect"
            placeholder={PLACEHOLDERS.selectContractor}
            defaultValue=""
            options={arrayAwfContractorAndOrmsUse ?? []}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16 mr-20',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.reviewer,
            }}
            disabled={
              watchIsCompleted || isHistoryPage || isPortfolioJobCompleted
            }
          />

          {/* Internal Consultant Reviewer */}
          <FormField.Select
            name={fieldNames.internalConsultantReviewer}
            handleClear={() => {
              form.setValue(fieldNames.internalConsultantReviewer, '', {
                shouldDirty: true,
              });
            }}
            aria-label="internalConsultantReviewerSelect"
            placeholder={PLACEHOLDERS.selectInternalConsultant}
            defaultValue=""
            options={arrayIntConsultantAwfUserList ?? []}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.internalConsultantReviewer,
            }}
            disabled={
              watchIsCompleted || isHistoryPage || isPortfolioJobCompleted
            }
          />
        </div>

        <div className="d-flex align-center justify-between mb-16">
          {/* ORMS Final Review */}
          <FormField.Select
            name={fieldNames.ormsFinalReviewer}
            handleClear={() => {
              form.setValue(fieldNames.ormsFinalReviewer, '', {
                shouldDirty: true,
              });
              if (watchFinalReviewStatus === completeOption[0].value)
                form.setValue(fieldNames.completed, '');
            }}
            aria-label="ormsFinalReviewerSelect"
            placeholder={PLACEHOLDERS.selectORMS}
            defaultValue=""
            options={arrayOrmsUser ?? []}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'mb-16 mr-20',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.oRMSFinalReview,
              required: true,
            }}
            disabled={
              watchIsCompleted || isHistoryPage || isPortfolioJobCompleted
            }
          />

          <div className="w-100">
            <label className="form-label pb-6">
              Final Disposition (Pre Invoice Generation)
            </label>
            <div className="d-flex">
              <FormField.Select
                name={fieldNames.completed}
                handleClear={() => {
                  form.setValue(fieldNames.completed, '', {
                    shouldDirty: true,
                  });
                }}
                aria-label="yesCompleted"
                placeholder={'Completed?'}
                defaultValue=""
                options={completeOption.map(option => {
                  if (
                    (isEmpty(watchOrms) ||
                      technicalOrAdminUserIds.includes(watchOrms)) &&
                    option.key === FinalReviewStage.yesCompleted
                  ) {
                    return { ...option, isDisabled: true };
                  } else {
                    return option;
                  }
                })}
                formControlProps={{
                  variant: BUTTON_VARIANT_THEMES.outlined,
                  className: 'mb-16 mr-20',
                }}
                disabled={isHistoryPage || isPortfolioJobCompleted}
              />

              <div className="w-80 ml-8">
                <FormField.Input
                  name={fieldNames.initial}
                  value={
                    isEmpty(finalReviewDetails?.statusUpdatedBy)
                      ? ''
                      : getInitialFromNames(finalReviewDetails?.statusUpdatedBy)
                  }
                  placeholder={PLACEHOLDERS.initial}
                  formControlProps={{
                    variant: 'outlined',
                    className: '',
                  }}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default memo(JobLogFinalReviewSection);
