import { Validate } from 'react-hook-form';
import { isValidPhoneNumber, CountryCallingCode } from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';

// Constants
import { PATTERNS, siteStates } from 'utils/constants';

// Types
import { ClientPocType } from 'app/pages/ClientsPage/types';

type validateStringType = {
  value: string;
  message: string;
};

export const validateString = ({ value, message }: validateStringType) => {
  if (value.trim().length === 0) {
    return message;
  }
};

export const emailValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    const isValid = PATTERNS.email.test(value);
    if (!isValid) {
      return message;
    }
  };

interface lengthValidationType {
  emptyStringErrorMessage: string;
  minErrorMessage: string;
  maxErrorMessage: string;
  minLimit?: number;
  maxLimit?: number;
}

// Empty string validation
export const lengthValidation =
  ({
    emptyStringErrorMessage,
    minErrorMessage,
    maxErrorMessage,
    minLimit = 1,
    maxLimit = 100,
  }: lengthValidationType): Validate<string, object> =>
  (value: string) => {
    if (value?.length > 0) {
      validateString({ value, message: emptyStringErrorMessage });

      if (value.trim().length < minLimit) {
        return minErrorMessage;
      }
      if (value?.trim().length > maxLimit) {
        return maxErrorMessage;
      }
    }
  };

// Password validation
export const passwordValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    const isValid = PATTERNS.password.test(value);
    if (!isValid) {
      return message;
    }
  };

// Confirm password validation
export const confirmPasswordValidation =
  (newPass: string, message: string): Validate<string, object> =>
  (value: string) => {
    if (newPass !== value) {
      return message;
    }
  };

// otp validation
export const otpValidation =
  (message: string): Validate<number, object> =>
  (value: number) => {
    if (String(value)?.length > 6 || isNaN(value)) {
      return message;
    }
  };

// Number validation
export const numberValidation =
  (message: string, allowZero: Boolean = true): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = allowZero
        ? PATTERNS.allowZeroNumber.test(value)
        : PATTERNS.number.test(value);
      if (!isValid) {
        return message;
      }
    }
  };

// Site validation
export const siteValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.siteNumber.test(value);
      if (!isValid) {
        return message;
      }
    }
  };

// Zip validation
export const zipValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.zip.test(value);
      if (!isValid) {
        return message;
      }
    }
  };

// Acronym validation
export const acronymValidation =
  (
    invalidErrormessage: string,
    lengthErrorMessage: string,
  ): Validate<string, object> =>
  (value: string) => {
    const isValid = PATTERNS.clientAcronym.test(value);
    if (value?.length < 2) {
      return lengthErrorMessage;
    }
    if (!isValid) {
      return invalidErrormessage;
    }
  };

// Acronym validation
export const acronymScopeValidation =
  (
    invalidErrormessage: string,
    lengthErrorMessage: string,
  ): Validate<string, object> =>
  (value: string) => {
    const isValid = PATTERNS.scopeAcronym.test(value);
    if (value?.length < 2) {
      return lengthErrorMessage;
    }
    if (!isValid) {
      return invalidErrormessage;
    }
  };

// Name Validation
export const nameValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.name.test(value);
      if (!isValid) {
        return message;
      }
    }
  };

export const clientNameValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.companyName.test(value);
      if (!isValid) {
        return message;
      }
    }
  };

// Name Validation
export const clientJobNameValidation =
  (invalidErrormessage: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.clientJobName.test(value);
      if (!isValid) {
        return invalidErrormessage;
      }
    }
  };

// Property Type
export const propertyTypeValidation =
  (invalidErrormessage: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.propertyType.test(value);
      if (!isValid) {
        return invalidErrormessage;
      }
    }
  };

// Initials Validation
export const initialsValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    const isValid = PATTERNS.alphabets.test(value);
    if (!isValid) {
      return message;
    }
  };

// PhoneNumber Validation
export const phoneNumberValidation = (
  message: string,
  callingCode: CountryCallingCode,
  isAcceptCharacter = false,
): Validate<string, object> => {
  return (value: string) => {
    if (isAcceptCharacter) {
      if (value?.length && value?.length > 20) return message;
    } else {
      const isValid = isValidPhoneNumber(callingCode + value);
      if (!isValid && value?.length) {
        return message;
      }
    }
  };
};

// Address Validation
export const addressValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    const isValid = PATTERNS.address.test(value);
    if (!isValid) {
      return message;
    }
  };

// Only string with space
export const stringValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    const isValid = PATTERNS.string.test(value);
    if (!isValid) {
      return message;
    }
  };

// Unique email validation
export const uniqueEmailValidation =
  (newPoc: ClientPocType[], message: string): Validate<string, object> =>
  (value: string) => {
    const sameEmail = newPoc.filter(val => val.email === value);
    if (sameEmail.length > 1) {
      return message;
    }
  };

// Alpha Numeric validation
export const alphaNumericValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.alphaNumeric.test(value);
      if (!isValid) {
        return message;
      }
    }
  };

// Alpha with Dashes validation
export const alphaDashesValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.alphaDashes.test(value);
      if (!isValid) {
        return message;
      }
    }
  };

// site state validation
export const siteStateValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = siteStates.includes(value);
      if (!isValid) {
        return message;
      }
    }
  };

// Price validation
export const priceValidation =
  (
    invalidErrormessage: string,
    lengthErrorMessage?: string,
  ): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.price.test(value);
      if (!isValid) {
        return invalidErrormessage;
      }
      if (Number(value) > 99999) {
        return lengthErrorMessage;
      }
    }
  };

// Acronym validation
export const AwfAbbValidation =
  (
    invalidErrormessage: string,
    lengthErrorMessage: string,
  ): Validate<string, object> =>
  (value: string) => {
    const isValid = PATTERNS.awfAcronym.test(value);
    if (value?.length < 2) {
      return lengthErrorMessage;
    }
    if (!isValid) {
      return invalidErrormessage;
    }
  };

//  Company Validation
export const companyValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.companyName.test(value);
      if (!isValid) {
        return message;
      }
    }
  };

//  loanNumber Validation
export const loanNumberValidation =
  (message: string): Validate<string, object> =>
  (value: string) => {
    if (!isEmpty(value)) {
      const isValid = PATTERNS.loanNumber.test(value);
      if (!isValid) {
        return message;
      }
    }
  };
