import { API_ROUTES, getCallBlob } from 'utils/api';

import { getModifiedApiError } from 'utils/services/common.service';

export const policiesApi = async payload => {
  try {
    const policies = `${API_ROUTES.policies}/${payload.type}`;

    const { data } = await getCallBlob(policies);

    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};
