import { ReactNode } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import {
  currencyDetails,
  DATE_FORMAT,
  FIELD_LABELS,
  TABLE_DATA_TYPES,
} from 'utils/constants';

import {
  getFormattedDate,
  getInitialFromNames,
  getName,
} from 'utils/services/common.service';
import {
  getEdit,
  redirectId,
  getPhoneWithCode,
  getPocValue,
} from 'app/components/Table/services';

import { TableColumnType } from 'app/components/Table/types';

const AwfUserType = {
  awfExternalConsultant: 'AWF External Consultant',
  awfInternalConsultant: 'AWF Internal Consultant',
  awfContractor: 'AWF Contractor',
};

const getInvoiceDescription = data => {
  return `${dayjs(data.createdAt).format('YY')}-${
    data?.client?.acronym ?? ''
  }.${data?.scope?.acronym ?? ''}.${data?.job?.jobId ?? ''}`;
};

export const getTableValueByKey = (
  col: TableColumnType,
  data: any,
): ReactNode => {
  const value = get(data, col.path, '');

  switch (col.type) {
    case TABLE_DATA_TYPES.edit:
      return getEdit(col, value);

    case TABLE_DATA_TYPES.date:
      return value ? getFormattedDate(value) : FIELD_LABELS.notApplicable;

    case TABLE_DATA_TYPES.invoiceDate:
      return value
        ? getFormattedDate(value, 'MM/DD/YYYY')
        : FIELD_LABELS.notApplicable;

    case TABLE_DATA_TYPES.year:
      return value
        ? getFormattedDate(value, DATE_FORMAT.yearOnlyFormat)
        : FIELD_LABELS.notApplicable;

    case TABLE_DATA_TYPES.price:
      const price = currencyDetails.dollar.format(value);
      return price.slice(0, -3);

    case TABLE_DATA_TYPES.boolean:
      return value ? FIELD_LABELS.YES : FIELD_LABELS.NO;

    case TABLE_DATA_TYPES.name:
      return getName(value, get(data, col.subPath!, ''));

    case TABLE_DATA_TYPES.poc:
      return getPocValue(col, data.poc);

    case TABLE_DATA_TYPES.initial:
      return getInitialFromNames(value);

    case TABLE_DATA_TYPES.phone:
      return getPhoneWithCode(value, get(data, col.subPath!, ''));

    case TABLE_DATA_TYPES.scopeDueDate:
      return `${value} ${value === 1 ? 'day' : 'days'}`;

    case TABLE_DATA_TYPES.clientName:
      return `${value} (${get(data, col.subPath!, '')})`;

    case TABLE_DATA_TYPES.combineName:
      return `${value} ${get(data, col.subPath!, '')}`;

    case TABLE_DATA_TYPES.number:
      return value ?? FIELD_LABELS.notApplicable;

    case TABLE_DATA_TYPES.invoiceNumber:
      return `#${value}`;

    case TABLE_DATA_TYPES.returnExact:
      return value;

    case TABLE_DATA_TYPES.redirect:
      return redirectId(col, value, data);

    case TABLE_DATA_TYPES.awfUser:
      return AwfUserType[value];

    case TABLE_DATA_TYPES.invoiceAmount:
      const invoiceAmount =
        parseFloat(value) + parseFloat(get(data, col.subPath!, ''));

      const amount = currencyDetails.dollar.format(invoiceAmount);
      return amount.slice(0, -3);

    case TABLE_DATA_TYPES.invoiceDescription:
      return getInvoiceDescription(data);

    default:
      return !isEmpty(value) ? value : FIELD_LABELS.notApplicable;
  }
};
