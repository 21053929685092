import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'store/socket';
import { RootState } from 'types';

export const selectSocketConfig = (state: RootState) =>
  state?.socket || initialState;

export const socketConfig = createSelector(
  [selectSocketConfig],
  subState => subState.socket,
);
