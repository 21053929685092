import { call, put, takeLatest, all } from 'redux-saga/effects';

import { actions } from 'store/auth';
import { validateUserByEmailApi, whoisApi } from 'store/auth/api';

// Types
import { CheckUserExistsActionProps } from 'store/auth/types';

export function* validateUserByEmail({ payload }: CheckUserExistsActionProps) {
  try {
    const { isUserExist } = yield call(validateUserByEmailApi, payload);
    yield put(actions.validateUserByEmailSuccessAction(isUserExist));
  } catch (err: any) {
    yield put(actions.validateUserByEmailErrorAction(err?.message));
  }
}

export function* watchValidateUserByEmail() {
  yield takeLatest(actions.validateUserByEmailAction.type, validateUserByEmail);
}

export function* whois() {
  try {
    const { data } = yield call(whoisApi);
    yield put(actions.whoisSuccessAction(data));
  } catch (err: any) {
    yield put(actions.whoisErrorAction(err?.message));
  }
}

export function* watchWhois() {
  yield takeLatest(actions.whoisAction.type, whois);
}

export default function* authSliceSaga() {
  yield all([watchValidateUserByEmail(), watchWhois()]);
}
