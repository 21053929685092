// Redux, saga imports
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './index';

// API fn imports
import { getJobFolderDetailsApi, getSingedUrlJobFile } from './api';

// Types and interfaces imports
import { GetJobFolderDetailsProps, GetPublicUrlProps } from './types';

// Snackbar
import { actions as snackbarActions } from 'store/snackbar';
import { formatSnackBarSuccess } from 'utils/services/common.service';

// Constants
import { SUCCESS_MESSAGES } from 'utils/constants';

export function* getJobFolderDetails({ payload }: GetJobFolderDetailsProps) {
  try {
    const { data } = yield call(getJobFolderDetailsApi, payload);
    yield put(actions.getJobFolderSuccessAction(data));
  } catch (error: any) {
    yield put(actions.getJobFolderDetailsErrorAction(error?.message));
  }
}

export function* watchGetJobFolderDetails() {
  yield takeLatest(
    actions.getJobFolderDetailsActions.type,
    getJobFolderDetails,
  );
}

export function* getSingedUrl({ payload }: GetPublicUrlProps) {
  try {
    const { data } = yield call(getSingedUrlJobFile, payload);
    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(SUCCESS_MESSAGES.singedUrlCreatedSuccessfully),
      ),
    );
    yield put(actions.getJobFolderSuccessAction(data));
  } catch (error: any) {
    yield put(actions.getJobFolderDetailsErrorAction(error?.message));
  }
}

export function* watchGetSingedUrl() {
  yield takeLatest(actions.getSingedUrlAction.type, getSingedUrl);
}

export default function* jobFolderDetailsModalSaga() {
  yield all([watchGetJobFolderDetails(), watchGetSingedUrl()]);
}
