import { API_ROUTES, putCall } from 'utils/api';

import { getModifiedApiError } from 'utils/services/common.service';

export const jobLogAssociatesApi = async payload => {
  try {
    const { data } = await putCall(
      `${API_ROUTES.job}/${payload?.jobId}/associates`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};
