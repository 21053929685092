/**
 * JobLogFolderSection
 */

import { memo, useEffect, useState } from 'react';

// MUI components
import FolderIcon from '@mui/icons-material/Folder';

// Services
import { getFormattedDate } from 'utils/services/common.service';

// Types and CONSTANTS
import {
  FolderData,
  JobFolderType,
  UploadKeys,
} from 'app/components/JobLogFolderSection/types';
import { WhoisDetailsType } from 'store/auth/types';

import mockData from 'app/components/JobLogFolderSection/mockFoldersData.json';
import JobFolderModal from './JobFolderModal';
import { useSelector } from 'react-redux';
import { selectJobFolderDetails } from './slice/selectors';
import { find } from 'lodash';
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';
import { selectPageDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';

export interface JobLogFolderSectionType {
  currentUser: WhoisDetailsType;
  jobId: Number;
}
export const JobLogFolderSection = ({
  currentUser,
  jobId,
}: JobLogFolderSectionType) => {
  const jobFolders = mockData.data as JobFolderType[];
  const [folderOpen, setFolderOpen] = useState(false);
  const [folderData, setFolderData] = useState<FolderData>({
    folderName: '',
    folderKey: UploadKeys.shared,
    files: [],
  });
  const [currentFolder, setCurrentFolder] = useState<UploadKeys>(
    UploadKeys.shared,
  );

  const folders = useSelector(selectJobFolderDetails);
  const jobLogDetails = useSelector(selectJobLogDetails);
  const isHistoryPage = useSelector(selectPageDetails);

  useEffect(() => {
    const folder = find(folders, folder => folder._id === currentFolder);

    if (folder) {
      const folderData: FolderData = {
        folderName: folder.folderName,
        folderKey: folder._id,
        files: folder?.files,
      };

      setFolderData(folderData);
    }
  }, [folders]);

  const openFolder = (folder: JobFolderType) => {
    const folderData: FolderData = {
      folderName: folder.folderName,
      folderKey: folder._id,
      files: folder.files,
    };
    setCurrentFolder(folder._id);
    setFolderData(folderData);
    setFolderOpen(true);
  };

  const closeFolder = () => {
    setFolderOpen(false);
  };
  return (
    <>
      <div className="bg-white radius-8 border-darken">
        <h5 className="f-14 l-h-22 f-w-700 text-black-8 p-16">{`Folder View: ${jobId}`}</h5>

        <div className="d-flex align-center justify-between px-16 py-12 bg-note">
          <p className="f-12 f-w-600 l-h-14 text-black-3">NAME</p>
          <p className="f-12 f-w-600 l-h-14 text-black-3">DATE</p>
        </div>

        {folders.map((folder: JobFolderType) => (
          <div
            className="d-flex align-center justify-between p-12 border-bottom-darken cursor-pointer"
            key={folder._id}
            onClick={() => openFolder(folder)}
          >
            <div className="d-flex align-center">
              <FolderIcon className="mr-8 text-black-3" />
              <p className="f-16 f-w-600 l-h-22 text-black-7">
                {folder.folderName}
              </p>
            </div>
            <p className="f-16 f-w-400 l-h-22 text-black-5">
              {getFormattedDate(
                folder?.files[folder?.files?.length - 1]?.updatedAt ??
                  jobLogDetails.createdAt,
              )}
            </p>
          </div>
        ))}
        <JobFolderModal
          handleClose={closeFolder}
          open={folderOpen}
          folderData={folderData}
          isHistoryPage={isHistoryPage}
        />
      </div>
    </>
  );
};

export default memo(JobLogFolderSection);
