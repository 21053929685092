/**
 * JobDueDateAdminModal
 */

import { memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

// Material
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { FormField, Button } from 'app/components';

import {
  BUTTON_COLORS,
  BUTTON_VARIANT_THEMES,
  FIELD_LABELS,
} from 'utils/constants';

import { fieldNames } from 'app/components/JobLogQuoteDeliverySection/constants';

export const JobDueDateAdminModal = ({ handleClose, onUpdateJobDueDate }) => {
  const { watch, setValue } = useFormContext();

  const watchJobDueDate = watch(fieldNames.dueDate);

  useEffect(() => {
    return () => {
      setValue(fieldNames.jobDueDateInModal, watchJobDueDate);
    };
  }, []);

  const onSaveClick = useCallback(() => {
    const watchJobDueDateInModal = watch(fieldNames.jobDueDateInModal);
    onUpdateJobDueDate(watchJobDueDateInModal);
  }, []);

  return (
    <Dialog open={true} onClose={handleClose} className="small-modal">
      <DialogTitle id="alert-dialog-title">
        <span className="f-16 l-h-22 f-w-700 text-black-8">
          Update Job Due Date
        </span>
      </DialogTitle>

      <DialogContent>
        <FormField.DatePicker
          name={fieldNames.jobDueDateInModal}
          defaultValue={watchJobDueDate}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
          }}
          labelProps={{
            className: 'form-label',
            displayName: FIELD_LABELS.jobDueDate,
            required: true,
          }}
          minDate={new Date()}
        />
      </DialogContent>

      <DialogActions>
        <Button
          aria-label="cancelBtn"
          color={BUTTON_COLORS.inherit}
          variant={BUTTON_VARIANT_THEMES.outlined}
          name={FIELD_LABELS.cancelBtn}
          onClick={handleClose}
        />

        <Button
          aria-label="saveBtn"
          color={BUTTON_COLORS.primary}
          variant={BUTTON_VARIANT_THEMES.contained}
          name={FIELD_LABELS.saveBtn}
          onClick={onSaveClick}
        />
      </DialogActions>
    </Dialog>
  );
};

export default memo(JobDueDateAdminModal);
