/**
 * TableListEmpty
 */

import { memo } from 'react';

import { TableListEmptyProps } from 'app/components/TableListEmpty/types';

export const TableListEmpty = ({
  className,
  list,
  title,
  description,
  children,
}: TableListEmptyProps) => {
  if (list?.length !== 0) {
    return <>{children}</>;
  }

  return (
    <div aria-label="table-empty-message">
      {children}
      <div className={`grid place-center ${className}`}>
        <div className="flex flex-column align-center justify-center text-center">
          <h3 className="f-20 f-w-bold text-primary mw-520">{title}</h3>

          {description && (
            <p className="f-14 f-w-500 l-h-24 text-tertiary mt-4 mb-16 mw-400">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TableListEmpty);
