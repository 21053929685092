/**
 * Common Service
 */

import dayjs from 'dayjs';
import dayjsBusinessTime from 'dayjs-business-time';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import { AlertColor } from '@mui/material';

import { TableQueryParams } from 'app/hooks/useTableFilters';

import { DATE_FORMAT, STORAGE_KEYS } from 'utils/constants';

import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
// Types
import { SelectOptionType } from 'app/components/Select/types';
import { PayloadType } from 'utils/types/common.types';
import { WhoisDetailsType } from 'store/auth/types';

// Services
import { pageRecords } from 'utils/constants/common';

export const formatStartCase = (value: string) => startCase(value);

export function arrayToQueryString(key, arr) {
  return arr.reduce((queryString, value) => {
    if (queryString.length > 0) {
      queryString += '&';
    }
    queryString += `${key}[]=${value}`;
    return queryString;
  }, '');
}

export const getModifiedApiUrl = (
  url: string,
  {
    page,
    pageSize,
    isAsc = false,
    searchKey,
    portfolio,
    view,
    finalReview,
    foreClosure,
    jobStage,
    sba,
    scopeCategory,
    basicDetails,
    asc,
    sortBy,
    categoryId,
    group,
    isSuperAdmin,
    isOrdered,
    isQuotes,
    orderFromDate,
    orderToDate,
    quotesFromDate,
    quotesToDate,
    receivedFromDate,
    receivedToDate,
    requestFromDate,
    requestToDate,
    isDisabled,
    isOrmsFinalReviewerDropdown,
    start,
    end,
    assignToMe,
    scope,
    dueFromDate,
    dueToDate,
    portfolioId,
  }: PayloadType,
) => {
  const payload = {
    page,
    pageSize,
    isAsc,
    searchKey,
    view,
    finalReview,
    foreClosure,
    jobStage,
    portfolio,
    sba,
    scopeCategory,
    basicDetails,
    asc,
    sortBy,
    categoryId,
    group,
    isSuperAdmin,
    isOrdered,
    isQuotes,
    orderFromDate,
    orderToDate,
    quotesFromDate,
    quotesToDate,
    receivedFromDate,
    receivedToDate,
    requestFromDate,
    requestToDate,
    isDisabled,
    isOrmsFinalReviewerDropdown,
    start,
    end,
    assignToMe,
    scope,
    dueFromDate,
    dueToDate,
    portfolioId,
  };
  let queryParams = '';
  let queryConnect = '?';
  Object.keys(payload).forEach(key => {
    if (payload[key] || key === 'isAsc' || typeof payload[key] === 'boolean') {
      if (key === 'scopeCategory' || key === 'scope' || key === 'portfolioId') {
        queryParams = queryParams + `&${arrayToQueryString(key, payload[key])}`;
      } else if (key === 'searchKey') {
        queryParams =
          queryParams +
          `${queryConnect}${key}=${encodeURIComponent(payload[key]!)}`;
      } else {
        queryParams = queryParams + `${queryConnect}${key}=${payload[key]}`;
      }
      queryConnect = '&';
    }
  });
  return `${url}${queryParams}`;
};

export const modifyDropdown = (
  list: any[],
  keyPath = '_id',
  displayNamePath: string,
  displaySubNamePath?: string,
  disabledPath?: string,
) => {
  if (!list?.length) {
    return [];
  }

  return list?.map(item => ({
    ...item,
    key: get(item, keyPath, ''),
    value: String(get(item, keyPath, '')),
    isDisabled: item[`${disabledPath}`] ?? false,
    displayName: displaySubNamePath
      ? get(item, displayNamePath, '') + ' ' + get(item, displaySubNamePath, '')
      : get(item, displayNamePath, ''),
  }));
};

export const getQueryParams = ({ page, pageSize, searchKey }) => {
  let modifiedParams = {
    page: page.toString(),
    pageSize: pageSize.toString(),
  } as Partial<TableQueryParams>;

  if (searchKey) {
    modifiedParams = { ...modifiedParams, searchKey: searchKey };
  }

  return modifiedParams;
};

export const getYearFromDate = (date: string, digitToShow = 2) => {
  const year = new Date(date).getFullYear();

  if (digitToShow === 2) {
    return String(year).slice(-2);
  }
  return year;
};

export const getModifiedApiError = (err: any) => {
  const error = err?.data?.error ?? '';
  const message = err?.data?.message ?? '';

  if (error && message) {
    return `${error} - ${message}`;
  } else if (message) {
    return message;
  } else if (error) {
    return error;
  }
};

export const getDayjsDate = (date: string | null | undefined) =>
  date ? dayjs(date) : null;

export const getFormattedDate = (
  date?: string,
  formatBy = DATE_FORMAT.formatDate,
) => (date ? dayjs(date).format(formatBy) : '');

export const formatSnackBarError = (
  message: string,
): { message: string; theme: AlertColor } => ({ message, theme: 'error' });

export const formatSnackBarSuccess = (
  message: string,
): { message: string; theme: AlertColor } => ({ message, theme: 'success' });

export const getDueDateOptions = () => {
  const dueDateOption: SelectOptionType[] = [];
  for (let i = 2; i <= 30; i++) {
    dueDateOption.push({
      key: `${i}`,
      value: i,
      displayName: `${i} days`,
    });
  }
  return dueDateOption;
};

export const getQuoteDays = (
  jobDueDateValue: string | null,
  updatedDate?: string,
) => {
  if (isEmpty(jobDueDateValue) || isEmpty(updatedDate)) {
    return 0;
  }

  dayjs.extend(dayjsBusinessTime);

  return !isEmpty(jobDueDateValue)
    ? dayjs(updatedDate).businessDaysDiff(dayjs(jobDueDateValue))
    : 0;
};

export const getInitialFromNames = (whoisDetails?: WhoisDetailsType | null) => {
  if (whoisDetails?.initial) {
    return whoisDetails?.initial;
  } else {
    return `${whoisDetails?.firstName ? whoisDetails?.firstName[0] : '-'}${
      whoisDetails?.lastName ? whoisDetails?.lastName[0] : ''
    }`;
  }
};

export const getName = (
  firstName: string | undefined,
  lastName: string | undefined,
) => `${firstName} ${lastName}`;

export const getFormattedName = (firstName?: string, lastName?: string) =>
  startCase(getName(firstName, lastName));

export const getFullName = (whoisDetails?: WhoisDetailsType) =>
  isEmpty(whoisDetails)
    ? null
    : getName(whoisDetails?.firstName, whoisDetails?.lastName);

export const setLocalStorageByKey = (
  value: string,
  key = STORAGE_KEYS.emailForSignIn,
) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageByKey = (key = STORAGE_KEYS.emailForSignIn) => {
  return localStorage.getItem(key);
};

export const getModifiedApiData = apiData => {
  for (const key in apiData) {
    if (apiData[key] === '' || apiData[key] === undefined) {
      delete apiData[key];
    } else if (typeof apiData[key] === 'object') {
      getModifiedApiData(apiData[key]);
    }
  }
  return apiData;
};

export const downloadBlobFile = async (
  data,
  fileName,
  file = 'pdf',
): Promise<void> => {
  const blob = new Blob([data], {
    type: file === 'CSV' ? 'text/csv;charset=utf-8;' : 'application/pdf',
  });
  const url = window.URL.createObjectURL(blob);
  const fileLink = document.createElement('a');
  fileLink.href = url;
  fileLink.setAttribute('download', fileName);
  fileLink.click();
  fileLink.remove();
};

export const openBlobFile = async (
  data: any,
  fileName: string,
): Promise<void> => {
  let fileType = 'application/pdf'; // Default to PDF MIME type
  if (
    fileName.toLowerCase().endsWith('.jpg') ||
    fileName.toLowerCase().endsWith('.jpeg') ||
    fileName.toLowerCase().endsWith('.png')
  ) {
    fileType = 'image/jpeg'; // Change MIME type for JPEG files
  }

  const file = new Blob([data], {
    type: fileType,
  });

  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export const fetchCurrentPage = () => {
  return `/${location.pathname.split('/')[2]}`;
};

export const getPrevPageSize = () => {
  let pageRecordSizeDetails;
  if (localStorage.getItem('pageRecords'))
    pageRecordSizeDetails = JSON.parse(localStorage.getItem('pageRecords')!);
  else pageRecordSizeDetails = pageRecords;

  const pageSize = parseInt(pageRecordSizeDetails[fetchCurrentPage()]);
  return isNaN(pageSize) ? 10 : pageSize;
};

export const humanizeDate = (date: Date) => {
  const atDate = dayjs(date);
  const currentDate = dayjs();

  const hourDiff = currentDate.diff(atDate, 'hour');

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  if (hourDiff < 23) {
    const min = currentDate.diff(atDate, 'minute');
    return dayjs.duration(-min, 'minutes').humanize(true);
  } else {
    return getFormattedDate(date.toLocaleString(), 'MMM D, YYYY HH:mm');
  }
};

export const findRepeatedStringElement = arr => {
  let repeatedElement = '';
  const map = new Map();

  for (let i = 0; i < arr.length; i++) {
    if (map.has(arr[i])) {
      repeatedElement = arr[i];
      break;
    } else {
      map.set(arr[i], true);
    }
  }

  return repeatedElement;
};

export const sortObjByKey = obj => {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
};

export const getPlaceHolderJob = item => {
  let name = { firstName: '', lastName: '' };

  switch (true) {
    case !!item?.finalReview?.ormsFinalReviewer?._id:
      name = item.finalReview.ormsFinalReviewer;
      break;

    case !!item?.finalReview?.reviewer?._id:
      name = item.finalReview.reviewer;
      break;

    case !!item?.finalReview?.internalConsultantReviewer?._id:
      name = item.finalReview.internalConsultantReviewer;
      break;

    case !!item?.consultantQuoteDetails?.consultants?._id:
      name = item.consultantQuoteDetails.consultants;
      break;

    case !!item?.consultantQuoteDetails?.assignTo?._id:
      name = item.consultantQuoteDetails.assignTo;
      break;

    default:
      name.firstName =
        item?.stageUpdatedBy?.firstName ?? item?.client?.poc?.firstName;
      name.lastName =
        item?.stageUpdatedBy?.lastName ?? item?.client?.poc?.lastName;
      break;
  }

  return getFormattedName(name.firstName, name.lastName);
};
