/**
 * JobDetailsSection
 */

import { memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { BUTTON_VARIANT_THEMES } from 'styles/assets';
import {
  booleanDropdownList,
  ERROR_MESSAGES,
  FIELD_LABELS,
  PLACEHOLDERS,
} from 'utils/constants';

// Child Components
import { FormField, DropdownPopupButton } from 'app/components';

// Services
import {
  loanNumberValidation,
  numberValidation,
} from 'app/components/Form/services';
import { getNoOptionText } from 'app/pages/CreateEditJobLogModal/services';

// Constants
import { fieldNames } from 'app/pages/CreateEditJobLogModal/constants';

// Types
import { JobDetailsSectionProps } from 'app/pages/CreateEditJobLogModal/types';
import { sortBy } from 'lodash';

export const JobDetailsSection = ({
  clientList,
  scopeCategoryList,
  scopeList,
  whoisDetails,
  scopeLoading,
  isEditJob,
  isClientListLoading,
  autoCompleteDefaultValue,
}: JobDetailsSectionProps) => {
  const { setError, clearErrors, watch, setValue } = useFormContext();

  const scopeCategory = watch(fieldNames.scopeCategory);

  useEffect(() => {
    if (!isEmpty(whoisDetails)) {
      setValue(
        fieldNames.createdBy,
        `${whoisDetails.firstName} ${whoisDetails.lastName}`,
      );
    }
  }, [whoisDetails]);

  useEffect(() => {
    if (!isEmpty(scopeCategory) && !scopeList.length && !scopeLoading) {
      setError(fieldNames.scopeId, { message: ERROR_MESSAGES.scopeNotFound });
    } else {
      clearErrors(fieldNames.scopeId);
    }
  }, [scopeList, scopeCategory, scopeLoading]);

  const onChangeClientId = useCallback((clientId: string) => {
    setValue(fieldNames.clientId, clientId);
  }, []);

  return (
    <div className="bg-white radius-8 p-20 pb-24 mb-12">
      <p className="f-14 f-w-700 text-primary mb-16">Job ID Details</p>

      {/* JOB ID & CREATED BY */}
      <div className="d-flex align-center justify-between mb-16">
        {isEditJob ? (
          <FormField.Input
            name={fieldNames.jobId}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'w-50',
            }}
            labelProps={{
              displayName: FIELD_LABELS.jobId,
            }}
            disabled
          />
        ) : (
          <div className="w-50 bg-note radius-5 p-10 mr-8">
            <p className="f-14 f-w-500 text-tertiary">
              <span className="f-w-600 text-secondary">Note:</span> JOB ID will
              be inserted after Create New Job button is clicked
            </p>
          </div>
        )}

        {/* CREATED BY */}
        <FormField.Input
          name={fieldNames.createdBy}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 ml-8',
          }}
          value={`${whoisDetails.firstName} ${whoisDetails.lastName}`}
          labelProps={{
            displayName: FIELD_LABELS.createdBy,
          }}
          disabled
        />
      </div>

      {/* CLIENT & LOAN NUMBER */}
      <div className="d-flex justify-between">
        <div className="d-flex w-50 flex-1">
          <FormField.Autocomplete
            noOptionsText={getNoOptionText(
              isClientListLoading,
              watch(fieldNames.searchClient, ''),
            )}
            name={fieldNames.clientId}
            onChange={onChangeClientId}
            searchFieldName={fieldNames.searchClient}
            aria-label="categoryIdSelect"
            placeholder={PLACEHOLDERS.selectClient}
            defaultValue={autoCompleteDefaultValue}
            options={clientList}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16 mr-8',
            }}
            labelProps={{
              displayName: FIELD_LABELS.client,
              required: true,
            }}
            rules={{
              required: ERROR_MESSAGES.clientRequired,
            }}
            disabled={clientList.length ? false : true}
          />
        </div>

        <FormField.Input
          name={fieldNames.loanNumber}
          placeholder={PLACEHOLDERS.enterLoanNumber}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mb-16 ml-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.loanNumber,
          }}
          rules={{
            validate: {
              loanNumber: loanNumberValidation(
                ERROR_MESSAGES.loanNumberInvalid,
              ),
            },
          }}
        />
      </div>

      {/* SCOPE CATEGORY, SCOPE & PRIOR REPORT */}
      <div className="d-flex">
        <div className="w-50 d-flex mr-8">
          <FormField.Select
            name={fieldNames.scopeCategory}
            aria-label="scopeCategoryIdSelect"
            placeholder={PLACEHOLDERS.selectScopeCategory}
            defaultValue=""
            options={scopeCategoryList}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16 mr-8',
            }}
            labelProps={{
              displayName: FIELD_LABELS.scopeCategory,
              required: true,
            }}
            rules={{
              required: ERROR_MESSAGES.scopeCategoryRequired,
            }}
            disabled={scopeCategoryList.length ? false : true}
          />

          <FormField.Select
            name={fieldNames.scopeId}
            aria-label="scopeIdSelect"
            placeholder={PLACEHOLDERS.selectScope}
            defaultValue=""
            options={sortBy(scopeList, ['acronym'])}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16 ml-8',
            }}
            labelProps={{
              displayName: FIELD_LABELS.scope,
              required: true,
            }}
            rules={{
              required: ERROR_MESSAGES.scopeRequired,
            }}
            disabled={
              (scopeList?.length ? false : true) || isEmpty(scopeCategory)
            }
          />
        </div>

        {/* PRIOR REPORT */}
        <FormField.Select
          name={fieldNames.priorReport}
          handleClear={() => {
            setValue(fieldNames.priorReport, '');
          }}
          aria-label="priorReportSelect"
          placeholder={PLACEHOLDERS.selectYesNo}
          defaultValue=""
          options={booleanDropdownList}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mb-16 ml-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.priorReport,
          }}
        />
      </div>

      {/* SBA & NAICS CODE */}
      <div className="d-flex">
        <FormField.Select
          name={fieldNames.sba}
          aria-label="sbaSelect"
          placeholder={PLACEHOLDERS.selectYesNo}
          defaultValue=""
          options={booleanDropdownList}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mb-16 mr-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.sba,
            required: true,
          }}
          rules={{
            required: ERROR_MESSAGES.sbaRequired,
          }}
        />

        <div className="d-flex w-50 ml-8">
          {/* NAICS CODE */}
          <FormField.Input
            name={fieldNames.naicsCode}
            placeholder={PLACEHOLDERS.enterNaicsCode}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16 mr-8',
            }}
            labelProps={{
              displayName: FIELD_LABELS.naicsCode,
            }}
            rules={{
              validate: {
                naics: numberValidation(ERROR_MESSAGES.naicsInvalid),
              },
            }}
          />
          <a
            href="https://storage.googleapis.com/ormsweb/src/files/NAICS%20Codes%20for%20Pop-out.pdf"
            target="_blank"
          >
            <DropdownPopupButton disabled={false} />
          </a>
        </div>
      </div>

      {/* FORECLOSURE & EXTERNAL DATA NEEDED */}
      <div className="d-flex">
        <FormField.Select
          name={fieldNames.foreclosure}
          aria-label="foreclosureSelect"
          placeholder={PLACEHOLDERS.selectYesNo}
          defaultValue=""
          options={booleanDropdownList}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 mr-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.foreclosure,
            required: true,
          }}
          rules={{
            required: ERROR_MESSAGES.foreclosureRequired,
          }}
        />

        {/* EXTERNAL DATA NEEDED */}
        <FormField.Select
          name={fieldNames.externalDataNeeded}
          handleClear={() => {
            setValue(fieldNames.externalDataNeeded, '');
          }}
          aria-label="externalDataNeededSelect"
          placeholder={PLACEHOLDERS.selectYesNo}
          defaultValue=""
          options={booleanDropdownList}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'w-50 ml-8',
          }}
          labelProps={{
            displayName: FIELD_LABELS.externalDataNeeded,
          }}
        />
      </div>
    </div>
  );
};

export default memo(JobDetailsSection);
