/**
 * Autocomplete
 */

import { memo } from 'react';

import { Autocomplete as MuiAutocomplete } from '@mui/material';

import { FormField } from 'app/components';

import { AutocompleteSelectType } from 'app/components/AutocompleteSelect/types';
import { SelectOptionType } from 'app/components/Select/types';

export const AutocompleteSelect = ({
  options,
  placeholder,
  searchFieldName,
  onChange,
  noOptionsText,
  name,
  defaultValue,
}: AutocompleteSelectType) => (
  <MuiAutocomplete
    className="autoComplete"
    aria-label={name}
    options={options}
    sx={{ width: 300 }}
    noOptionsText={noOptionsText}
    defaultValue={defaultValue}
    onChange={(e, value: SelectOptionType | null) => onChange(value?._id ?? '')}
    renderInput={params => (
      <FormField.Input
        {...params}
        name={searchFieldName}
        aria-label={searchFieldName}
        placeholder={placeholder}
      />
    )}
  />
);

export default memo(AutocompleteSelect);
