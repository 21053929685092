/**
 * AddPortfolioParentModal
 */

import { memo } from 'react';

// Material
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { FormField, Button } from 'app/components';

// Constants
import {
  BUTTON_COLORS,
  BUTTON_VARIANT_THEMES,
  FIELD_LABELS,
  PLACEHOLDERS,
} from 'utils/constants';
import { fieldNames } from 'app/pages/CreateEditJobLogModal/constants';
import { dropdownDimensions } from '../JobLogConsultantQuoteSection/constants';

// Types
import { JobLogDropdownType } from 'app/pages/JobLogsPage/types';

interface AddPortfolioParentModalProps {
  onModalClose: () => void;
  parentPortfolioJobList: JobLogDropdownType[];
  onModalSave: () => void;
}

export const AddPortfolioParentModal = ({
  onModalClose,
  parentPortfolioJobList,
  onModalSave,
}: AddPortfolioParentModalProps) => (
  <Dialog
    open={true}
    onClose={onModalClose}
    className="small-modal portfolio-modal"
  >
    <DialogTitle id="alert-dialog-title">
      <span className="f-20 l-h-22 f-w-700 text-black-8">
        Add Portfolio Parent
      </span>
    </DialogTitle>

    <DialogContent>
      <FormField.Select
        name={fieldNames.portfolioParentId}
        aria-label="portfolioParentIdSelect"
        placeholder={PLACEHOLDERS.searchJob}
        defaultValue=""
        MenuProps={{ PaperProps: { sx: dropdownDimensions } }}
        options={parentPortfolioJobList}
        formControlProps={{
          variant: BUTTON_VARIANT_THEMES.outlined,
          className: 'mb-16',
        }}
        labelProps={{
          className: 'form-label pb-6',
          displayName: FIELD_LABELS.addParent,
        }}
      />
    </DialogContent>

    <DialogActions>
      <Button
        aria-label="cancelBtn"
        color={BUTTON_COLORS.primary}
        variant={BUTTON_VARIANT_THEMES.outlined}
        name={FIELD_LABELS.cancelBtn}
        onClick={onModalClose}
      />

      <Button
        aria-label="saveBtn"
        color={BUTTON_COLORS.primary}
        variant={BUTTON_VARIANT_THEMES.contained}
        name={FIELD_LABELS.saveBtn}
        onClick={onModalSave}
      />
    </DialogActions>
  </Dialog>
);

export default memo(AddPortfolioParentModal);
