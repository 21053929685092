/**
 * PortfolioSticker
 */

import { memo } from 'react';

// Material
import Chip from '@mui/material/Chip';

// Services
import { getPortfolioId } from 'app/components/JobLogList/services';

interface PortfolioStickerProps {
  portfolioId?: number | null;
  childPortfolioId?: number | null;
}

export const PortfolioSticker = ({
  portfolioId,
  childPortfolioId,
}: PortfolioStickerProps) =>
  portfolioId ? (
    <Chip
      aria-label="portfolio-chip"
      label={getPortfolioId(portfolioId, childPortfolioId)}
      size="small"
      color="info"
      className="chip-grey item-grey"
    />
  ) : (
    <></>
  );

export default memo(PortfolioSticker);
