import isEmpty from 'lodash/isEmpty';

import { fieldNames } from 'app/components/JobLogQuoteDeliverySection/constants';
import { booleanApiValue, booleanValue } from 'utils/constants';

import { JobLogType, JobQuoteDeliveryType } from 'app/pages/JobLogsPage/types';

export const setDropDownFieldValue = (
  value: boolean | null | string = null,
) => {
  if (value === null) {
    return '';
  }
  return value ? booleanValue.one : booleanValue.zero;
};

export const getModifiedFormValues = (
  quoteDeliveryDetails?: JobQuoteDeliveryType,
  jobDetails?: JobLogType,
) => ({
  [fieldNames.dueDate]: quoteDeliveryDetails?.dueDate?.toString() ?? null,
  [fieldNames.amount]: quoteDeliveryDetails?.amount?.toString() ?? '',
  [fieldNames.clientApproval]: setDropDownFieldValue(
    quoteDeliveryDetails?.clientApproval,
  ),
  [fieldNames.isExtConsultantEngaged]: setDropDownFieldValue(
    quoteDeliveryDetails?.isExtConsultantEngaged,
  ),
  [fieldNames.isECReportReceived]:
    quoteDeliveryDetails?.isECReportReceived ?? false,
});

export const getQuoteDeliverObject = (
  quoteDeliveryApiData: JobQuoteDeliveryType,
  watchIsECReportReceived: boolean | string,
) => {
  delete quoteDeliveryApiData[fieldNames.quoteDays];
  delete quoteDeliveryApiData[fieldNames.jobDueDateInModal];
  delete quoteDeliveryApiData[fieldNames?.isECReportReceived];

  Object.entries(quoteDeliveryApiData).map(([key, values]) => {
    if (values === '') {
      quoteDeliveryApiData[`${key}`] = null;
    }
    if (
      !isEmpty(values) &&
      (key === fieldNames.clientApproval ||
        key === fieldNames.isExtConsultantEngaged)
    ) {
      quoteDeliveryApiData[`${key}`] =
        booleanApiValue[quoteDeliveryApiData[`${key}`]] ?? null;
    }
  });

  return watchIsECReportReceived
    ? {
        ...quoteDeliveryApiData,
        isECReportReceived: !!watchIsECReportReceived,
      }
    : quoteDeliveryApiData;
};
