import {
  ListAltOutlined as ListAltOutlinedIcon,
  InsertChartOutlinedRounded as InsertChartOutlinedRoundedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  ReceiptLongOutlined as ReceiptLongOutlinedIcon,
  HistoryOutlined as HistoryOutlinedIcon,
  SupervisorAccountOutlined as SupervisorAccountOutlinedIcon,
  LightbulbOutlined as LightbulbOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
} from '@mui/icons-material';

import { ROUTES } from 'utils/constants';

import { SideMenuType } from 'app/components/SideMenu/types';

const className = 'd-flex radius-6 p-12';
const iconClassName = 'f-24 text-tertiary';

export const sideMenuNonAdminList: SideMenuType[] = [
  {
    key: 'jobLogs',
    displayName: 'Job Log',
    redirectTo: ROUTES.jobLogsPage,
    pathName: ROUTES.jobLogsPage,
    className,
    getIcon: () => <ListAltOutlinedIcon className={iconClassName} />,
  },
];

export const sideMenuSuperAdminList: SideMenuType[] = [
  ...sideMenuNonAdminList,
  {
    key: 'jobStage',
    displayName: 'Job Stage',
    redirectTo: ROUTES.jobStagePage,
    pathName: ROUTES.jobStagePage,
    className,
    getIcon: () => <InsertChartOutlinedRoundedIcon className={iconClassName} />,
  },
  {
    key: 'dataQuote',
    displayName: 'Data & Quote',
    redirectTo: ROUTES.dataQuotePaginatedRoute,
    pathName: ROUTES.dataQuotePage,
    className,
    getIcon: () => <DescriptionOutlinedIcon className={iconClassName} />,
  },
  {
    key: 'invoices',
    displayName: 'Invoices',
    redirectTo: ROUTES.invoicesPaginatedRoute,
    pathName: ROUTES.invoicesPage,
    className,
    getIcon: () => <ReceiptLongOutlinedIcon className={iconClassName} />,
  },
  {
    key: 'history',
    displayName: 'History',
    redirectTo: ROUTES.historyPage,
    pathName: ROUTES.historyPage,
    className,
    getIcon: () => <HistoryOutlinedIcon className={iconClassName} />,
  },
  {
    key: 'client',
    displayName: 'Clients',
    redirectTo: ROUTES.clientsPaginatedRoute,
    pathName: ROUTES.clientsPage,
    className,
    getIcon: () => <SupervisorAccountOutlinedIcon className={iconClassName} />,
  },
  {
    key: 'scopes',
    displayName: 'Scopes',
    redirectTo: ROUTES.scopesPaginatedRoute,
    pathName: ROUTES.scopesPage,
    className,
    getIcon: () => <LightbulbOutlinedIcon className={iconClassName} />,
  },
  {
    key: 'users',
    displayName: 'Users',
    redirectTo: ROUTES.ormsUsersPaginatedRoute,
    pathName: ROUTES.usersPage,
    className,
    getIcon: () => <AccountCircleOutlinedIcon className={iconClassName} />,
  },
];

export const sideMenuAdminList: SideMenuType[] = sideMenuSuperAdminList.filter(
  sideNav => sideNav.key !== 'invoices',
);
