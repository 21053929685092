import { API_ROUTES, getCall, postCall, putCall } from 'utils/api';

import {
  getModifiedApiError,
  getModifiedApiUrl,
  getModifiedApiData,
} from 'utils/services/common.service';

export const getScopeCategoriesApi = async () => {
  try {
    const { data } = await getCall(API_ROUTES.scopeCategories);
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const getScopesApi = async payload => {
  try {
    const scopeApiUrl = `${API_ROUTES.scope}?categoryId=${
      payload.categoryId
    }&isDisabled=${false}`;
    const { data } = await getCall(scopeApiUrl);
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const clientsApi = async payload => {
  try {
    const clientApiUrl = getModifiedApiUrl(API_ROUTES.client, {
      ...payload,
      isDisabled: false,
      basicDetails: true,
    });

    const { data } = await getCall(clientApiUrl);
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const createJobApi = async payload => {
  try {
    const { data } = await postCall(
      API_ROUTES.createJob,
      getModifiedApiData(payload),
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const editJobApi = async payload => {
  try {
    const jobId = payload['jobId'];
    delete payload['jobId'];
    delete payload['createdBy'];

    const { data } = await putCall(
      `${API_ROUTES.job}/${jobId}`,
      getModifiedApiData(payload),
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const convertPortfolioChildApi = async payload => {
  try {
    const jobId = payload.jobId;
    delete payload.jobId;

    const { data } = await putCall(
      `${API_ROUTES.convertChild}/${jobId}`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const getClientByIdApi = async payload => {
  try {
    const ormsUsersApiUrl = `${API_ROUTES.client}/${payload}`;
    const { data } = await getCall(ormsUsersApiUrl);
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};
