import { call, put, takeLatest, all, debounce } from 'redux-saga/effects';

import { actions } from 'app/pages/CreateEditJobLogModal/slice';
import { actions as jobLogDetailsActions } from 'app/pages/JobLogDetailsPage/slice';
import {
  getScopeCategoriesApi,
  createJobApi,
  clientsApi,
  getScopesApi,
  editJobApi,
  convertPortfolioChildApi,
  getClientByIdApi,
} from 'app/pages/CreateEditJobLogModal/slice/api';
import { convertPortfolioParentApi } from 'app/pages/JobLogDeliveryPortfolioSection/slice/api';

import {
  CreateJobActionProps,
  GetScopeActionProps,
  GetClientActionProps,
  ConvertPortfolioParentActionProps,
  GetParentJobActionProps,
  ConvertPortfolioChildActionProps,
  DuplicatePortfolioActionProps,
} from 'app/pages/CreateEditJobLogModal/slice/types';

// Snackbar
import { actions as snackbarActions } from 'store/snackbar';
import { formatSnackBarSuccess } from 'utils/services/common.service';

// Constants
import { SUCCESS_MESSAGES } from 'utils/constants';
import { jobLogsPageApi } from 'app/pages/JobLogsPage/slice/api';
import { GetClientByIdActionProps } from 'app/pages/CreateEditClientPage/slice/types';

export function* getScopeCategories() {
  try {
    const scopeCategories = yield call(getScopeCategoriesApi);
    yield put(actions.getScopeCategoriesSuccessAction(scopeCategories));
  } catch (err: any) {
    yield put(actions.getScopeCategoriesErrorAction(err?.message));
  }
}

export function* watchGetScopeCategories() {
  yield takeLatest(actions.getScopeCategoriesAction.type, getScopeCategories);
}

export function* getScope({ payload }: GetScopeActionProps) {
  try {
    const scopeDetails = yield call(getScopesApi, payload);
    yield put(actions.getScopeSuccessAction(scopeDetails.list));
  } catch (err: any) {
    yield put(actions.getScopeErrorAction(err?.message));
  }
}

export function* watchGetScope() {
  yield takeLatest(actions.getScopeAction.type, getScope);
}

export function* getClient({ payload }: GetClientActionProps) {
  try {
    const clientDetails = yield call(clientsApi, payload);
    yield put(actions.getClientSuccessAction(clientDetails.list));
  } catch (err: any) {
    yield put(actions.getClientErrorAction(err?.message));
  }
}

export function* watchGetClient() {
  // NOTE: The debounce function waits for 1 second (1000 milliseconds) before performing its logic.
  yield debounce(1000, actions.getClientAction.type, getClient);
}

export function* createJob({ payload }: CreateJobActionProps) {
  try {
    const createScopeDetail = yield call(createJobApi, payload);
    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(SUCCESS_MESSAGES.jobLogCreatedSuccessfully),
      ),
    );
    yield put(actions.createJobSuccessAction(createScopeDetail));
  } catch (err: any) {
    yield put(actions.createJobErrorAction(err?.message));
  }
}

export function* watchCreateJob() {
  yield takeLatest(actions.createJobAction.type, createJob);
}

export function* editJob({ payload }: CreateJobActionProps) {
  try {
    const successMessage = payload.isParentPortfolio
      ? SUCCESS_MESSAGES.convertPortfolioUpdatedSuccessfully
      : SUCCESS_MESSAGES.jobLogUpdatedSuccessfully;

    delete payload?.isParentPortfolio;

    const editJobDetails = yield call(editJobApi, payload);
    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(successMessage),
      ),
    );

    yield put(actions.editJobSuccessAction(editJobDetails?.data));
    yield put(
      jobLogDetailsActions.updateJobLogDetailsSuccessAction(
        editJobDetails?.data,
      ),
    );
  } catch (err: any) {
    yield put(actions.editJobErrorAction(err?.message));
  }
}

export function* watchEditJob() {
  yield takeLatest(actions.editJobAction.type, editJob);
}

export function* convertPortfolioParent({
  payload,
}: ConvertPortfolioParentActionProps) {
  try {
    const data = yield call(convertPortfolioParentApi, payload);

    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(
          SUCCESS_MESSAGES.convertPortfolioParentSuccessfully,
        ),
      ),
    );
    yield put(actions.convertPortfolioParentSuccessAction(data));
    yield put(
      jobLogDetailsActions.updateJobLogDetailsSuccessAction(data?.data),
    );
  } catch (err: any) {
    yield put(actions.convertPortfolioParentErrorAction(err?.message));
  }
}

export function* watchConvertPortfolioParent() {
  yield takeLatest(
    actions.convertPortfolioParentAction.type,
    convertPortfolioParent,
  );
}

export function* getParentPortfolioList({ payload }: GetParentJobActionProps) {
  try {
    const { list } = yield call(jobLogsPageApi, payload);
    yield put(actions.getParentPortfolioListSuccessAction(list));
  } catch (err: any) {
    yield put(actions.getParentPortfolioListErrorAction(err?.message));
  }
}

export function* watchGetParentPortfolioList() {
  yield takeLatest(
    actions.getParentPortfolioListAction.type,
    getParentPortfolioList,
  );
}

export function* convertPortfolioChild({
  payload,
}: ConvertPortfolioChildActionProps) {
  try {
    const { data } = yield call(convertPortfolioChildApi, payload);

    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(
          SUCCESS_MESSAGES.convertPortfolioChildSuccessfully,
        ),
      ),
    );
    yield put(actions.convertPortfolioChildSuccessAction(data));
    yield put(jobLogDetailsActions.updateJobLogDetailsSuccessAction(data));
  } catch (err: any) {
    yield put(actions.convertPortfolioChildErrorAction(err?.message));
  }
}

export function* watchConvertPortfolioChild() {
  yield takeLatest(
    actions.convertPortfolioChildAction.type,
    convertPortfolioChild,
  );
}

export function* duplicatePortfolio({
  payload,
}: DuplicatePortfolioActionProps) {
  try {
    const createScopeDetail = yield call(createJobApi, payload.createJob);

    const data = yield call(convertPortfolioChildApi, {
      jobId: createScopeDetail.jobId,
      parentJobId: payload.parentJobId,
    });

    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(SUCCESS_MESSAGES.duplicatePortfolio),
      ),
    );
    yield put(actions.duplicatePortfolioSuccessAction(data));
  } catch (err: any) {
    yield put(actions.duplicatePortfolioErrorAction(err?.message));
  }
}

export function* watchDuplicatePortfolio() {
  yield takeLatest(actions.duplicatePortfolioAction.type, duplicatePortfolio);
}

export function* getClientById({ payload }: GetClientByIdActionProps) {
  try {
    const getClientDetailsByIds = yield call(getClientByIdApi, payload);
    yield put(actions.getClientByIdSuccessAction(getClientDetailsByIds));
  } catch (err: any) {
    yield put(actions.getClientByIdErrorAction(err?.message));
  }
}

export function* watchGetClientById() {
  yield takeLatest(actions.getClientByIdAction.type, getClientById);
}

export default function* createEditJobLogModalSaga() {
  yield all([
    watchGetScopeCategories(),
    watchGetScope(),
    watchGetClient(),
    watchCreateJob(),
    watchEditJob(),
    watchConvertPortfolioParent(),
    watchGetParentPortfolioList(),
    watchConvertPortfolioChild(),
    watchDuplicatePortfolio(),
    watchGetClientById(),
  ]);
}
