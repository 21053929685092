/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for DataQuotePage
 *
 */

import { lazyLoad } from 'utils/loadable';

const DataQuotePage = lazyLoad(
  () => import('./index'),
  module => module.DataQuotePage,
);

export default DataQuotePage;
