import { TABLE_DATA_TYPES } from 'utils/constants';

import { FieldArrayType } from 'app/pages/JobLogDetailsPage/types';

export const commonCellClassNames = {
  divClassName: 'flex-1',
  labelClassName: 'f-14 f-w-500 text-black-3 l-h-16',
  valueClassName: 'f-14 f-w-600 text-black-7 mt-8',
};

export const FinalReviewStage = {
  yesCompleted: 'yesCompleted',
  billableCancel: 'billableCancel',
  noFeeCancel: 'noFeeCancel',
  onHold: 'onHold',
};

export const commonClassNames = {
  divClassName: 'd-flex mt-24',
  leftCelClassName: commonCellClassNames,
  rightCelClassName: commonCellClassNames,
};

export const fieldPathDetails = {
  clientName: {
    key: 'clientName',
    displayName: 'Client Name',
    path: 'client.name',
    subPath: 'client.acronym',
    type: TABLE_DATA_TYPES.clientName,
  },
  loanNumber: {
    key: 'loanNumber',
    displayName: 'Loan Number',
    path: 'loanNumber',
    type: TABLE_DATA_TYPES.returnExact,
  },
  scope: {
    key: 'scope',
    displayName: 'Scope',
    path: 'scopeCategoryId.displayName',
    subPath: 'scope.acronym',
    type: TABLE_DATA_TYPES.name,
  },
  priorReport: {
    key: 'priorReport',
    displayName: 'Prior Report',
    path: 'priorReport',
    type: TABLE_DATA_TYPES.boolean,
  },
  sba: {
    key: 'sba',
    displayName: 'SBA',
    path: 'sba',
    type: TABLE_DATA_TYPES.boolean,
  },
  naicsCode: {
    key: 'naicsCode',
    displayName: 'NAICS Code',
    path: 'naicsCode',
  },
  foreClosure: {
    key: 'foreClosure',
    displayName: 'Potential Foreclosure',
    path: 'foreClosure',
    type: TABLE_DATA_TYPES.boolean,
  },
  externalDataNeeded: {
    key: 'externalDataNeeded',
    displayName: 'External Data Needed?',
    path: 'externalDataNeeded',
    type: TABLE_DATA_TYPES.boolean,
  },
  // Client Job Details
  clientJobName: {
    key: 'clientJobName',
    displayName: 'Job Name',
    path: 'clientJob.name',
  },
  clientPropertyType: {
    key: 'clientPropertyType',
    displayName: 'Property Type',
    path: 'clientJob.propertyType',
  },
  clientSiteAcres: {
    key: 'clientSiteAcres',
    displayName: 'Site Acres',
    path: 'clientJob.siteAcres',
  },
  clientSiteSquareFootage: {
    key: 'clientSiteSquareFootage',
    displayName: 'Site Square Footage',
    path: 'clientJob.siteSquareFootage',
  },
  constructionYear: {
    key: 'constructionYear',
    displayName: 'Construction Year',
    path: 'clientJob.constructionDate',
    type: TABLE_DATA_TYPES.year,
  },
};

export const jobLogFields = [
  // Client Name & Loan Number
  {
    divClassName: commonClassNames.divClassName,
    leftCelDetails: {
      ...commonClassNames.leftCelClassName,
      ...fieldPathDetails.clientName,
    },
    rightCelDetails: {
      ...commonClassNames.rightCelClassName,
      ...fieldPathDetails.loanNumber,
    },
  },
  // Scope & Prior Report
  {
    divClassName: commonClassNames.divClassName,
    leftCelDetails: {
      ...commonClassNames.leftCelClassName,
      ...fieldPathDetails.scope,
    },
    rightCelDetails: {
      ...commonClassNames.rightCelClassName,
      ...fieldPathDetails.priorReport,
    },
  },
  // SBA & Naics Code
  {
    divClassName: commonClassNames.divClassName,
    leftCelDetails: {
      ...commonClassNames.leftCelClassName,
      ...fieldPathDetails.sba,
    },
    rightCelDetails: {
      ...commonClassNames.rightCelClassName,
      ...fieldPathDetails.naicsCode,
    },
  },
  // Foreclosure & External Data Needed
  {
    divClassName: commonClassNames.divClassName,
    leftCelDetails: {
      ...commonClassNames.leftCelClassName,
      ...fieldPathDetails.foreClosure,
    },
    rightCelDetails: {
      ...commonClassNames.rightCelClassName,
      ...fieldPathDetails.externalDataNeeded,
    },
  },
] as FieldArrayType[];

export const clientJobFields = [
  // Client Job Name & Property Type
  {
    divClassName: commonClassNames.divClassName,
    leftCelDetails: {
      ...commonClassNames.leftCelClassName,
      ...fieldPathDetails.clientJobName,
    },
    rightCelDetails: {
      ...commonClassNames.rightCelClassName,
      ...fieldPathDetails.clientPropertyType,
    },
  },
] as FieldArrayType[];

export const siteDetails = [
  // SiteAcres & SiteSquareFootage
  {
    divClassName: commonClassNames.divClassName,
    leftCelDetails: {
      ...commonClassNames.leftCelClassName,
      ...fieldPathDetails.clientSiteAcres,
    },
    rightCelDetails: {
      ...commonClassNames.rightCelClassName,
      ...fieldPathDetails.clientSiteSquareFootage,
    },
  },
] as FieldArrayType[];

// ConstructionYear
export const constructionYearField = {
  ...commonClassNames.leftCelClassName,
  ...fieldPathDetails.constructionYear,
};
