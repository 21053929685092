/**
 * ButtonTooltip
 */

// Material
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneIcon from '@mui/icons-material/Done';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

// Constants
import { BUTTON_COLORS, BUTTON_TYPES } from 'styles/assets';
import { FIELD_LABELS } from 'utils/constants';

import {
  ButtonTooltipProps,
  ButtonTooltipSaveProps,
  ButtonTooltipCancelProps,
} from 'app/components/ButtonTooltip/types';

export const ButtonTooltip = ({
  tooltipTitle,
  buttonProps,
  icon,
  ...props
}: ButtonTooltipProps) => (
  <Tooltip title={tooltipTitle} {...props}>
    <IconButton {...buttonProps}>{icon}</IconButton>
  </Tooltip>
);

ButtonTooltip.Save = ({ buttonProps }: ButtonTooltipSaveProps) => (
  <ButtonTooltip
    tooltipTitle={FIELD_LABELS.saveBtn}
    aria-label="saveBtnTooltip"
    buttonProps={{
      color: BUTTON_COLORS.inherit,
      type: BUTTON_TYPES.submit,
      className: 'btn-action-success',
      ...buttonProps,
    }}
    icon={<DoneIcon />}
  />
);

ButtonTooltip.Cancel = ({ buttonProps }: ButtonTooltipCancelProps) => (
  <ButtonTooltip
    tooltipTitle={FIELD_LABELS.cancelBtn}
    aria-label="cancelBtnTooltip"
    buttonProps={{
      color: BUTTON_COLORS.error,
      ...buttonProps,
    }}
    icon={<ClearOutlinedIcon />}
  />
);

ButtonTooltip.Reload = ({ buttonProps }: ButtonTooltipCancelProps) => (
  <ButtonTooltip
    tooltipTitle={FIELD_LABELS.refreshBtn}
    aria-label="cancelBtnTooltip"
    buttonProps={{
      color: BUTTON_COLORS.success,
      ...buttonProps,
    }}
    icon={<RefreshOutlinedIcon />}
  />
);

export default ButtonTooltip;
