/**
 * useSnackbar
 */

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material
import {
  Alert,
  Snackbar as MuiSnackbar,
  Button,
  AlertColor,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

// Constants
import { BUTTON_COLORS } from 'utils/constants';

// Slice - Actions & Selectors
import { selectMessage, selectTheme } from 'store/snackbar/selectors';
import { actions as snackbarActions } from 'store/snackbar';

const vertical = 'top';
const horizontal = 'center';
const defaultSnackbarDetails = {
  show: false,
  message: '',
  theme: 'success' as AlertColor,
};

export const useSnackbar = () => {
  const [showSnackbarDetails, setShowSnackbarDetails] = useState(
    defaultSnackbarDetails,
  );

  const dispatch = useDispatch();

  const snackbarMessage = useSelector(selectMessage);
  const snackbarTheme = useSelector(selectTheme);

  const updateSnackDetails = details => {
    setShowSnackbarDetails(prevState => ({
      ...prevState,
      ...details,
    }));
  };

  const resetSnackDetails = useCallback(() => {
    setShowSnackbarDetails(defaultSnackbarDetails);
  }, []);

  useEffect(() => {
    if (snackbarMessage) {
      updateSnackDetails({
        show: true,
        message: snackbarMessage,
        theme: snackbarTheme,
      });

      // Clean up after showing message
      setTimeout(() => {
        resetSnackDetails();
        dispatch(snackbarActions.clearSnackbarAction());
      }, 5000);
    } else {
      resetSnackDetails();
    }
  }, [snackbarMessage, snackbarTheme]);

  const Snackbar = () =>
    showSnackbarDetails.show ? (
      <MuiSnackbar
        anchorOrigin={{ vertical, horizontal }}
        open={true}
        autoHideDuration={5000}
        onClose={resetSnackDetails}
        key={vertical + horizontal}
      >
        <Alert
          severity={showSnackbarDetails.theme}
          className="d-flex align-center"
          action={
            <Button
              className="p-0 minw-40"
              aria-label="close"
              color={BUTTON_COLORS.inherit}
              onClick={resetSnackDetails}
            >
              <CloseIcon fontSize="inherit" />
            </Button>
          }
        >
          {showSnackbarDetails.message}
        </Alert>
      </MuiSnackbar>
    ) : (
      <></>
    );

  return { Snackbar };
};

export default useSnackbar;
