/**
 * Button
 */

import { memo } from 'react';

// Material
import { Button as MuiButton } from '@mui/material';

import { ButtonProps } from 'app/components/Button/types';

export const Button = ({
  color,
  variant,
  className = '',
  name,
  type,
  children,
  ...props
}: ButtonProps) => (
  <MuiButton
    variant={variant}
    className={className}
    color={color}
    type={type ?? 'button'}
    {...props}
  >
    {name || children}
  </MuiButton>
);

export default memo(Button);
