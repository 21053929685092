/**
 * End and move now alert box
 */
// MUI imports
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Components
import JobLogButtonLabel from '../JobLogButtonLabel';

// Constants
import {
  BUTTON_SIZE,
  BUTTON_VARIANT_THEMES,
  FIELD_LABELS,
} from 'utils/constants';

// Interfaces
import { EndAndMoveNowAlertProps } from './types';

function EndAndMoveNowAlertBox({
  open,
  handleClose,
  endAndMoveJob,
}: EndAndMoveNowAlertProps) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="end-now-dialog-title"
        aria-describedby="end-now-dialog-description"
      >
        <DialogTitle id="end-now-dialog-title" className="mt-10">
          {'WARNING'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="end-now-dialog-description">
            By clicking <b>End & Move Now</b> button, this Job Record has been
            fulfilled, is no longer editable and will begin the process of
            migrating this Job Record to History (This action cannot be undone)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <JobLogButtonLabel
            isButtonVisible={true}
            buttonProps={{
              name: FIELD_LABELS.cancelBtn,
              variant: BUTTON_VARIANT_THEMES.outlined,
              'aria-label': 'cancelBtnConfirm',
              size: BUTTON_SIZE.medium,
              onClick: () => handleClose(),
            }}
            label={FIELD_LABELS.endAndMoveNow}
            labelAriaName="cancelLabel"
          />
          <JobLogButtonLabel
            isButtonVisible={true}
            buttonProps={{
              name: FIELD_LABELS.endAndMoveNow,
              variant: BUTTON_VARIANT_THEMES.contained,
              'aria-label': 'endAndMoveNowBtnConfirm',
              size: BUTTON_SIZE.medium,
              onClick: () => endAndMoveJob(),
            }}
            label={FIELD_LABELS.endAndMoveNow}
            labelAriaName="endAndMoveNowLabel"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EndAndMoveNowAlertBox;
