import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// MUI components
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import { IconButton, Tooltip, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Redux imports
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';
import { useSelector } from 'react-redux';

// Types and interfaces
import { JobFile, JobFiles, UploadKeys } from './types';
import { DownloadJobFilePayload } from './slice/types';

// API and services
import { downloadJobFile } from './slice/api';
import {
  downloadBlobFile,
  getFormattedDate,
  openBlobFile,
} from 'utils/services/common.service';
import { BUTTON_COLORS, BUTTON_VARIANT_THEMES, ROUTES } from 'utils/constants';
import { Button, FormField } from 'app/components';

// slice
import { useJobFolderDetailsSlice } from 'app/components/JobLogFolderSection/slice';
import dayjs from 'dayjs';

function JobFilesView(props: JobFiles) {
  const [copied, setCopied] = useState<null | string>(null);
  const { jobFolderActions } = useJobFolderDetailsSlice();
  const dispatch = useDispatch();

  const {
    files,
    isUploaderView = false,
    folderKey,
    setDownloadFile,
    selectAll,
    setLoader,
  } = props;
  const jobDetails = useSelector(selectJobLogDetails);
  const [checkedList, setCheckedList] = useState<any>([]);
  const { jobId } = jobDetails;
  const isHistoryPage = location.pathname.includes(ROUTES.historyPage);

  const _getData = async (jobFolder: JobFile) => {
    /** Set loader */
    setLoader && setLoader({ loading: true, error: '' });

    /** Download job file */
    const { fileName, _id } = jobFolder;
    const payload: DownloadJobFilePayload = {
      jobId,
      path: folderKey,
      fileId: _id,
    };

    const data = await downloadJobFile({
      ...payload,
      isHistory: isHistoryPage,
    });

    return { data: data, fileName: fileName };
  };

  const downloadFile = async (jobFolder: JobFile) => {
    const { data, fileName } = await _getData(jobFolder);

    if (data.error)
      return (
        setLoader &&
        setLoader({
          loading: false,
          error: data?.error,
        })
      );
    else await downloadBlobFile(data, fileName);

    setLoader && setLoader({ loading: false, error: '' });
  };

  // function that opens the downloaded file, similar to the above, but on a new browser tab
  const openFile = async (jobFolder: JobFile) => {
    const { data, fileName } = await _getData(jobFolder);

    if (data.error)
      return (
        setLoader &&
        setLoader({
          loading: false,
          error: data?.error,
        })
      );
    else await openBlobFile(data, fileName);

    setLoader && setLoader({ loading: false, error: '' });
  };

  const getSingedUrl = (jobFolder: JobFile) => {
    dispatch(
      jobFolderActions.getSingedUrlAction({
        jobId,
        path: folderKey,
        fileId: jobFolder._id,
        isHistory: isHistoryPage,
      }),
    );
  };

  useEffect(() => {
    setCheckedList(selectAll);
  }, [selectAll]);

  const hasAllowedExtension = (fileName: string) => {
    const allowedExtensions = ['.pdf', '.jpg', '.jpeg', '.png'];
    const hasAllowedExtension = allowedExtensions.some(ext =>
      fileName.toLowerCase().includes(ext),
    );

    return hasAllowedExtension;
  };

  const getFileNameClass = (fileName: string) => {
    const className = [
      'f-16 f-w-600 l-h-22 text-black-7 file-name download-btn',
      hasAllowedExtension(fileName) ? 'cursor-pointer' : '',
    ].join(' ');
    return className;
  };

  return (
    <div className="mt-8">
      <Typography id="folder-view-description">
        {files.map(jobFolder => (
          <div
            className="d-flex align-center justify-between py-12 border-bottom-darken"
            key={jobFolder._id}
          >
            <div
              className={`d-flex  align-start ${
                folderKey === UploadKeys.deliverable && !isUploaderView
                  ? 'file-displayName'
                  : ''
              }`}
            >
              {setDownloadFile && (
                <div className="w50">
                  <FormField.Checkbox
                    name={`file`}
                    className="w-50"
                    value={jobFolder._id}
                    onChange={e => {
                      const { value, checked } = e.target;
                      setDownloadFile(checked, {
                        path: folderKey,
                        fileId: jobFolder._id,
                        name: jobFolder.fileName,
                      });
                      if (checked) {
                        setCheckedList([...checkedList, value]);
                      } else {
                        setCheckedList(
                          checkedList.filter(item => item != value),
                        );
                      }
                    }}
                    checked={checkedList.includes(jobFolder._id)}
                  />
                </div>
              )}
              <FolderCopyIcon
                className="mr-16 text-black-3 text-truncate"
                fontSize="small"
              />
              <Tooltip title={jobFolder?.fileName} placement="top-start">
                <p
                  className={getFileNameClass(jobFolder.fileName)}
                  onClick={() => {
                    if (hasAllowedExtension(jobFolder.fileName)) {
                      openFile(jobFolder);
                    }
                  }}
                >
                  {jobFolder.fileName}
                </p>
              </Tooltip>
            </div>

            {folderKey === UploadKeys.deliverable && !isUploaderView && (
              <>
                <div className="file-displayName">
                  {!dayjs().isAfter(jobFolder?.expirePublicUrlAt, 'second') && (
                    <Tooltip title={jobFolder?.publicUrl} placement="top">
                      <a
                        href={jobFolder?.publicUrl}
                        target="_blank"
                        className="f-16 f-w-600 l-h-22  file-name text-truncate text-blue d-block text-primary-7"
                      >
                        {jobFolder?.publicUrl}
                      </a>
                    </Tooltip>
                  )}
                </div>

                <div className="copy-clipboard">
                  {jobFolder?.publicUrl &&
                    !dayjs().isAfter(jobFolder?.expirePublicUrlAt) && (
                      <Tooltip title={copied} placement="top">
                        <IconButton
                          onClick={() => {
                            if (jobFolder?.publicUrl)
                              navigator.clipboard.writeText(
                                jobFolder?.publicUrl,
                              );
                            setCopied('copied');
                            setTimeout(function () {
                              setCopied(null);
                            }, 1000);
                          }}
                        >
                          {<ContentCopyIcon />}
                        </IconButton>
                      </Tooltip>
                    )}
                </div>
                <div className="mr-10">
                  <Button
                    aria-label="generateLink"
                    className="generateButton"
                    color={BUTTON_COLORS.primary}
                    variant={BUTTON_VARIANT_THEMES.outlined}
                    name={
                      jobFolder?.publicUrl &&
                      !dayjs().isAfter(jobFolder?.expirePublicUrlAt)
                        ? 'Regenerate Link'
                        : 'Generate Link'
                    }
                    onClick={() => getSingedUrl(jobFolder)}
                  />
                </div>
              </>
            )}

            {jobFolder.updatedAt ? (
              <div className="d-flex f-12 f-w-400 l-h-22 text-black-5 mr-16">
                <Tooltip
                  title={`${jobFolder?.owner?.firstName} ${jobFolder?.owner?.lastName}`}
                  placement="top-start"
                >
                  <div className="mr-40 file-owner-name">
                    {`${jobFolder?.owner?.firstName} ${jobFolder?.owner?.lastName}`}
                  </div>
                </Tooltip>
                <div className="mr-40">
                  {getFormattedDate(jobFolder.updatedAt)}
                </div>
                <div>
                  {!isUploaderView && (
                    <DownloadIcon
                      onClick={() => downloadFile(jobFolder)}
                      className="cursor-pointer download-btn"
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </Typography>
    </div>
  );
}

export default memo(JobFilesView);
