/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for CreateEditAwfUserPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const CreateEditAwfUserPage = lazyLoad(
  () => import('./index'),
  module => module.CreateEditAwfUserPage,
);

export default CreateEditAwfUserPage;
