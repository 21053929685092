import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { notificationSaga } from './saga';
import {
  NotificationDetails,
  NotificationId,
  NotificationState,
} from './types';

export const initialState: NotificationState = {
  notifications: [] as NotificationDetails[],
  error: null,
  hasUnReadMsg: false,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getNotificationAction: state => ({
      ...state,
    }),
    pushNotification(state, action: PayloadAction<any>) {
      return {
        ...state,
        error: initialState.error,
        notifications: [...action.payload, ...state.notifications],
      };
    },
    setNotificationList(state, action: PayloadAction<any>) {
      return {
        error: initialState.error,
        notifications: action.payload,
      };
    },
    getNotificationError: state => ({
      ...state,
      error: 'disconnected',
    }),
    updateNotificationStatus(state, action: PayloadAction<NotificationId[]>) {
      return {
        ...state,
      };
    },
    hasUnReadMsg(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasUnReadMsg: action.payload,
      };
    },
    markAllAsRead(state) {
      return {
        ...state,
      };
    },
  },
});

export const { name: sliceName, actions } = slice;

export const useNotificationSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: notificationSaga });
  return { actions: slice.actions };
};

export default slice.reducer;
