import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import policiesSaga from './saga';
import { GetPoliciesSuccessProps, policiesState } from './types';
import { ErrorProps } from 'store/auth/types';

export const initialState: policiesState = {
  loading: false,
  error: null,
  termsAndCondition: null,
  support: null,
};

const slice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    getPoliciesAction: state => ({
      ...state,
      loading: true,
    }),

    getTermsAndConditionSuccessAction: (
      state,
      { payload }: GetPoliciesSuccessProps,
    ) => ({
      ...state,
      loading: false,
      termsAndCondition: payload,
    }),

    getSupportSuccessAction: (state, { payload }: GetPoliciesSuccessProps) => ({
      ...state,
      loading: false,
      support: payload,
    }),

    getPoliciesError: (state, { payload }: ErrorProps) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { name: sliceName, actions } = slice;

export const usePoliciesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: policiesSaga });
  return { actions: slice.actions };
};

export default slice.reducer;
