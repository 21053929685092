/* istanbul ignore file */

const COMMON_ROUTES = {
  user: '/user',
  roles: '/roles',
  scope: '/scope',
  client: '/client',
  orms: '/orms',
  awf: '/awf',
  job: '/job',
  stage: '/stage',
  invoice: '/invoice',
  notification: '/notification',
  history: '/history',
  chat: '/chat',
};

const API_ROUTES = {
  ...COMMON_ROUTES,
  whois: `${COMMON_ROUTES.user}/whoIs`,
  validateEmail: '/validateEmail',

  //uid
  uid: `${COMMON_ROUTES.user}/uid`,

  // Scope
  scopeCategories: '/scopeCategories',
  createScope: `${COMMON_ROUTES.scope}/create`,

  // Client
  createClient: `${COMMON_ROUTES.client}/create`,

  // ORMS Users
  createOrmsUser: `${COMMON_ROUTES.orms}/create`,

  // AWF Users
  createAwfUser: `${COMMON_ROUTES.awf}/create`,
  awfByFilter: `${COMMON_ROUTES.awf}/poc`,

  // Create/Edit Job Record
  createJob: `${COMMON_ROUTES.job}/create`,
  externalData: `${COMMON_ROUTES.job}/externalData`,
  consultantQuote: `${COMMON_ROUTES.job}/consultantQuote`,
  quoteDelivery: `${COMMON_ROUTES.job}/quoteDelivery`,
  finalReview: `${COMMON_ROUTES.job}/finalReview`,

  // Convert Parent
  convertParent: `${COMMON_ROUTES.job}/convert/parent`,

  // Convert Child
  convertChild: `${COMMON_ROUTES.job}/convert/child`,

  // Completion Portfolio
  portfolioCompletion: `${COMMON_ROUTES.job}/portfolio/completion`,

  // Completion Portfolio Job
  portfolioJobCompletion: `${COMMON_ROUTES.job}/portfolioJob/completion`,

  // Upload job files
  jobFileUpload: `${COMMON_ROUTES.job}/upload`,

  // Data Quote
  dataQuote: `${COMMON_ROUTES.job}/data/quote`,

  // GCS templates
  gcsTemplate: `${COMMON_ROUTES.job}/gcs/templates`,

  // History page
  // history: `${COMMON_ROUTES.job}/history`,

  // Invoice
  invoice: `${COMMON_ROUTES.invoice}`,

  // Invoice CSV
  invoiceCSV: `${COMMON_ROUTES.invoice}/generate-csv`,

  // Chat
  chatHistory: `${COMMON_ROUTES.chat}/history`,
  sendMessage: `${COMMON_ROUTES.chat}/send`,
  subscribeChat: `${COMMON_ROUTES.chat}/subscribe`,

  // Notifications
  markAsRead: `${COMMON_ROUTES.notification}/markAsRead`,

  // Terms And Condition
  policies: '/policies',
};

export default API_ROUTES;
