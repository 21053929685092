/**
 * JobLogAssociatesSection
 */

// React and devependent imports
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { JobConsultantForm } from './JobConsultantsForm';

import get from 'lodash/get';

// Slice
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';
import { useJobLogAssociatesSlice } from 'app/components/JobLogAssociatesSection/slice';
import { selectAuth } from 'store/auth/selectors';

// Constants
import { AwfUserRoleId } from './constants';
import { UserTypeEnum } from 'utils/constants';

export const JobLogAssociatesSection = ({ isHistoryPage }) => {
  const dispatch = useDispatch();
  const { actions } = useJobLogAssociatesSlice();
  const user = useSelector(selectAuth).whoisApiDetails.whoisDetails;

  const jobDetails = useSelector(selectJobLogDetails);

  const updateInJobAssociation = value => {
    dispatch(
      actions.updateJobAssociationAction({
        ...value,
        jobId: jobDetails?.jobId,
      }),
    );
  };

  return (
    <>
      {jobDetails?.jobAssociates?.map(jobAssociate => {
        if (
          user?.userType !== UserTypeEnum.awf ||
          user?.roleId?.name === jobAssociate.roleName
        )
          return (
            <JobConsultantForm
              roleId={get(jobDetails, AwfUserRoleId[jobAssociate.roleName])}
              roleName={jobAssociate.roleName}
              isCompleted={jobAssociate.isCompleted}
              updateInJobAssociation={updateInJobAssociation}
              isAwfUser={user?.userType === UserTypeEnum.awf}
              user={user}
              completed={jobAssociate.markedAt}
              isHistory={isHistoryPage}
            />
          );
      })}
    </>
  );
};

export default memo(JobLogAssociatesSection);
