/**
 * Selectors for AuthSlice
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState, sliceName } from 'store/auth';

export const selectAuth = (state: RootState) =>
  state?.[sliceName] || initialState;

export const selectWhoisDetails = createSelector(
  [selectAuth],
  subState => subState.whoisApiDetails,
);

export const selectValidateUserByEmailDetails = createSelector(
  [selectAuth],
  subState => subState.validateUserByEmailDetails,
);
