import { API_ROUTES, getCall, postCall } from 'utils/api';

import { getModifiedApiError } from 'utils/services/common.service';

export const validateUserByEmailApi = async payload => {
  try {
    const { data } = await postCall(
      `${API_ROUTES.user}${API_ROUTES.validateEmail}`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const whoisApi = async () => {
  try {
    const { data } = await getCall(API_ROUTES.whois);
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};
