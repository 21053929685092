import { ROUTES } from 'utils/constants';
import { endAndMoveNowApi, deliveryApi } from './api';

const updateDeliveryApi = async (jobId, payload, dispatch, actions) => {
  await deliveryApi(jobId, payload);
  dispatch(actions.getJobLogDetailsAction({ jobId }));
};

export const showEmailText = async (jobId, dispatch, actions) => {
  const payload = {
    showEmailText: true,
  };

  await updateDeliveryApi(jobId, payload, dispatch, actions);
};

export const invoiceSentBtn = async (jobId, dispatch, actions) => {
  const payload = {
    isInvoiceSent: true,
  };
  await updateDeliveryApi(jobId, payload, dispatch, actions);
};

export const endAndMoveNowBtn = async (jobId, payload, navigate) => {
  await endAndMoveNowApi(jobId, payload);
  return navigate(ROUTES.historyPage);
};
