/**
 * JobConsultantForm
 */

// React and devependent imports
import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

// Components
import { Form, FormField } from 'app/components';

// Assets and constants
import { BUTTON_VARIANT_THEMES } from 'styles/assets';
import {
  booleanDropdownList,
  FIELD_LABELS,
  PLACEHOLDERS,
} from 'utils/constants';

// Types
import { JobRecordConsultantsForm } from './type';

// Slice
import {
  selectAwfContractorUserList,
  selectAwfExtConsUserList,
  selectAwfInternalConsUserList,
} from 'app/pages/JobLogDetailsCenterSection/slice/selectors';

// Services
import { getBooleanValue } from 'app/pages/FilterModal/services';

import { isEmpty, isNull } from 'lodash';

import { AwfUserTypeEnum } from 'utils/constants/enum';
import dayjs from 'dayjs';

export interface JobConsultantFormType {
  completed: string | null;
  consultantAssignment: string;
}

export const JobConsultantForm = (props: JobRecordConsultantsForm) => {
  const awfExternalUser = useSelector(selectAwfExtConsUserList);
  const awfContractorUser = useSelector(selectAwfContractorUserList);
  const awfInternalUser = useSelector(selectAwfInternalConsUserList);

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      completed: isEmpty(getBooleanValue(props.isCompleted))
        ? null
        : getBooleanValue(props.isCompleted),
      consultantAssignment: '',
    } as JobConsultantFormType,
  });

  useEffect(() => {
    form.setValue(
      'completed',
      isEmpty(getBooleanValue(props.isCompleted))
        ? null
        : getBooleanValue(props.isCompleted),
      { shouldDirty: false },
    );
  }, [props.isCompleted]);

  const watchComplete = form.watch(
    'completed',
    isEmpty(getBooleanValue(props.isCompleted))
      ? null
      : getBooleanValue(props.isCompleted),
  );

  useEffect(() => {
    if (props.isCompleted !== !!+watchComplete! && watchComplete !== null) {
      props.updateInJobAssociation({
        isCompleted:
          isNull(watchComplete) || isEmpty(watchComplete)
            ? null
            : !!+watchComplete,
        role: props.roleName,
      });
    }
  }, [watchComplete]);

  const getUserName = () => {
    let user: any = {};
    switch (props.roleName) {
      case AwfUserTypeEnum.awfExternalConsultant:
        user = awfExternalUser.find(awf => awf?._id === props?.roleId);
        break;
      case AwfUserTypeEnum.awfContractor:
        user = awfContractorUser.find(awf => awf?._id === props?.roleId);
        break;
      case AwfUserTypeEnum.awfInternalConsultant:
        user = awfInternalUser.find(awf => awf?._id === props?.roleId);
    }
    return `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  };

  const getAwfUserName = () => {
    return `${props?.user?.firstName} ${props?.user?.lastName} `;
  };

  return (
    <div className="bg-white mt-16 p-16 radius-8 border-darken">
      <h5 className="f-14 l-h-22 f-w-700 text-black-8 pb-12">Job Associates</h5>
      <Form formName="jobConsultantForm" form={form} onSubmit={() => null}>
        <div>
          <div className="d-flex">
            <FormField.Input
              name="consultantAssignment"
              placeholder={PLACEHOLDERS.enterConsultantAssignment}
              formControlProps={{
                variant: BUTTON_VARIANT_THEMES.outlined,
                className: 'flex-1 mb-16',
              }}
              value={props?.isAwfUser ? getAwfUserName() : getUserName()}
              labelProps={{
                className: 'form-label pb-6',
                displayName: FIELD_LABELS.consultantAssignment,
              }}
              InputProps={{
                inputProps: { readOnly: true },
              }}
            />
          </div>
        </div>

        <FormField.Select
          name="completed"
          aria-label="completedSelect"
          placeholder={PLACEHOLDERS.selectChooseYesNo}
          options={booleanDropdownList}
          formControlProps={{
            variant: BUTTON_VARIANT_THEMES.outlined,
            className: 'flex-1 mb-16 mr-8',
          }}
          disabled={props.isHistory}
          labelProps={{
            className: 'form-label pb-6',
            displayName: FIELD_LABELS.completed,
          }}
        />
        <>
          {props.isCompleted && (
            <>
              <p>Completed</p>
              <p>Date: {dayjs(props?.completed).format('MM-DD-YYYY')}</p>
            </>
          )}
        </>
      </Form>
    </div>
  );
};

export default memo(JobConsultantForm);
