import { JobStageEnum } from 'utils/constants/enum';

export interface JobListClassType {
  displayName: string;
  chipClassName: string;
  cardClassName: string;
}

export const defaultClassDetails: JobListClassType = {
  displayName: '',
  chipClassName: '',
  cardClassName: '',
};

export const displayDetailsByEnum = {
  [JobStageEnum.jobCreated]: {
    displayName: 'Job Created',
    chipClassName: 'chip-grey',
    cardClassName: 'item-grey',
  },
  [JobStageEnum.dataQuote]: {
    displayName: 'Data & Quote',
    chipClassName: 'chip-yellow',
    cardClassName: 'item-yellow',
  },
  [JobStageEnum.externalConsultantDeliverables]: {
    displayName: 'Ext. Cons. Deliverables',
    chipClassName: 'chip-green',
    cardClassName: 'item-green',
  },
  [JobStageEnum.internalReview]: {
    displayName: 'Internal Review',
    chipClassName: 'chip-purple',
    cardClassName: 'item-purple',
  },
  [JobStageEnum.internalConsultantReview]: {
    displayName: 'Internal. Cons. Review',
    chipClassName: 'chip-blue',
    cardClassName: 'item-blue',
  },
  [JobStageEnum.contractorReview]: {
    displayName: 'Contractor Review',
    chipClassName: 'chip-orange',
    cardClassName: 'item-orange',
  },
  [JobStageEnum.ormsFinal]: {
    displayName: 'ORMS Final',
    chipClassName: 'chip-red',
    cardClassName: 'item-red',
  },
};
