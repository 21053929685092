/**
 * index.tsx
 */

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import './styles/scss/ui.scss';

// Child Components
import App from 'app';

import { configureAppStore } from 'store/configureStore';

// Material
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import muiTheme from 'styles/theme/mui-theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import reportWebVitals from 'reportWebVitals';
import initializeFirebase from 'utils/services/firebase.service';
import { TabBadgerContext } from 'context/TabBadgerContext';
import { TabBadger } from 'utils/services/TabBadger';

const openSansObserver = new FontFaceObserver('Inter', {});

openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();

const container = document.getElementById('root');
const root = createRoot(container!);

// Initialize firebase
initializeFirebase.getInstance();

root.render(
  <Provider store={store}>
    <TabBadgerContext.Provider value={new TabBadger()}>
      <MuiThemeProvider theme={muiTheme}>
        <HelmetProvider>
          <StrictMode>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </LocalizationProvider>
          </StrictMode>
        </HelmetProvider>
      </MuiThemeProvider>
    </TabBadgerContext.Provider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
