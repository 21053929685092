import { all, call, put, takeLatest } from 'redux-saga/effects';

// Actions
import { actions } from 'app/pages/JobLogDetailsCenterSection/slice';
import { actions as jobLogDetailsPageActions } from 'app/pages/JobLogDetailsPage/slice';

// Api
import {
  updateExtDataApi,
  getAwfUserByFilterApi,
  updateConsultantQuoteApi,
  updateQuoteDeliveryApi,
  updateFinalReviewApi,
  getOrmsList,
} from 'app/pages/JobLogDetailsCenterSection/slice/api';
import { getOrmsUsersListApi } from 'app/pages/CreateEditAwfUserPage/slice/api';

// Types
import {
  UpdateJobExtDataProps,
  UpdateConsultantQuoteProps,
  UpdateQuoteDeliveryProps,
  UpdateFinalReviewProps,
} from 'app/pages/JobLogDetailsCenterSection/slice/types';

// Snackbar
import { actions as snackbarActions } from 'store/snackbar';

// Services
import { formatSnackBarSuccess } from 'utils/services/common.service';

// Constants
import { SUCCESS_MESSAGES } from 'utils/constants';
import { AwfUserTypeEnum } from 'utils/constants/enum';

export function* getOrmsUsersList() {
  try {
    const ormsUserList = yield call(getOrmsList, {
      asc: true,
      sortBy: 'firstName',
    });
    yield put(actions.getOrmsUserListSuccessAction(ormsUserList));
  } catch (err: any) {
    yield put(actions.getOrmsUserListErrorAction(err?.message));
  }
}

export function* watchGetOrmsUserDetails() {
  yield takeLatest(actions.getOrmsUserListAction.type, getOrmsUsersList);
}

export function* getAwfExtConsUsersList() {
  try {
    const awfExtUserList = yield call(getAwfUserByFilterApi, {
      awfUserType: AwfUserTypeEnum.awfExternalConsultant,
    });
    yield put(actions.getAwfExtConsUsersListSuccessAction(awfExtUserList));
  } catch (err: any) {
    yield put(actions.getAwfExtConsUsersListErrorAction(err?.message));
  }
}

export function* watchGetAwfExtConsultantUserDetails() {
  yield takeLatest(
    actions.getAwfExtConsUsersListAction.type,
    getAwfExtConsUsersList,
  );
}

export function* getAwfIntConsUsersList() {
  try {
    const awfIntUserList = yield call(getAwfUserByFilterApi, {
      awfUserType: AwfUserTypeEnum.awfInternalConsultant,
    });
    yield put(actions.getAwfIntConsUsersListSuccessAction(awfIntUserList));
  } catch (err: any) {
    yield put(actions.getAwfIntConsUsersListErrorAction(err?.message));
  }
}

export function* watchGetAwfIntConsultantUserDetails() {
  yield takeLatest(
    actions.getAwfIntConsUsersListAction.type,
    getAwfIntConsUsersList,
  );
}

export function* getAwfContractorUsersList() {
  try {
    const awfContractorUserList = yield call(getAwfUserByFilterApi, {
      awfUserType: AwfUserTypeEnum.awfContractor,
    });
    yield put(
      actions.getAwfContractorUsersListSuccessAction(awfContractorUserList),
    );
  } catch (err: any) {
    yield put(actions.getAwfContractorUsersListErrorAction(err?.message));
  }
}

export function* watchGetAwfContractorUserDetails() {
  yield takeLatest(
    actions.getAwfContractorUsersListAction.type,
    getAwfContractorUsersList,
  );
}

export function* updateJobExtData({ payload }: UpdateJobExtDataProps) {
  try {
    const { data } = yield call(updateExtDataApi, payload);
    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(
          payload.received
            ? SUCCESS_MESSAGES.externalReceivedSuccessfully
            : SUCCESS_MESSAGES.externalOrderedSuccessfully,
        ),
      ),
    );
    yield put(actions.updateJobExtDataSuccessAction(data));
    yield put(jobLogDetailsPageActions.updateJobLogDetailsSuccessAction(data));
  } catch (err: any) {
    yield put(actions.updateJobCenterSectionErrorAction(err?.message));
  }
}

export function* watchUpdateJobExtData() {
  yield takeLatest(actions.updateJobExtDataAction.type, updateJobExtData);
}

export function* updateConsultantQuoteDetails({
  payload,
}: UpdateConsultantQuoteProps) {
  try {
    const { data } = yield call(updateConsultantQuoteApi, payload);
    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(
          SUCCESS_MESSAGES.consultantQuoteUpdatedSuccessfully,
        ),
      ),
    );
    yield put(actions.updateConsultantQuoteSuccessAction(data));
    yield put(jobLogDetailsPageActions.updateJobLogDetailsSuccessAction(data));
  } catch (err: any) {
    yield put(actions.updateJobCenterSectionErrorAction(err?.message));
  }
}

export function* watchUpdateConsultantQuote() {
  yield takeLatest(
    actions.updateConsultantQuoteAction.type,
    updateConsultantQuoteDetails,
  );
}

export function* updateQuoteDeliveryDetails({
  payload,
}: UpdateQuoteDeliveryProps) {
  try {
    const { data } = yield call(updateQuoteDeliveryApi, payload);
    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(
          SUCCESS_MESSAGES.quoteDeliveryUpdatedSuccessfully,
        ),
      ),
    );
    yield put(actions.updateQuoteDeliverySuccessAction(data));
    yield put(jobLogDetailsPageActions.updateJobLogDetailsSuccessAction(data));
  } catch (err: any) {
    yield put(actions.updateJobCenterSectionErrorAction(err?.message));
  }
}

export function* watchUpdateQuoteDelivery() {
  yield takeLatest(
    actions.updateQuoteDeliveryAction.type,
    updateQuoteDeliveryDetails,
  );
}

export function* updateFinalReviewDetails({ payload }: UpdateFinalReviewProps) {
  try {
    const { data } = yield call(updateFinalReviewApi, payload);
    yield put(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(SUCCESS_MESSAGES.finalReviewUpdatedSuccessfully),
      ),
    );
    yield put(actions.updateFinalReviewSuccessAction(data));
    yield put(jobLogDetailsPageActions.updateJobLogDetailsSuccessAction(data));
  } catch (err: any) {
    yield put(actions.updateJobCenterSectionErrorAction(err?.message));
  }
}

export function* watchUpdateFinalReview() {
  yield takeLatest(
    actions.updateFinalReviewAction.type,
    updateFinalReviewDetails,
  );
}

export default function* jobLogDetailsCenterSectionSaga() {
  yield all([
    watchGetOrmsUserDetails(),
    watchGetAwfExtConsultantUserDetails(),
    watchGetAwfIntConsultantUserDetails(),
    watchGetAwfContractorUserDetails(),

    watchUpdateJobExtData(),
    watchUpdateConsultantQuote(),
    watchUpdateQuoteDelivery(),
    watchUpdateFinalReview(),
  ]);
}
