import { memo } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';

import { CheckboxProps } from 'app/components/Checkbox/types';

export const Checkbox = ({ name, label, ...props }: CheckboxProps) => (
  <FormControlLabel
    control={<MuiCheckbox name={name} {...props} />}
    label={label}
  />
);

export default memo(Checkbox);
