const errorMessage = {
  firebaseError: 'Firebase Error',

  //Email
  emailRequired: 'Email is required',
  enterValidEmail: 'Please enter a valid Email',
  uniqueEmailValidation: 'Email exists already',

  //POC Email
  pocEmailRequired: 'POC Email is required',
  pocEmailInvalid: 'Please enter the valid POC Email',

  // Password
  passwordRequired: 'Password is required',
  newPasswordRequired: 'New Password is required',
  confirmNewPasswordRequired: 'Confirm New Password is required',
  minPasswordError: 'Password must contain minimum of 10 characters',
  maxPasswordError: 'Password must contain less than 20 characters',
  validPasswordError:
    'Password must contain minimum of 10 characters, one UpperCase, one LowerCase, one Number and one Special character',
  passwordNotMatch: 'Password does not match',

  // OTP
  otpRequired: 'OTP is required',
  invalidOtp: 'Please enter valid OTP',

  // Category
  categoryRequired: 'Category is required',

  // Name
  nameRequired: 'Name is required',
  invalidName: 'Please enter valid Name',

  // Acronym
  acronymRequired: 'Acronym is required',
  acronymCapitalError: 'Acronym should be alphanumeric',
  acronymLengthError: 'Please enter atleast 2 characters',

  // Date
  dateRequired: 'Date is required',

  // Price
  priceRequired: 'Price is required',
  invalidPrice: 'Please enter valid Price',
  maxPriceError: 'Please enter Price less than 99999',

  // Phone
  phoneRequired: 'Phone Number   is required',
  invalidPhone: 'Please enter valid Phone Number',

  // Address
  addressRequired: 'Address is required',
  invalidAddress: 'Please enter valid Address',

  // City
  cityRequired: 'City is required',
  invalidCity: 'City must have atLeast 1 alphabet',

  // State
  stateRequired: 'State is required',
  invalidState: 'Please enter valid State',

  // Zip
  zipRequired: 'Zip is required',
  invalidZip: 'Please enter valid Zip',

  // Delivery location 1
  locationRequired_1: 'Delivery Location 1 is required',
  invalidLocationRequired_1: 'Please enter valid Delivery Location 1',

  // Delivery location 2
  locationRequired_2: 'Delivery Location 2 is required',
  invalidLocationRequired_2: 'Please enter valid Delivery Location 2',

  // FirstName
  firstNameRequired: 'First Name is required',
  invalidFirstName: 'Please enter valid First Name',

  // LastName
  lastNameRequired: 'Last Name is required',
  invalidLastName: 'Please enter valid Last Name',

  // Initials
  initialsRequired: 'Initials is required',
  invalidInitials: 'Initials should be alphabet and capitalized',

  // Group
  groupRequired: 'Group is required',

  // ClientName
  clientNameRequired: 'Client Name is required',
  clientNameInvalid: 'Please enter valid Client Name',

  // NAICS
  naicsInvalid: 'Please enter valid NAICS',

  // Loan  Number
  loanNumberInvalid: 'Please enter valid Loan Number',

  // Client
  clientRequired: 'Client is required',
  clientSearchName: 'Please enter valid Client Name',

  // Scope Category
  scopeCategoryRequired: 'Scope Category is required',

  // Scope
  scopeRequired: 'Scope is required',
  scopeNotFound: 'Scopes not found',
  scopeNameRequired: 'Scope Name is required',
  scopeNameInvalid: 'Please enter a valid scope name',

  // Sba
  sbaRequired: 'SBA is required',

  // Foreclosure
  foreclosureRequired: 'Foreclosure is required',

  // Client Job Name
  clientJobNameInvalid: 'Please enter valid Client Job Name',
  clientJobNameLengthError: 'Please enter atleast 4 characters',

  // Property Type
  propertyTypeInvalid: 'Please enter valid Property Type',

  // Site Acres
  siteAcresInvalid: 'Please enter valid Site Acres',

  // Site Square Footage
  siteSquareFootageInvalid: 'Please enter valid Site Square Footage',

  // Job Recipients
  jobRecipientsRequired: 'Job Recipients is required',

  // Site Address
  siteAddressRequired: 'Site Address is required',
  siteAddressInvalid: 'Please enter valid Site Address',

  // Site City
  siteCityRequired: 'Site City is required',
  siteCityInvalid: 'Site City must have atLeast 1 alphabet',

  // Site State
  siteStateRequired: 'Site State Abbreviation is required',
  siteStateInvalid: 'Please enter valid Site State Abbreviation',

  // AWF Company Name
  awfCompanyName: 'AWF Company Name is required',
  awfCompanyNameInvalid: 'Please enter valid AWF Company Name',

  // AWF Abbreviation
  awfAbbreviation: 'AWF Abbreviation is required',
  awfAbbreviationInvalid: 'Please enter valid AWF Abbreviation',

  // Phone Number
  phoneNumberRequired: 'Phone Number is required',
  invalidPhoneNumber: 'Please enter valid Phone Number',

  // AWF Group
  awfGroup: 'AWF Group is required',

  // POC Initials
  pocInitials: 'POC Initials is required',
  pocInitialsInvalid: 'Please enter valid POC Initials',

  // DueDate
  dueDateRequired: 'Due Date is required',

  // Job Number
  jobNumberRequired: 'Job Number is required',
  jobNumberInvalid: 'Please enter valid Job Number',

  // Reported By
  reportedByRequired: 'Reported By is required',
  reportedByInvalid: 'Please enter valid Reported By',

  // amount
  amountInvalid: 'Please enter valid Amount',
  maxAmountInvalid: 'Please enter Amount less than 99999',

  // Job Template
  jobTemplateRequired: 'Please Select Job Template',

  // Expiration Date
  expirationDateRequired: 'Expiration Date is Required',

  // Insurance Certificate
  insuranceCertificateRequired: 'Insurance Certificate is Required',
  incidentReportDateRequired: 'Incident Report Date is required',
};

export default errorMessage;
