import { API_ROUTES, putCall } from 'utils/api';
import { getModifiedApiError } from 'utils/services/common.service';

export const deliveryApi = async (jobId, payload) => {
  try {
    const { data } = await putCall(
      `${API_ROUTES.job}/${jobId}/delivery`,
      payload,
    );
    return data;
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};

export const endAndMoveNowApi = async (jobId, payload) => {
  try {
    await putCall(`${API_ROUTES.job}/${jobId}/archive`, payload);
  } catch (error) {
    throw new Error(getModifiedApiError(error));
  }
};
