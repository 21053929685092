import { FIELD_LABELS } from 'utils/constants';

export const fieldNames = {
  createdBy: 'createdBy',
  clientId: 'clientId',
  loanNumber: 'loanNumber',
  scopeCategory: 'scopeCategory',
  scopeId: 'scopeId',
  priorReport: 'priorReport',
  sba: 'sba',
  naicsCode: 'naicsCode',
  foreclosure: 'foreClosure',
  externalDataNeeded: 'externalDataNeeded',
  jobId: 'jobId',
  searchClient: 'searchClient',
  portfolioType: 'portfolioType',

  // Client Job Details
  clientName: 'clientJob.name',
  clientPropertyType: 'clientJob.propertyType',
  clientSiteAcres: 'clientJob.siteAcres',
  clientSiteSquareFootage: 'clientJob.siteSquareFootage',
  clientConstructionYear: 'clientJob.constructionDate',
  clientJobRecipients: 'clientJob.jobRecipients',

  // Site Location Details
  siteLocationAddress: 'siteLocation.address',
  siteLocationCity: 'siteLocation.city',
  siteLocationState: 'siteLocation.state',

  // Portfolio Parent Id
  portfolioParentId: 'portfolioParentId',
};

export const prefillFieldsForJobPath = {
  ...fieldNames,
  clientId: 'client._id',
  scopeId: 'scope._id',
  scopeCategory: 'scope.categoryId',
  createdBy: {
    firstName: 'createdBy.firstName',
    lastName: 'createdBy.lastName',
  },
  foreclosure: 'foreClosure',
};

export enum JobPortfolioEnum {
  parent = 'parent',
  child = 'child',
}

export enum JobAddEditTypeEnum {
  createJob = 'createJob',
  editJob = 'editJob',
  duplicateJob = 'duplicateJob',
}

export const modelDetailsByType = {
  [JobAddEditTypeEnum.createJob]: {
    title: 'Job Record',
    footerBtnName: FIELD_LABELS.createNewJobBtn,
  },
  [JobAddEditTypeEnum.editJob]: {
    title: 'Edit Job Record',
    footerBtnName: FIELD_LABELS.saveChangesBtn,
  },
  [JobAddEditTypeEnum.duplicateJob]: {
    title: 'Duplicate Job Record Portfolio Child',
    footerBtnName: FIELD_LABELS.createPortfolioChild,
  },
};

export const portfolioOptions = [
  {
    key: JobPortfolioEnum.parent,
    value: JobPortfolioEnum.parent,
    displayName: FIELD_LABELS.createParent,
  },
  {
    key: JobPortfolioEnum.child,
    value: JobPortfolioEnum.child,
    displayName: FIELD_LABELS.createChild,
  },
];
