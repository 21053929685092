/**
 * LoaderErrorWrapper
 */

import { memo } from 'react';

// Child Components
import { Loader, ErrorAlert } from 'app/components';

export interface LoaderErrorWrapperProps {
  loading: boolean;
  error?: string | null;
  children: JSX.Element | JSX.Element[];
  onCloseError?: () => void | undefined;
  className?: string;
}

export const LoaderErrorWrapper = ({
  loading,
  error = null,
  children,
  onCloseError,
  className,
}: LoaderErrorWrapperProps) => (
  <>
    {error && <ErrorAlert error={error} onCloseError={onCloseError} />}

    {loading && <Loader className={className} />}

    {children}
  </>
);

export default memo(LoaderErrorWrapper);
