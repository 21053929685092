/**
 * JobLogDeliverySection
 */

import { memo, useState } from 'react';

// Components and assets
import { JobLogButtonLabel, Button } from 'app/components';
import ShowEmailTextModal from './ShowEmailTextModal';
import {
  BUTTON_COLORS,
  BUTTON_SIZE,
  BUTTON_VARIANT_THEMES,
} from 'styles/assets';

// Router imports
import { useNavigate } from 'react-router-dom';

// Redux imports
import { useSelector } from 'react-redux';
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';
import { useDispatch } from 'react-redux';
import { useJobLogDetailsSlice } from 'app/pages/JobLogDetailsPage/slice';

// Types
import { JobLogDeliveryDetailsType } from 'app/pages/JobLogsPage/types';

// Constants
import { FinalReviewStage } from 'app/pages/JobLogDetailsPage/constants';
import { FIELD_LABELS } from 'utils/constants';

// services and utils
import { endAndMoveNowBtn, invoiceSentBtn, showEmailText } from './services';
import { isEmpty, isNull } from 'lodash';
import EndAndMoveNowAlertBox from './EndAndMoveNowAlertBox';

export interface JobLogDeliverySectionProps {
  deliveryDetails: JobLogDeliveryDetailsType;
  onYesDeliverySentClick: () => void;
  isHistoryPage: boolean;
}

export const JobLogDeliverySection = ({
  deliveryDetails,
  onYesDeliverySentClick,
  isHistoryPage,
}: JobLogDeliverySectionProps) => {
  const jobDetails = useSelector(selectJobLogDetails);
  const { finalReview, jobId } = jobDetails;
  const invoiceGenerated = jobDetails.invoices?.length === 0 ? false : true;
  const dispatch = useDispatch();
  const { actions } = useJobLogDetailsSlice();
  const navigate = useNavigate();

  const [openEmailTextModal, setOpenEmailTextModal] = useState<boolean>(false);
  const [openEndNowAlertBox, setOpenEndNowAlertBox] = useState<boolean>(false);

  const disableEndMoveNow = jobDetails?.parentPortfolioJobId
    ? jobDetails?.isPortfolioJobCompleted
    : jobDetails?.isPortfolioCompleted;

  return (
    <>
      <ShowEmailTextModal
        open={openEmailTextModal}
        handleClose={() => setOpenEmailTextModal(!openEmailTextModal)}
      />
      <EndAndMoveNowAlertBox
        open={openEndNowAlertBox}
        handleClose={() => setOpenEndNowAlertBox(!openEndNowAlertBox)}
        endAndMoveJob={() =>
          endAndMoveNowBtn(jobId, jobDetails.finalReview, navigate)
        }
      />
      <div className="bg-white mt-12 p-24 radius-8">
        <h5 className="f-14 l-h-22 f-w-700 text-black-8 mb-16">Delivery</h5>

        <div className="d-flex align-center justify-between mb-16">
          <p className="f-14 l-h-16 f-w-500 text-secondary">
            Delivery Creation
          </p>
          <Button
            name="Show Email Text"
            aria-label="showMailText"
            variant={BUTTON_VARIANT_THEMES.contained}
            color={BUTTON_COLORS.primary}
            disabled={false || isHistoryPage}
            onClick={() => {
              showEmailText(jobId, dispatch, actions);
              setOpenEmailTextModal(!openEmailTextModal);
            }}
          />
        </div>
        <div className="d-flex align-center justify-between mb-16">
          <p className="f-14 l-h-16 f-w-500 text-secondary">
            Deliverable & Invoice Sent?
          </p>

          <JobLogButtonLabel
            isButtonVisible={!deliveryDetails.isDeliverableInvoiceSent}
            buttonProps={{
              variant: BUTTON_VARIANT_THEMES.contained,
              name: FIELD_LABELS.yesSent,
              'aria-label': 'yesSent',
              size: BUTTON_SIZE.medium,
              onClick: () => {
                onYesDeliverySentClick();
                invoiceSentBtn(jobId, dispatch, actions);
              },
              disabled:
                isEmpty(finalReview?.finalReviewStatus) ||
                finalReview?.finalReviewStatus === FinalReviewStage.onHold ||
                finalReview?.finalReviewStatus === null ||
                isHistoryPage ||
                !isEmpty(jobDetails?.invoiceDetails?.invoiceId) ||
                !jobDetails?.showEmailText,
            }}
            label={FIELD_LABELS.sent}
            labelAriaName="yesSentLabelSection"
          />
        </div>

        <div className="d-flex align-center justify-between">
          <p className="f-14 l-h-16 f-w-500 text-secondary">
            Job Record End & Move to History (Archive)
          </p>

          <JobLogButtonLabel
            isButtonVisible={!deliveryDetails.isDeliverableInvoiceSent}
            buttonProps={{
              name: FIELD_LABELS.endAndMoveNow,
              'aria-label': 'endAndMoveNow',
              variant: BUTTON_VARIANT_THEMES.contained,
              size: BUTTON_SIZE.medium,
              disabled:
                !invoiceGenerated ||
                finalReview?.finalReviewStatus === FinalReviewStage.onHold ||
                !jobDetails.showEmailText ||
                !jobDetails.isInvoiceSent ||
                isHistoryPage ||
                (isNull(jobDetails.portfolioId)
                  ? false
                  : jobDetails?.hasActiveChildJobs || !disableEndMoveNow),
              onClick: () => setOpenEndNowAlertBox(true),
            }}
            label={FIELD_LABELS.sent}
            labelAriaName="endAndMoveNowLabelSection"
          />
        </div>
      </div>
    </>
  );
};

export default memo(JobLogDeliverySection);
