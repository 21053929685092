/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for ScopesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const ScopesPage = lazyLoad(
  () => import('./index'),
  module => module.ScopesPage,
);

export default ScopesPage;
