/* istanbul ignore file */

/**
 *
 * Asynchronously loads the component for JobLogDetailsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

const JobLogDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.JobLogDetailsPage,
);

export default JobLogDetailsPage;
