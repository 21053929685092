import { API_ROUTES, getCall, putCall } from 'utils/api';

import { getModifiedApiError } from 'utils/services/common.service';

export const notificationApi = async () => {
  try {
    const { data } = await getCall(API_ROUTES.notification);

    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const updateNotificationApi = async (id: string[]) => {
  try {
    const { data } = await putCall(API_ROUTES.notification, {
      notificationIds: id,
    });

    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const markAllAsReadApi = async () => {
  try {
    const { data } = await putCall(API_ROUTES.markAsRead, {});
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};
