import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.policies || initialState;

export const selectPolicies = createSelector([selectSlice], state => state);

export const selectTermsAndConditions = createSelector(
  [selectSlice],
  state => state.termsAndCondition,
);

export const selectSupport = createSelector(
  [selectSlice],
  state => state.support,
);
