import { createSelector } from 'reselect';
import { RootState } from 'types';
import { initialState } from '.';

export const selectJobFolderDetailsSlice = (state: RootState) =>
  state?.jobFolderDetailsModal || initialState;

export const selectJobFolderDetails = createSelector(
  [selectJobFolderDetailsSlice],
  state => state.jobFolderDetails,
);

export const selectJobFolderDetailsLoading = createSelector(
  [selectJobFolderDetailsSlice],
  state => state.loading,
);

export const selectJobFolderDetailsError = createSelector(
  [selectJobFolderDetailsSlice],
  state => state.error,
);
