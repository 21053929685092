import { API_ROUTES, getCall, putCall } from 'utils/api';

import { getModifiedApiError } from 'utils/services/common.service';

export const getJobLogDetailsApi = async payload => {
  try {
    const { data } = await getCall(`${API_ROUTES.job}/${payload}`);
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const getHistoryJobLogDetailsApi = async payload => {
  try {
    const { data } = await getCall(`${API_ROUTES.history}/${payload}`);
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};

export const addTemplateApi = async payload => {
  try {
    const { jobId } = payload;
    delete payload.jobId;
    const { data } = await putCall(
      `${API_ROUTES.job}/${jobId}/add/template`,
      payload,
    );
    return data;
  } catch (err: any) {
    throw new Error(getModifiedApiError(err));
  }
};
