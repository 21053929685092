/**
 * JobLogButtonLabel
 */

import { memo } from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { Button } from 'app/components';

import { BUTTON_COLORS, BUTTON_VARIANT_THEMES } from 'styles/assets';

import { ButtonProps } from 'app/components/Button/types';

export interface JobLogButtonLabelProps {
  isButtonVisible: boolean;
  buttonProps: ButtonProps;
  label: string;
  labelAriaName: string;
}

export const JobLogButtonLabel = ({
  isButtonVisible,
  buttonProps,
  label,
  labelAriaName,
}: JobLogButtonLabelProps) =>
  isButtonVisible ? (
    <Button
      color={BUTTON_COLORS.primary}
      variant={BUTTON_VARIANT_THEMES.outlined}
      {...buttonProps}
    />
  ) : (
    <div
      className="d-flex align-center bg-primary-100 py-10 px-10 radius-6 f-12 l-h-20 f-w-600 text-primary-6"
      aria-label={labelAriaName}
    >
      <CheckCircleRoundedIcon className="f-16 mr-6" /> {label}
    </div>
  );

export default memo(JobLogButtonLabel);
