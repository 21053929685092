import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer } from 'redux-injectors';

export const initialState = { socket: null };

const slice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setSocket: (state, { payload }) => void (state.socket = payload.socket),
  },
});

export const { name: sliceName, actions } = slice;

export const useSocketSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { socketActions: slice.actions };
};

export default slice.reducer;
