import { memo, useState } from 'react';

// React Router
import { useParams } from 'react-router-dom';

// Interfaces and types
import { FolderModal, LoaderType } from './types';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

// Form
import { useForm } from 'react-hook-form';

// MUI imports
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Modal, Typography, Button } from '@mui/material';

// Components
import JobFilesView from './JobFilesView';
import { actions as snackbarActions } from 'store/snackbar';
import JobFileUploderView from './JobFileUploderView';
import NoFilesView from './NoFilesView';
import { Form, FormField, LoaderErrorWrapper } from 'app/components';

// Styling
import { BUTTON_SIZE, BUTTON_VARIANT_THEMES } from 'styles/assets';
import { style } from './styles';

// Services
import {
  downloadBlobFile,
  formatSnackBarSuccess,
} from 'utils/services/common.service';
import { downloadJobFile } from './slice/api';
import { FIELD_LABELS } from 'utils/constants';
import {
  selectJobFolderDetailsError,
  selectJobFolderDetailsLoading,
} from './slice/selectors';
import { useJobFolderDetailsSlice } from 'app/components/JobLogFolderSection/slice';

function JobFolderModal(props: FolderModal) {
  const [fileUploaderView, setFileUploaderView] = useState<boolean>(false);
  const [downloadFile, setDownloadFile] = useState<any>([]);
  const [selectAll, setSelectAll] = useState<any>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [loader, setLoader] = useState<LoaderType>({
    loading: false,
    error: null,
  });

  const dispatch = useDispatch();
  const { jobFolderActions } = useJobFolderDetailsSlice();

  // Selector - JobFolder
  const jobFolderLoading = useSelector(selectJobFolderDetailsLoading);
  const jobFolderError = useSelector(selectJobFolderDetailsError);

  const { handleClose, open, folderData, isHistoryPage } = props;
  const { folderName, files, folderKey } = folderData;
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();
  const jobId = jobIdParam ? parseInt(jobIdParam) : null;

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const handleUploadBtnOnClick = () => {
    setFileUploaderView(true);
  };

  const handleFilesBtnOnClick = () => {
    setFileUploaderView(false);
  };

  const backToFileUploadView = (value: boolean) => {
    setFileUploaderView(value);
    dispatch(
      snackbarActions.updateSnackbarAction(
        formatSnackBarSuccess(
          `File(s) are uploaded to the ${folderName} Folder successfully`,
        ),
      ),
    );
  };

  const setDownloadFiles = (isChecked, file) => {
    if (isChecked) {
      setDownloadFile([...downloadFile, file]);
    } else {
      const eliminateFile = downloadFile.filter(
        files => files.name !== file.name,
      );
      setDownloadFile(eliminateFile);
      setIsSelectAll(false);
    }
  };

  const downloadSelectedFiles = () => {
    downloadFile.forEach(async file => {
      const payload = {
        jobId: jobId!,
        path: folderKey,
        fileId: file.fileId,
      };

      try {
        setLoader(preState => ({ ...preState, loading: true }));
        const data = await downloadJobFile({
          ...payload,
          isHistory: isHistoryPage,
        });
        await downloadBlobFile(data, file.name);
        setLoader(preState => ({
          ...preState,
          loading: false,
        }));
      } catch (err) {
        setLoader(preState => ({
          ...preState,
          loading: false,
          error: err,
        }));
      }
    });
  };

  const selectAllFiles = isChecked => {
    if (isChecked) {
      files.map(file => {
        setSelectAll(preState => [...preState, file._id]);
        if (
          !downloadFile.find(fileDownload => fileDownload?.fileId === file._id)
        ) {
          setDownloadFile(preState => [
            ...preState,
            {
              path: folderKey,
              fileId: file._id,
              name: file.fileName,
            },
          ]);
        }
      });
      setIsSelectAll(true);
    } else {
      setSelectAll([]);
      setDownloadFile([]);
      setIsSelectAll(false);
    }
  };

  const oncloseModal = () => {
    setSelectAll([]);
    setDownloadFile([]);
    setIsSelectAll(false);
    setLoader({ loading: false, error: null });
    handleClose();
  };

  const onClearError = () => {
    setSelectAll([]);
    setDownloadFile([]);
    setIsSelectAll(false);
    dispatch(jobFolderActions.resetJobFolderDetailsErrorAction());
    setLoader({ loading: false, error: null });
  };

  const getErrorMessage = () => {
    if (loader?.error) return `Could not download File. Please Try again`;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={`job-folder-modal-for-${folderName}`}
      aria-describedby={`${folderName}-folder-modal`}
    >
      <LoaderErrorWrapper
        loading={loader.loading || jobFolderLoading}
        error={getErrorMessage() || jobFolderError}
        onCloseError={onClearError}
      >
        <Box sx={style}>
          <div className="d-flex justify-space-between">
            {fileUploaderView && (
              <ChevronLeftIcon
                onClick={handleFilesBtnOnClick}
                className="my-14 mr-10 cursor-pointer"
              />
            )}
            <div className="flex-1">
              <Typography
                id={`${folderName}`}
                variant="h3"
                component="h3"
                className="my-12"
              >
                Job ID: {jobId} → {folderName}
              </Typography>
            </div>
            <div className="mt-4 mr-16">
              {!fileUploaderView && (
                <div className="d-flex">
                  <Button
                    variant={BUTTON_VARIANT_THEMES.contained}
                    size={BUTTON_SIZE.medium}
                    onClick={downloadSelectedFiles}
                    disabled={!downloadFile.length}
                    className="mr-2"
                  >
                    DOWNLOAD
                  </Button>

                  <Button
                    variant={BUTTON_VARIANT_THEMES.contained}
                    size={BUTTON_SIZE.medium}
                    onClick={handleUploadBtnOnClick}
                    disabled={isHistoryPage}
                  >
                    UPLOAD
                  </Button>
                </div>
              )}
            </div>
            <div>
              <CloseIcon
                className="mt-8 cursor-pointer"
                onClick={oncloseModal}
              />
            </div>
          </div>
          <div>
            <Form formName="" form={form} onSubmit={() => null}>
              <>
                {!fileUploaderView && files.length !== 0 && (
                  <div>
                    <FormField.Checkbox
                      className="w-120 f-16 f-w-600"
                      name={`selectAll`}
                      defaultValue={false}
                      label={'Select All'}
                      onChange={e => selectAllFiles(e.target.checked)}
                      checked={
                        files.length === downloadFile.length || isSelectAll
                      }
                    />
                  </div>
                )}
              </>
              {fileUploaderView ? (
                <JobFileUploderView
                  folderKey={folderKey}
                  backToFileUploderViews={(value: boolean) =>
                    backToFileUploadView(value)
                  }
                />
              ) : (
                <div className=" overflow-y-scroll h-490">
                  {files.length ? (
                    <JobFilesView
                      folderKey={folderKey}
                      files={files}
                      selectAll={selectAll}
                      setDownloadFile={(isChecked, files) => {
                        setDownloadFiles(isChecked, files);
                      }}
                      setLoader={setLoader}
                    />
                  ) : (
                    <NoFilesView />
                  )}
                </div>
              )}
            </Form>
          </div>
        </Box>
      </LoaderErrorWrapper>
    </Modal>
  );
}

export default memo(JobFolderModal);
