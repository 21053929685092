export enum TABLE_DATA_TYPES {
  edit = 'edit',
  date = 'date',
  year = 'year',
  price = 'price',
  boolean = 'boolean',
  name = 'name',
  poc = 'poc',
  scopeDueDate = 'scopeDueDate',
  phone = 'phone',
  clientName = 'clientName',
  number = 'number',
  invoiceNumber = 'invoiceNumber',
  returnExact = 'returnExact',
  redirect = 'redirect',
  initial = 'initial',
  awfUser = 'awfUser',
  invoiceAmount = 'invoiceAmount',
  invoiceDescription = 'invoiceDescription',
  invoiceDate = 'invoiceDate',
  combineName = 'combineName',
}

export default TABLE_DATA_TYPES;
