/**
 * JobLogConsultantQuoteSection
 */

import { memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

// Constants
import {
  booleanDropdownList,
  BUTTON_SIZE,
  BUTTON_VARIANT_THEMES,
  FIELD_LABELS,
  PLACEHOLDERS,
} from 'utils/constants';
import { fieldName } from 'app/components/JobLogConsultantQuoteSection/constants';

// Children Components
import {
  ButtonTooltip,
  Form,
  FormField,
  JobLogButtonLabel,
} from 'app/components';

// Types
import { JobLogConsultantQuoteSectionProps } from 'app/components/JobLogConsultantQuoteSection/types';
import { JobConsultantQuoteDetailsType } from 'app/pages/JobLogsPage/types';

// Services
import {
  getInternalQuoteValue,
  getModifiedFormValues,
  getModifiedSubmitValues,
} from 'app/components/JobLogConsultantQuoteSection/services';
import { getFormattedDate } from 'utils/services/common.service';

// Mui components
import { Typography } from '@mui/material';

export const JobLogConsultantQuoteSection = ({
  consultantQuoteDetails,
  ormsUserList,
  awfExtConsUserList,
  onConsultantQuoteSubmit,
  setIsInternalStaffRequest,
  isHistoryPage,
  isPortfolioJobCompleted,
}: JobLogConsultantQuoteSectionProps) => {
  const [showButton, setShowButton] = useState(false);
  const isConsultantQuoted = consultantQuoteDetails?.isQuoted ?? false;

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [fieldName.assignTo]: '',
      [fieldName.consultants]: '',
      [fieldName.internalQuote]: '',
      [fieldName.isQuoted]: isConsultantQuoted,
    },
  });

  const isFormDirty = form.formState.isDirty;

  const watchInternalQuote = form.watch(fieldName.internalQuote);
  const watchAssignTo = form.watch(fieldName.assignTo);
  const watchExternalConsultant = form.watch(fieldName.consultants);
  const watchIsQuoted = form.watch(fieldName.isQuoted, false);

  const isDisableAssignAndConsultant =
    !+watchInternalQuote || isConsultantQuoted;

  // For reset assignTo and consultants while internalQuote is No
  useEffect(() => {
    if (!isEmpty(watchInternalQuote) && !+watchInternalQuote) {
      form.setValue(fieldName.assignTo, '');
      form.setValue(fieldName.consultants, '');
    }
    if (watchInternalQuote === '0') {
      setIsInternalStaffRequest(false);
    } else {
      setIsInternalStaffRequest(true);
    }
  }, [watchInternalQuote]);

  useEffect(() => {
    if (consultantQuoteDetails?.consultants !== watchExternalConsultant) {
      setShowButton(true);
      form.setValue(fieldName.isQuoted, false, { shouldDirty: false });
    } else {
      setShowButton(false);
      form.setValue(fieldName.isQuoted, true, { shouldDirty: false });
    }
  }, [consultantQuoteDetails, watchExternalConsultant]);

  // For auto fill the fields while the consultantQuoteDetails is already updated
  useEffect(() => {
    if (!isEmpty(consultantQuoteDetails)) {
      consultantQuoteDetails &&
        form.reset(
          getModifiedFormValues(consultantQuoteDetails, isHistoryPage),
          {
            keepDirty: false,
          },
        );
    }
  }, [consultantQuoteDetails]);

  const onQuoteToClientClick = useCallback(() => {
    form.setValue(fieldName.isQuoted, true, { shouldDirty: true });
    setShowButton(false);
  }, []);

  const onFormSubmit = useCallback(
    (consultantQuoteDetails: JobConsultantQuoteDetailsType) => {
      const modifiedValue = getModifiedSubmitValues(consultantQuoteDetails);

      onConsultantQuoteSubmit(modifiedValue);
    },
    [],
  );

  const onCancel = useCallback(() => {
    if (!isEmpty(consultantQuoteDetails)) {
      form.reset(getModifiedFormValues(consultantQuoteDetails), {
        keepDirty: false,
      });
      if (consultantQuoteDetails?.internalQuote) {
        setIsInternalStaffRequest(true);
      }
    } else {
      form.reset();
    }
  }, [consultantQuoteDetails]);

  return (
    <div className="bg-white mt-12 p-24 radius-8">
      <Form
        formName="jobConsultantQuoteForm"
        form={form}
        onSubmit={onFormSubmit}
      >
        <div className="d-flex align-center justify-between mb-16">
          <h5 className="f-14 l-h-22 f-w-700 text-black-8">
            Consultant Quote Details
          </h5>

          {/* FORM CANCEL & SAVE */}
          {isFormDirty && !isHistoryPage && (
            <div className="d-flex">
              <ButtonTooltip.Cancel buttonProps={{ onClick: onCancel }} />
              <ButtonTooltip.Save />
            </div>
          )}
        </div>

        <div className="d-flex align-center justify-between mb-16">
          {/* INTERNAL STAFF REQUESTS QUOTE */}
          <FormField.Select
            name={fieldName.internalQuote}
            handleClear={() => {
              form.setValue(fieldName.internalQuote, '', {
                shouldDirty: true,
              });
              form.setValue(fieldName.assignTo, '', {
                shouldDirty: true,
              });
              form.setValue(fieldName.consultants, '', {
                shouldDirty: true,
              });
            }}
            aria-label="internalQuoteSelect"
            placeholder={PLACEHOLDERS.selectYesNo}
            defaultValue={getInternalQuoteValue(
              consultantQuoteDetails?.internalQuote,
            )}
            options={booleanDropdownList}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16 mr-20',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.internalStaffRequestsQuote,
            }}
            disabled={
              isConsultantQuoted || isHistoryPage || isPortfolioJobCompleted
            }
          />

          {/* ASSIGN */}
          <FormField.Select
            name={fieldName.assignTo}
            handleClear={() => {
              form.setValue(fieldName.assignTo, '', { shouldDirty: true });
            }}
            aria-label="assignSelect"
            placeholder={PLACEHOLDERS.selectORMSMember}
            defaultValue=""
            options={ormsUserList ?? []}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'flex-1 mb-16',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.assign,
            }}
            disabled={
              isDisableAssignAndConsultant ||
              isHistoryPage ||
              isPortfolioJobCompleted
            }
          />
        </div>

        <div className="d-flex align-center justify-between mb-16">
          {/* Consultant */}
          <FormField.Select
            name={fieldName.consultants}
            handleClear={() => {
              form.setValue(fieldName.consultants, '', { shouldDirty: true });
            }}
            aria-label="externalConsultantSelect"
            placeholder={PLACEHOLDERS.selectExternalConsultant}
            defaultValue={consultantQuoteDetails?.consultants ?? ''}
            options={awfExtConsUserList ?? []}
            formControlProps={{
              variant: BUTTON_VARIANT_THEMES.outlined,
              className: 'mb-16 mr-20',
            }}
            labelProps={{
              className: 'form-label pb-6',
              displayName: FIELD_LABELS.externalConsultant,
            }}
            disabled={
              !+watchInternalQuote || isHistoryPage || isPortfolioJobCompleted
            }
          />

          <div className="d-flex align-center w-100 mt-10">
            <p className="f-14 f-w-500 l-h-16 text-secondary client-text mr-6 w-100 mt-25">
              Quote To Client
              {consultantQuoteDetails?.quotedAt && (
                <Typography variant="body2" className="mt-8">
                  {consultantQuoteDetails?.quotedAt &&
                    getFormattedDate(
                      consultantQuoteDetails?.quotedAt,
                      'DD MMM YYYY HH:mm',
                    )}
                </Typography>
              )}
            </p>

            <div className="d-flex justify-content-end flex-1">
              <JobLogButtonLabel
                isButtonVisible={!watchIsQuoted || showButton}
                buttonProps={{
                  name: FIELD_LABELS.yesQuoted,
                  'aria-label': 'yesQuoted',
                  className: 'w-nowrap',
                  size: BUTTON_SIZE.medium,
                  onClick: onQuoteToClientClick,
                  disabled:
                    isEmpty(watchAssignTo) ||
                    isEmpty(watchExternalConsultant) ||
                    isHistoryPage,
                }}
                label={FIELD_LABELS.quoted}
                labelAriaName="quotedLabelSection"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default memo(JobLogConsultantQuoteSection);
