/**
 * FormControlField
 */

import isEmpty from 'lodash/isEmpty';
import { FormControl, FormHelperText } from '@mui/material';

import {
  LabelProps,
  ErrorProps,
  FormControlProps,
} from 'app/components/Form/types/FormControlField.types';

export const FormControlField = ({ children }) => children;

FormControlField.displayName = 'FormControlField';

FormControlField.FormControl = ({ children, ...props }: FormControlProps) => (
  <FormControl {...props}>{children}</FormControl>
);

FormControlField.Label = ({
  name,
  displayName,
  required,
  icon,
  className = 'form-label',
  ...props
}: LabelProps) => (
  <label className={className} htmlFor={name} {...props}>
    {`${displayName}${required ? ' *' : ''}`}
    {icon ? icon : null}
  </label>
);

FormControlField.Error = ({ message, name, ...props }: ErrorProps) => {
  if (!isEmpty(message))
    return (
      <FormHelperText error aria-label={`error-${name}`} {...props}>
        {message}
      </FormHelperText>
    );

  return <></>;
};

export default FormControlField;
