const successMessage = {
  emailSentSuccessfully: 'Email sent successfully!',
  clientCreatedSuccessfully: 'Client created successfully!',
  clientUpdatedSuccessfully: 'Client updated successfully!',
  scopeCreatedSuccessfully: 'Scope created successfully!',
  jobTemplateUpdateSuccessfully: 'Job Template updated successfully!',
  scopeUpdatedSuccessfully: 'Scope updated successfully!',
  jobLogCreatedSuccessfully: 'Job Log created successfully!',
  jobLogUpdatedSuccessfully: 'Job Log updated successfully!',
  ormsUserCreatedSuccessfully: 'ORMS User created successfully!',
  ormsUpdatedSuccessfully: 'ORMS User is updated successfully!',
  awfUserCreatedSuccessfully: 'AWF User created successfully!',
  awfUserUpdatedSuccessfully: 'AWF User Updated successfully!',
  externalOrderedSuccessfully: 'External data ordered successfully',
  externalReceivedSuccessfully: 'External data received successfully',
  consultantQuoteUpdatedSuccessfully:
    'Consultant Quote Details are updated successfully',
  quoteDeliveryUpdatedSuccessfully:
    'Quote Delivery Details are updated successfully',
  finalReviewUpdatedSuccessfully:
    'Final Review Details are updated successfully',
  convertPortfolioParentSuccessfully:
    'This job is converted as a portfolio parent successfully',
  convertPortfolioUpdatedSuccessfully:
    'This job is converted as a portfolio job and job details are also updated successfully',
  convertPortfolioChildSuccessfully:
    'This job is converted as the child job of the selected parent successfully',
  duplicatePortfolio: 'Duplicate child portfolio is created successfully',
  portfolioJobCompletedSuccessfully:
    'Portfolio Child job is completed successfully',
  portfolioCompletedSuccessfully:
    'Portfolio Parent job is completed successfully',
  singedUrlCreatedSuccessfully: 'Singed URL created successfully',
  invoiceDetailUpdateSuccessfully: 'Invoice Details are updated successfully',
  invoiceGenerateSuccessfully: 'Invoice Generated successfully',
};

export default successMessage;
