/**
 * NotificationPopover
 */

import { Divider, Popover, Tooltip } from '@mui/material';
import { memo, useEffect } from 'react';
import { NotificationPopoverProps } from './type';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';

// Socket imports
import { io } from 'socket.io-client';
import { selectAuth } from 'store/auth/selectors';
import { selectNotification } from './slice/selectors';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useNotificationSlice } from './slice';
import { Link } from 'react-router-dom';
import { redirectTO } from './constants';
import { ActionType } from './slice/types';

import { humanizeDate } from 'utils/services/common.service';
import { isUndefined } from 'lodash';
import { socketConfig } from 'store/socket/selectors';
import { selectWhoisDetails } from 'store/auth/selectors';
import { RolesEnum } from 'utils/constants/enum';
import { NotificationType } from './slice/types';

export const NotificationPopover = ({
  id,
  isNotificationOpen,
  notificationAnchor,
  closeNotificationPopover,
  showBadge,
}: NotificationPopoverProps) => {
  const { actions } = useNotificationSlice();
  const userId = useSelector(selectAuth).whoisApiDetails.whoisDetails._id;
  const notificationList = useSelector(selectNotification).notifications;
  const hasUnReadMsg = useSelector(selectNotification).hasUnReadMsg;
  const dispatch = useDispatch();
  const socket = useSelector(socketConfig);
  const { whoisDetails } = useSelector(selectWhoisDetails);

  useEffect(() => {
    if (userId && socket !== null) {
      socket.on('userNotifications', (...args) => {
        dispatch(actions.hasUnReadMsg(true));
        dispatch(actions.pushNotification(args));
      });
    }
  }, [userId, socket]);

  useEffect(() => {
    dispatch(actions.getNotificationAction());
  }, []);

  useEffect(() => {
    if (!isUndefined(hasUnReadMsg) && typeof hasUnReadMsg === 'boolean') {
      showBadge(hasUnReadMsg);
    }
  }, [hasUnReadMsg]);

  const isAdmin =
    whoisDetails?.roleId?.name === RolesEnum.superAdmin ||
    whoisDetails?.roleId?.name === RolesEnum.ormsAdmin;

  const isOrmsAdmin = whoisDetails?.roleId?.name === RolesEnum.ormsAdmin;

  const getRedirect = notification => {
    if (
      (!isAdmin &&
        [
          NotificationType.orms,
          NotificationType.awf,
          NotificationType.scope,
        ].includes(notification?.notificationType)) ||
      ([NotificationType.orms, NotificationType.scope].includes(
        notification?.notificationType,
      ) &&
        isOrmsAdmin)
    ) {
      return '#';
    }

    return `${redirectTO[notification?.notificationType]}/${
      notification?.performedOn
    }`;
  };

  return (
    <Popover
      id={id}
      open={isNotificationOpen}
      anchorEl={notificationAnchor}
      onClose={closeNotificationPopover}
      anchorPosition={{ top: 200, left: 1100 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: { width: '50%' },
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className="notification-popover-container">
        <div className="notification-header-sticky">
          <div className="d-flex align-center px-14 py-16">
            <div className="flex-1">
              <div className="text-black-9 f-18 f-w-700">Notifications</div>
            </div>
            {/* TODO: Need for future integration */}
            <div
              className="text-black-5 f-14 f-w-500 cursor-pointer"
              onClick={() => {
                dispatch(actions.markAllAsRead());
              }}
            >
              Mark all as read
            </div>
          </div>
          <Divider />
        </div>
        {notificationList.length ? (
          <div className="notification-body mx-4">
            {notificationList.map(notification => (
              <Link
                to={
                  notification?.actionType === ActionType.delete
                    ? '#'
                    : getRedirect(notification)
                }
                onClick={() => {
                  dispatch(
                    actions.updateNotificationStatus([notification['_id']]),
                  );
                  closeNotificationPopover();
                }}
                key={notification['id']}
              >
                <div
                  className={`notification-container radius-4 p-12 mb-4 mt-4 ${
                    !get(notification, 'readStatus[0].isMsgRead', false) &&
                    'unread-notification'
                  }`}
                  key={notification['id']}
                >
                  <div className="cursor-pointer" key={notification['id']}>
                    <div className=" d-flex">
                      <div className="flex-1">
                        <div className="f-16 f-w-600 text-primary-6"></div>
                      </div>
                      <div className="f-10 f-w-300 text-primary-6">
                        <div className={`f-10 f-w-600 text-primary-5`}>
                          {humanizeDate(notification?.createdAt)}
                        </div>
                      </div>
                    </div>
                    <Tooltip
                      title={get(notification, 'message', '')}
                      placement="bottom"
                    >
                      <div className="mt-12 text-truncate text-primary-7">
                        {get(notification, 'message', '')}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <Divider variant="middle" />
              </Link>
            ))}
          </div>
        ) : (
          <h3 className="notificationEmpty-body text-black-3">
            No Notification
          </h3>
        )}
      </div>
    </Popover>
  );
};

export default memo(NotificationPopover);
