import { memo } from 'react';

// MUI imports & styles
import { Box, Modal } from '@mui/material';
import { emailStaterStyle } from './style';
import CloseIcon from '@mui/icons-material/Close';
// Redux imports
import { useSelector } from 'react-redux';
import { selectJobLogDetails } from 'app/pages/JobLogDetailsPage/slice/selectors';

// Services
import { getLongJobId } from '../JobLogList/services';

// Utils and lodash imports
import { isNull } from 'lodash';

function ShowEmailTextModal({ open, handleClose }) {
  const job = useSelector(selectJobLogDetails);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="email-stater-modal"
    >
      <Box sx={{ ...emailStaterStyle }}>
        <div className="d-flex">
          <div className="flex-1 f-20 f-w-700 my-24">Email Starter</div>
          <div className="my-22 cursor-pointer">
            <CloseIcon onClick={handleClose} />
          </div>
        </div>
        <div className="f-13 f-w-500 text-tertiary my-6">
          {job?.clientJob?.jobRecipients
            .map(jobRecipient => jobRecipient.email)
            .join('; ')}
        </div>

        <div className="f-13 f-w-500 text-tertiary my-6">
          {`${getLongJobId(job)} - ${job?.siteLocation?.address}, ${
            job?.siteLocation?.city
          }, ${job?.siteLocation?.state} ${
            isNull(job.loanNumber) ? '' : ` - ${job.loanNumber}`
          }`}
        </div>

        <div className="f-13 f-w-500 text-tertiary my-6">
          {job?.clientJob?.jobRecipients
            .map(jobRecipient => jobRecipient.firstName)
            .join(', ')}
        </div>

        <div className="f-13 f-w-500 text-tertiary my-6">
          Please find the attached Outsourced Risk Management Solutions (ORMS){' '}
          {job?.scope?.name} for the site located at{' '}
          {job?.siteLocation?.address}, {job?.siteLocation?.city},{' '}
          {job?.siteLocation?.state}, as well as the associated invoice for
          processing.
        </div>

        <div className="f-13 f-w-500 text-tertiary my-6">
          {job.client?.deliveryEmailStarter &&
            job.client.deliveryEmailStarter.map(deliveryEmail => (
              <div className="f-13 f-w-500 text-tertiary my-6">
                {deliveryEmail}
              </div>
            ))}
        </div>
      </Box>
    </Modal>
  );
}

export default memo(ShowEmailTextModal);
