import get from 'lodash/get';

// Child Components
import MultiSelectChip from 'app/components/MultiSelect/MultiSelectChip';

// Types
import { OrmsUserDetailsType } from 'app/pages/UsersPage/types';

// Services
import { getName } from 'utils/services/common.service';
import { SelectOptionType } from 'app/components/Select/types';

export const getModifiedJobRecipientsName = (
  jobRecipients?: string[] | OrmsUserDetailsType[],
) => {
  const jobRecipientsLength = jobRecipients?.length ?? 0;

  if (jobRecipientsLength === 0) {
    return 'N/A';
  }

  const defaultJobRecipientName = getName(
    get(jobRecipients, '[0].firstName', ''),
    get(jobRecipients, '[0].lastName', ''),
  );

  return (
    <MultiSelectChip
      defaultDetails={{
        key: defaultJobRecipientName,
        label: defaultJobRecipientName,
        className: 'radius-6 bg-black-90',
      }}
      additionalDetails={{
        length: jobRecipientsLength,
        className: 'radius-6 ml-8 bg-black-400 text-white',
      }}
    />
  );
};

export const getModifiedJobTemplates = list => {
  const modifiedData: any = [];
  list.forEach(template => {
    if (!template?.isDisabled) {
      modifiedData.push({
        key: template?.name,
        value: template?.name,
        displayName: template?.name,
      });
    }
  });
  return modifiedData;
};
